import _ from "lodash";
import React from "react";
import * as ID from "uuid";
import * as F from "../../Form";
import * as M from "../../Modal";
import Coverage from "./Coverage";
import * as G from "../../Gestion";
import * as ME from "./ModalEdits";
import * as H from "../../../hooks";
import * as C from "../../../Common";
import { T, TB, TC, MISSION } from "../../../Constants";

//#region Types
export type DataSourcesProps = {
    /** The current mission's asset id */
    asset_id: string;
    /** Is the component currently active ? */
    active: boolean;
    /** The data for the EPRA mission */
    epra_data: ReturnType<T.API.Utils.Missions.MissionResumeEpra>["epra_data"];
    /** Callback to update the EPRA data */
    update: React.Dispatch<React.SetStateAction<ReturnType<T.API.Utils.Missions.MissionResumeEpra>["epra_data"]>>;
}

type ExtraRowDataset = Record<"is_selected", boolean>;
type ExtraRowEmplacement = Record<"is_selected", boolean>;

type Row = {
    /** The EPRA code of the value */
    epra_code: keyof T.Mission["epra"]["categories"];
    /** The EPRA label of the main categories */
    epra_label: string;
    /** The name of sub category */
    category_id: string;
    /** The id of the sub category */
    category: string;
}
//#endregion

const sort_epra_labels = (a: string, b: string) => {
    // Get only the number from the string
    let parsed_a = parseFloat(a || "");
    let parsed_b = parseFloat(b || "");
    // Count the number of decimals
    let amount_decimal_a = TB.countDecimals(parsed_a);
    let amount_decimal_b = TB.countDecimals(parsed_b);
    // If only one decimal, move the decimal one place to the right
    if (amount_decimal_a === 1) parsed_a = Math.floor(parsed_a) + ((parsed_a % 1) / 10);
    if (amount_decimal_b === 1) parsed_b = Math.floor(parsed_b) + ((parsed_b % 1) / 10);
    return parsed_a - parsed_b;
}

const DataSources: React.FC<DataSourcesProps> = ({ update, ...props }) => {
    // Handle the dataset table
    const original_dataset_rows = React.useRef([]);
    const show_dataset_popup = H.useBoolean(false);
    const current_dataset_selection = React.useRef<string[]>([]);
    const update_dataset_ref_function = React.useRef<() => void>(null);
    const dataset_grid_ref = React.useRef<F.DataSets.DataPanelRef<ExtraRowDataset>>(null);
    // Handle the emplacement table
    const original_emp_rows = React.useRef([]);
    const show_emplacement_popup = H.useBoolean(false);
    const current_emplacement_selection = React.useRef<string[]>([]);
    const update_emplacement_ref_function = React.useRef<() => void>(null);
    const emplacement_grid_ref = React.useRef<G.EmplacementTableRef<ExtraRowEmplacement>>(null);
    // Normal state
    const lg = H.useLanguage();
    const grid = React.useRef<G.TableRef<Row>>(null);
    const context = React.useMemo<T.ContextParams>(() => ({ roots: props.asset_id }), [props.asset_id]);
    const auto_prompt_settled = React.useRef<Record<"period" | "assets", boolean>>({ period: false, assets: false });

    //#region Events Handler & Inline Edition
    const events = React.useMemo(() => ({
        edit_period: () => {
            ME.render_edit<ME.PeriodEditProps>({ type: "period", periods: props.epra_data.periods }).then(data => {
                let new_periods_set = data?.period_1 && data?.period_2;
                let periods_already_set = props.epra_data.periods?.period_1 && props.epra_data.periods?.period_2;
                // If the periods are not set, and the user did not set them, show an error message
                if (!new_periods_set && !periods_already_set) M.renderAlert({ message: TC.EPRA_MISSION_PERIODS_REQUIRED, type: "error" });
                // If the periods are not set, but the user set new one, just update the state
                else if (new_periods_set) update(p => ({ ...p, periods: data }));
                return data;
            });
        },
        edit_favorite: () => {
            ME.render_edit<ME.SavedViewsManagerProps>({ type: "views", epra: props.epra_data }).then(data => {
                if (data) update(data.epra);
            });
        },
        edit_asset_categories: () => {
            ME.render_edit<ME.CategoryEditProps>({ type: "asset", categories: props.epra_data.datasource }).then(data => {
                if (data) update(p => ({ ...p, datasource: data }));
            });
        },
        add_category: (epra_label: string) => {
            let epra_code = MISSION.EPRA_DATA.find(c => c.label === epra_label)?.value;
            if (!epra_code) M.renderAlert({ message: TC.EPRA_FAIL_CAT_CREATION, type: "error" });
            else {
                let new_categories = props.epra_data.categories[epra_code] || [];
                // There wasn't any category yet, so add two (One to replace the 'fake' one created in rows, and one for the one created by the press of the button)
                if (new_categories.length === 0) new_categories.push({ id: ID.v4(), name: "" }, { id: ID.v4(), name: "" });
                else new_categories.push({ id: ID.v4(), name: "" });
                // Update the state
                update(p => ({ ...p, categories: { ...p.categories, [epra_code]: new_categories } }));
            }
        },
        remove_category: (epra_code: Row["epra_code"], cat_id: Row["category_id"]) => {
            M.askConfirm().then(confirmed => {
                if (confirmed) {
                    // Remove the category from the list of categories
                    let new_categories = (props.epra_data.categories[epra_code] || []).filter(c => c.id !== cat_id);
                    // Remove the category when it appears in an asset
                    let new_datasource = props.epra_data.datasource.map(d => ({ ...d, assets: d.assets.filter(a => a.id !== cat_id) }));

                    // Update the state
                    update(p => {
                        let new_repartition: T.Mission["epra"]["repartition"] = null;
                        // Check if repartition needs to be updated
                        let update_repartition = Object.values(p.repartition).some(epra_cat => epra_cat.some(cat => cat.sources.includes(cat_id)));
                        if (update_repartition) new_repartition = Object.fromEntries(Object.entries(p.repartition).map(([epra_code, epra_cats]) => {
                            return [epra_code, epra_cats.map(cat => {
                                if (cat.sources.includes(cat_id)) return { ...cat, sources: cat.sources.filter(s => s !== cat_id) };
                                else return cat;
                            })];
                        })) as T.Mission["epra"]["repartition"];

                        let updated_data = { ...p, categories: { ...p.categories, [epra_code]: new_categories }, datasource: new_datasource } as T.Mission["epra"];
                        if (update_repartition) updated_data.repartition = new_repartition;
                        return updated_data;
                    });
                }
            });
        },
    }), [props.epra_data, update]);

    const inline = React.useMemo(() => ({
        change: (event => {
            let row = event.data,
                new_value = event.newValue,
                field = event.colDef.field as keyof Row;

            // Check that the updated field is an updatable one
            if (field === "category") {
                // Check that the field is not left empty
                if (!TB.validString(new_value)) M.renderAlert({ message: TC.GLOBAL_REQUIRED_FIELD, type: "warning" });
                else {
                    let epra_code = row.epra_code;
                    let new_categories = props.epra_data.categories[epra_code] || [];
                    // The user edited the default category, so we need to create a new one
                    if (new_categories.length === 0) new_categories.push({ id: ID.v4(), name: new_value });
                    // The user edited an existing category
                    else new_categories = new_categories.map(c => c.id === row.category_id ? { ...c, name: new_value } : c);
                    // Update the state
                    update(p => ({ ...p, categories: { ...p.categories, [epra_code]: new_categories } }));
                }
            }
            // The field can't be updated
            else M.renderAlert({ message: TC.EPRA_WARNING_ONLY_CAT_TO_BE_UPDATED, type: "warning" });
        }) as G.TableProps<Row>["onValueChange"],
        show_datasets_pop_up: (row: Row, asset_field: string) => {
            // Remove the added suffix to get the asset id
            let asset_id = asset_field.slice(0, -4);
            // Determine wether this is editing the first of the second period
            let is_first_period = asset_field.slice(-1) === "1";
            // Retrieve the asset's data
            let asset_object = props.epra_data.datasource.find(d => d.id === asset_id);
            // Retrieve the category amongst the asset data
            let category_object = asset_object?.assets?.find?.(a => a.id === row.category_id);
            // Retrieve the previously selected items
            let selected_items = (is_first_period ? category_object?.datasets?.period_1?.datasets : category_object?.datasets?.period_2?.datasets) || [];
            // Update the ref with the new selection
            current_dataset_selection.current = selected_items;
            // Retrieve the filtered types
            let epra_code = Object.entries(props.epra_data.categories).find(([epra_code, categories]) => categories.some(c => c.id === row.category_id))[0];
            let epra_filtered_types = TB.arrayWrapper(MISSION.EPRA_DATA.find(e => e.value === epra_code)?.data_type);
            // Add the info if the row is selected or not
            let new_rows = dataset_grid_ref.current?.rows?.map(r => ({ ...r, is_selected: selected_items.includes(r._id) }))
                // Filter the types
                .filter(r => epra_filtered_types.includes(r.type));
            // Filter based on the emplacements
            let restricted_emplacements = (is_first_period ? category_object?.datasets?.period_1?.emplacements : category_object?.datasets?.period_2?.emplacements) || [];
            if (restricted_emplacements.length > 0) new_rows = new_rows.filter(r => restricted_emplacements.includes(r.origin));

            // Save the original rows
            original_dataset_rows.current = dataset_grid_ref.current?.rows || [];
            dataset_grid_ref.current?.setRows?.(new_rows);
            // Show the popup
            show_dataset_popup.setTrue();

            // Create a callback to handle the update of the dataset
            update_dataset_ref_function.current = () => {
                let rows = dataset_grid_ref.current?.rows || [];
                let new_selection = rows.filter(r => r.is_selected).map(r => r._id);
                let prop: keyof T.Mission["epra"]["datasource"][number]["assets"][number]["datasets"] = is_first_period ? "period_1" : "period_2";

                // Reset the original rows
                dataset_grid_ref.current?.setRows?.(original_dataset_rows.current);

                // Auto-select the emplacements based on the datasets
                let must_be_selected_emplacements = rows.filter(r => r.is_selected).map(r => r.origin);
                let new_emplacements = ((is_first_period ? category_object?.datasets?.period_1?.emplacements : category_object?.datasets?.period_2?.emplacements) || []).concat(must_be_selected_emplacements);
                // Remove duplicates
                new_emplacements = _.uniq(new_emplacements);

                update(p => ({
                    ...p,
                    datasource: p.datasource.map(ds => {
                        if (ds.id !== asset_id) return ds;
                        // Check if the category does exists already in the asset
                        else if (ds.assets.some(a => a.id === row.category_id)) return {
                            ...ds,
                            assets: ds.assets.map(a => {
                                if (a.id !== row.category_id) return a;
                                else return { ...a, datasets: { ...a.datasets, [prop]: { emplacements: new_emplacements, datasets: new_selection } } };
                            }),
                        }
                        // Category didn't exists yet
                        else return {
                            ...ds,
                            assets: ds.assets.concat([{
                                id: row.category_id,
                                datasets: {
                                    period_1: is_first_period ? { datasets: new_selection, emplacements: new_emplacements } : { datasets: [], emplacements: [] },
                                    period_2: is_first_period ? { datasets: [], emplacements: [] } : { datasets: new_selection, emplacements: new_emplacements },
                                }
                            }]),
                        };
                    }),
                }));
                // Close the popup
                show_dataset_popup.setFalse();
            };
        },
        show_emp_pop_up: (row: Row, asset_field: string) => {
            // Remove the added suffix to get the asset id
            let asset_id = asset_field.slice(0, -4);
            // Determine wether this is editing the first of the second period
            let is_first_period = asset_field.slice(-1) === "1";
            // Retrieve the asset's data
            let asset_object = props.epra_data.datasource.find(d => d.id === asset_id);
            // Retrieve the category amongst the asset data
            let category_object = asset_object?.assets?.find?.(a => a.id === row.category_id);
            // Retrieve the previously selected items
            let selected_items = (is_first_period ? category_object?.datasets?.period_1?.emplacements : category_object?.datasets?.period_2?.emplacements) || [];
            // Update the ref with the new selection
            current_emplacement_selection.current = selected_items;

            // Save the original rows
            original_emp_rows.current = emplacement_grid_ref.current?.rows || [];
            // Add the info if the row is selected or not
            let new_rows = emplacement_grid_ref.current?.rows?.map(r => ({ ...r, is_selected: selected_items.includes(r._id) }))
            emplacement_grid_ref.current?.setRows?.(new_rows);
            // Show the popup
            show_emplacement_popup.setTrue();

            // Create a callback to handle the update of the dataset
            update_emplacement_ref_function.current = () => {
                let rows = emplacement_grid_ref.current?.rows || [];
                let new_selection = rows.filter(r => r.is_selected).map(r => r._id);
                let prop: keyof T.Mission["epra"]["datasource"][number]["assets"][number]["datasets"] = is_first_period ? "period_1" : "period_2";

                // Reset the original rows
                emplacement_grid_ref.current?.setRows?.(original_emp_rows.current);

                // Make a list of all the possible datasets to be selected
                let can_be_selected_datasets = (dataset_grid_ref.current?.rows || [])
                    .filter(d => new_selection.includes(d.origin))
                    .map(r => r._id);
                let new_datasets = ((is_first_period ? category_object?.datasets?.period_1?.datasets : category_object?.datasets?.period_2?.datasets) || [])
                    .filter(d => can_be_selected_datasets.includes(d));

                update(p => ({
                    ...p,
                    datasource: p.datasource.map(ds => {
                        if (ds.id !== asset_id) return ds;
                        // Check if the category does exists already in the asset
                        else if (ds.assets.some(a => a.id === row.category_id)) return {
                            ...ds,
                            assets: ds.assets.map(a => {
                                if (a.id !== row.category_id) return a;
                                else return { ...a, datasets: { ...a.datasets, [prop]: { datasets: new_datasets, emplacements: new_selection } } };
                            }),
                        }
                        // Category didn't exists yet
                        else return {
                            ...ds,
                            assets: ds.assets.concat([{
                                id: row.category_id,
                                datasets: {
                                    period_1: is_first_period ? { datasets: new_datasets, emplacements: new_selection } : { datasets: [], emplacements: [] },
                                    period_2: is_first_period ? { datasets: [], emplacements: [] } : { datasets: new_datasets, emplacements: new_selection },
                                }
                            }]),
                        };
                    }),
                }));

                // Close the popup
                show_emplacement_popup.setFalse();
            };
        },
        extra_buttons_dataset: {
            icon: { element: "<i class='fa fa-check me-2'></i>" },
            label: lg.getStaticText(TC.EPRA_TABLE_SELECT_DISPLAYED_ROWS),
            onClick: () => {
                // Fetch the current rows
                let all_current_rows = dataset_grid_ref.current?.rows || [];
                // Save them all as selected
                current_dataset_selection.current = all_current_rows.map(r => r._id);
                // Update the state of the rows in the table
                let updated_state = all_current_rows.map(r => ({ ...r, is_selected: true }));
                dataset_grid_ref.current?.setRows?.(updated_state);
            },
        } as F.DataSets.DataPanelProps<ExtraRowDataset>["buttons"],
        extra_buttons_emplacement: {
            icon: { element: "<i class='fa fa-check me-2'></i>" },
            label: lg.getStaticText(TC.EPRA_TABLE_SELECT_DISPLAYED_ROWS),
            onClick: () => {
                // Fetch the current rows
                let all_current_rows = emplacement_grid_ref.current?.rows || [];
                // Save them all as selected
                current_emplacement_selection.current = all_current_rows.map(r => r._id);
                // Update the state of the rows in the table
                let updated_state = all_current_rows.map(r => ({ ...r, is_selected: true }));
                emplacement_grid_ref.current?.setRows?.(updated_state);
            }
        } as G.EmplacementTableProps<ExtraRowEmplacement>["buttons"],
        dataset_extra_col: [{
            pinned: "left",
            headerName: " ",
            editable: false,
            field: "is_selected",
            type: G.CellsTypes.TYPE_ACTION_BUTTON,
            params: {
                action: row => {
                    if (current_dataset_selection.current.includes(row._id)) current_dataset_selection.current.splice(current_dataset_selection.current.indexOf(row._id), 1)
                    else current_dataset_selection.current.push(row._id);
                    // Update the state of the table
                    let updated_state = (dataset_grid_ref.current?.rows || []).map(r => r._id === row._id ? { ...r, is_selected: !r.is_selected } : r);
                    dataset_grid_ref.current?.setRows?.(updated_state);
                },
                buttonProps: row => row.is_selected
                    ? { variant: "success", icon: "check-circle", size: "sm", text: TC.EPRA_DATASET_SELECTED }
                    : { variant: "primary", icon: "times-circle", size: "sm", text: TC.EPRA_DATASET_TO_SELECT },
            }
        }] as F.DataSets.DataPanelProps<ExtraRowDataset>["extra_columns"],
        emplacement_extra_col: [{
            pinned: "left",
            headerName: " ",
            editable: false,
            field: "is_selected",
            type: G.CellsTypes.TYPE_FREE_RENDER,
            params: {
                render: (row, field, all_props) => {

                    const onClick = (ids: T.AllowArray<string>) => {
                        ids = TB.arrayWrapper(ids).filter(TB.mongoIdValidator);
                        let updated_state = (emplacement_grid_ref.current?.rows || [])
                        if (ids.length === 0) return;
                        else for (let id of ids) {
                            if (current_emplacement_selection.current.includes(id)) current_emplacement_selection.current.splice(current_emplacement_selection.current.indexOf(id), 1);
                            else current_emplacement_selection.current.push(id);
                            // Update the state of the table
                            updated_state = updated_state.map(r => r._id === id ? { ...r, is_selected: !r.is_selected } : r);
                        }
                        emplacement_grid_ref.current?.setRows?.(updated_state);
                    }

                    if (all_props.node?.group) {
                        let children: typeof emplacement_grid_ref.current.rows = all_props.node.allLeafChildren.map(c => c.data);
                        let not_selected_rows = children.filter(c => !c.is_selected);
                        let ids = [] as string[];

                        // Not children available
                        if (children.length === 0) return null;
                        // All children are selected, offer to unselect all of them
                        else if (not_selected_rows.length === 0) ids = children.map(c => c._id);
                        // Not all children are selected, so offer to select them all
                        else ids = not_selected_rows.map(c => c._id);
                        return <C.Button size="sm" icon="check" onClick={() => onClick(ids)} />;
                    }
                    else if (row.is_selected) return <C.Button
                        size="sm"
                        variant="success"
                        icon="check-circle"
                        text={TC.EPRA_DATASET_SELECTED}
                        onClick={() => onClick(row._id)}
                    />;
                    else return <C.Button
                        size="sm"
                        variant="primary"
                        icon="times-circle"
                        onClick={() => onClick(row._id)}
                        text={TC.EPRA_DATASET_TO_SELECT}
                    />;
                }
            }
        }] as G.EmplacementTableProps<ExtraRowEmplacement>["extra_columns"],
    }), [props.epra_data.categories, show_dataset_popup, show_emplacement_popup, props.epra_data.datasource, update]);

    const context_menu = React.useCallback<G.TableProps<Row>["getContextMenuItems"]>(params => {
        let row = params.node?.data as Row;
        let default_actions = params.defaultItems || [];
        let actions: ReturnType<G.TableProps<Row>["getContextMenuItems"]> = [];

        // If there is a category for the current row, add the option to remove it
        if (row?.category_id && row.category_id !== "empty") actions.unshift({
            icon: "<i class='fa fa-times'></i>",
            action: () => events.remove_category(row.epra_code, row.category_id),
            name: lg.getStaticText(TC.EPRA_CONTEXT_ACTION_REMOVE_CAT, row.category),
        });
        // Add a separator between custom and default actions
        if (actions.length > 0 && default_actions.length > 0) actions.push("separator");
        // Add the default actions
        if (default_actions.length > 0) actions.push(...default_actions);
        return actions;
    }, [events, lg]);
    //#endregion

    //#region Columns & Renders
    const render = React.useMemo(() => ({
        dataset_lfl: (row: Row, field: keyof Row) => {
            if (!row) return null;
            let periods = { from: props.epra_data.periods?.period_1?.start_date, to: props.epra_data.periods?.period_2?.end_date };

            return <div className="w-100 text-center">
                <span children={(row[field]?.length || 0) + " " + lg.getStaticText(TC.NRJ_DASH_DATASETS)} />
                <C.IconTip trigger="click" className="ms-2" icon="question-circle" tipContent={<Coverage periods={periods} epra_code={row.epra_code} datasets={row[field] as any} />} />
            </div>;
        },
        emplacement_lfl: (row: Row, field: keyof Row) => row && <div
            className="w-100 text-center"
            children={(row[field]?.length || 0) + " " + lg.getStaticText(TC.NRJ_DASH_EMPLACEMENTS)}
        />,
        datasets_button: (row: Row, field: keyof Row) => {
            if (!row) return null;
            let is_first_period = field.slice(-1) === "1";

            let periods = is_first_period
                ? { from: props.epra_data.periods?.period_1?.start_date, to: props.epra_data.periods?.period_1?.end_date }
                : { from: props.epra_data.periods?.period_2?.start_date, to: props.epra_data.periods?.period_2?.end_date };

            return <C.Flex alignItems="center" justifyContent="between">
                <div>
                    <span children={(row[field]?.length || 0) + " " + lg.getStaticText(TC.NRJ_DASH_DATASETS)} />
                    <C.IconTip trigger="click" className="ms-2" icon="question-circle" tipContent={<Coverage periods={periods} epra_code={row.epra_code} datasets={row[field] as any} />} />
                </div>
                <C.Button
                    size="sm"
                    variant="success"
                    icon="pencil-alt"
                    disabled={row.category_id === "empty"}
                    onClick={() => inline.show_datasets_pop_up(row, field)}
                />
            </C.Flex>
        },
        emplacement_button: (row: Row, field: keyof Row) => {
            if (!row) return null;
            return <C.Flex alignItems="center" justifyContent="between">
                <div>
                    <span children={(row[field]?.length || 0) + " " + lg.getStaticText(TC.NRJ_DASH_EMPLACEMENTS)} />
                </div>
                <C.Button
                    size="sm"
                    variant="primary"
                    icon="pencil-alt"
                    disabled={row.category_id === "empty"}
                    onClick={() => inline.show_emp_pop_up(row, field)}
                />
            </C.Flex>
        },
    }), [lg, inline, props.epra_data.periods]);

    const columns = React.useMemo<G.TableProps<Row>["columns"]>(() => {
        let periods = props.epra_data.periods;
        let base_columns: G.TableProps<Row>["columns"] = [
            { field: "epra_label", headerName: TC.EPRA_CODE_LABEL, rowGroup: true, hide: true, sort: "asc", comparator: sort_epra_labels },
            { field: "category", headerName: TC.EPRA_CATEGORY_LABEL, editable: true },
            {
                field: "add_cat_button",
                headerName: " ",
                type: G.CellsTypes.TYPE_FREE_RENDER,
                params: {
                    render: (row, field, all_props) => {
                        // Only should appear on the main categories group header
                        if (all_props.node?.group && (all_props.node?.field as keyof Row) === "epra_label") return <C.Flex className="h-100" alignItems="center" justifyContent="center">
                            <C.Button
                                size="sm"
                                icon="plus"
                                variant="link"
                                text={TC.EPRA_ADD_CAT_TITLE}
                                onClick={() => events.add_category(all_props.node.key)}
                            />
                        </C.Flex>;
                        else return null;
                    }
                }
            }
        ];

        for (let asset of props.epra_data.datasource) base_columns.push({
            headerName: asset.name,
            children: [
                {
                    field: asset.id + "_e_1",
                    type: G.CellsTypes.TYPE_FREE_RENDER,
                    params: { render: render.emplacement_button },
                    headerName: (periods?.period_1?.name || " ") + " " + lg.getStaticText(TC.EPRA_EMPLACEMENTS_LABEL),
                },
                {
                    field: asset.id + "_d_1",
                    type: G.CellsTypes.TYPE_FREE_RENDER,
                    params: { render: render.datasets_button },
                    headerName: (periods?.period_1?.name || " ") + " " + lg.getStaticText(TC.DATASET_PANEL_TAB_DATA),
                },
                {
                    field: asset.id + "_e_2",
                    type: G.CellsTypes.TYPE_FREE_RENDER,
                    params: { render: render.emplacement_button },
                    headerName: (periods?.period_2?.name || " ") + " " + lg.getStaticText(TC.EPRA_EMPLACEMENTS_LABEL),
                },
                {
                    field: asset.id + "_d_2",
                    type: G.CellsTypes.TYPE_FREE_RENDER,
                    params: { render: render.datasets_button },
                    headerName: (periods?.period_2?.name || " ") + " " + lg.getStaticText(TC.DATASET_PANEL_TAB_DATA),
                },
                {
                    field: asset.id + "_e_lfl",
                    type: G.CellsTypes.TYPE_FREE_RENDER,
                    params: { render: render.emplacement_lfl },
                    headerName: `LfL (${periods?.period_1?.name || "P1"} / ${periods?.period_2?.name || "P2"}) ` + lg.getStaticText(TC.EPRA_EMPLACEMENTS_LABEL),
                },
                {
                    field: asset.id + "_d_lfl",
                    type: G.CellsTypes.TYPE_FREE_RENDER,
                    params: { render: render.dataset_lfl },
                    headerName: `LfL (${periods?.period_1?.name || "P1"} / ${periods?.period_2?.name || "P2"}) ` + lg.getStaticText(TC.DATASET_PANEL_TAB_DATA),
                },
            ],
        });

        return base_columns;
    }, [lg, events, render, props.epra_data]);
    //#endregion

    //#region Modal Params
    const modal_params = React.useMemo<M.BlankModalProps>(() => {
        let quit_modal = () => {
            if (show_dataset_popup.value) {
                show_dataset_popup.setFalse();
                dataset_grid_ref.current.setRows(original_dataset_rows.current);
            }
            else {
                show_emplacement_popup.setFalse();
                emplacement_grid_ref.current.setRows(original_emp_rows.current);
            }
        }

        return {
            isFullScreen: true,
            onQuit: quit_modal,
            hidden: !(show_dataset_popup.value || show_emplacement_popup.value),
            title: show_dataset_popup.value ? TC.EPRA_DATASET_SELECTION : TC.EPRA_EMPLACEMENT_SELECTION,
            footer: <C.Button
                icon="save"
                text={TC.GLOBAL_SAVE}
                onClick={() => (show_dataset_popup.value ? update_dataset_ref_function.current : update_emplacement_ref_function.current)()}
            />,
        };
    }, [show_dataset_popup, show_emplacement_popup]);
    //#endregion

    //#region Rows & other datas
    const rows = React.useMemo<Row[]>(() => {
        let rows: Row[] = [];

        for (let main_cat of MISSION.EPRA_DATA) {
            let sub_categories = props.epra_data.categories?.[main_cat.value] || [];
            // No categories yet, but need something otherwise no rows will be displayed
            if (sub_categories.length === 0) rows.push({
                category_id: "empty",
                epra_code: main_cat.value,
                epra_label: main_cat.label,
                category: lg.getStaticText(TC.EPRA_CAT_EDIT_TIP)
            });
            // There are categories, so display them
            else for (let category of sub_categories) {
                let row = {
                    category: category.name,
                    category_id: category.id,
                    epra_code: main_cat.value,
                    epra_label: main_cat.label,
                };
                // For each asset category
                for (let asset of props.epra_data.datasource) {
                    let category_datasets = asset.assets.find(a => a.id === category.id);

                    if (category_datasets) {
                        row[asset.id + "_d_1"] = category_datasets.datasets.period_1.datasets;
                        row[asset.id + "_d_2"] = category_datasets.datasets.period_2.datasets;
                        row[asset.id + "_e_1"] = category_datasets.datasets.period_1.emplacements;
                        row[asset.id + "_e_2"] = category_datasets.datasets.period_2.emplacements;
                        row[asset.id + "_d_lfl"] = _.intersection(category_datasets.datasets.period_1.datasets, category_datasets.datasets.period_2.datasets);
                        row[asset.id + "_e_lfl"] = _.intersection(category_datasets.datasets.period_1.emplacements, category_datasets.datasets.period_2.emplacements);
                    }
                }
                rows.push(row);
            }
        }

        return rows;
    }, [lg, props.epra_data.categories, props.epra_data.datasource]);

    const are_periods_set = React.useMemo(() => props.epra_data.periods?.period_1 && props.epra_data.periods?.period_2, [props.epra_data.periods]);
    //#endregion

    //#region Cosmetics & styles
    const period_label = React.useMemo(() => {
        if (are_periods_set) return [props.epra_data.periods.period_1.name, props.epra_data.periods.period_2.name].join(", ");
        return lg.getStaticText(TC.EPRA_MISSION_TAB_BUTTON_PERIOD);
    }, [lg, are_periods_set, props.epra_data.periods]);

    const extra_buttons = React.useMemo<G.TableProps<Row>["extra_buttons"]>(() => [
        // Period button
        {
            label: period_label,
            onClick: events.edit_period,
            buttonStyle: { className: "me-2" },
            icon: { element: "<i class='fa fa-calendar me-2'></i>" },
        },
        // Favorite button
        {
            onClick: events.edit_favorite,
            buttonStyle: { className: "me-2" },
            icon: { element: "<i class='fa fa-bookmark me-2'></i>" },
            label: lg.getStaticText(TC.EPRA_MISSION_TAB_BUTTON_FAVORITE),
        },
        // Asset categories button
        {
            disabled: () => !are_periods_set,
            buttonStyle: { className: "me-2" },
            onClick: events.edit_asset_categories,
            icon: { element: "<i class='fa fa-edit me-2'></i>" },
            label: lg.getStaticText(TC.EPRA_MISSION_TAB_BUTTON_ASSET_CAT),
        },
    ], [events, lg, period_label, are_periods_set]);

    const expandRows = React.useCallback(() => grid.current.grid.api.expandAll?.(), []);
    //#endregion

    //#region Auto-Prompt on open
    React.useEffect(() => {
        let is_active = props.active,
            was_already_prompted = auto_prompt_settled.current.assets,
            are_periods_set = props.epra_data.periods?.period_1 && props.epra_data.periods?.period_2;

        // Force the user to enter the periods, if none was found in the mission's data
        if (is_active && !are_periods_set && !was_already_prompted) {
            // Open the period prompt
            events.edit_period();
            // Set the flag to true, so the user won't be prompted again
            auto_prompt_settled.current.period = true;
        }
    }, [props.epra_data.periods, props.active, events]);

    React.useEffect(() => {
        let is_active = props.active,
            was_already_prompted = auto_prompt_settled.current.assets,
            has_no_asset_cat = (props.epra_data?.datasource || []).length === 0,
            are_periods_set = props.epra_data.periods?.period_1 && props.epra_data.periods?.period_2;

        // Force the user to enter the asset categories, if none was found in the mission's data, and the periods are set
        if (is_active && has_no_asset_cat && are_periods_set && !was_already_prompted) {
            // Open the asset prompt
            events.edit_asset_categories();
            // Set the flag to true, so the user won't be prompted again
            auto_prompt_settled.current.assets = true;
        }
    }, [props.active, props.epra_data.datasource, props.epra_data.periods, events]);
    //#endregion

    return <>
        <G.Table<Row>
            ref={grid}
            rows={rows}
            columns={columns}
            remove_unknown_columns
            onReadyGrid={expandRows}
            sideBar="filters_columns"
            columns_base="all_but_edit"
            adaptableId="epra_datasource"
            extra_buttons={extra_buttons}
            onValueChange={inline.change}
            getContextMenuItems={context_menu}
        />

        <M.BlankModal {...modal_params}>
            <div className="h-100" hidden={!show_dataset_popup.value}>
                <F.DataSets.DataPanel<ExtraRowDataset>
                    readOnly
                    no_link_panel
                    version="epra"
                    context={context}
                    ref={dataset_grid_ref}
                    buttons={inline.extra_buttons_dataset}
                    extra_columns={inline.dataset_extra_col}
                />
            </div>
            <div className="h-100" hidden={!show_emplacement_popup.value}>
                <G.Emplacement<ExtraRowEmplacement>
                    context={context}
                    className="h-100"
                    ref={emplacement_grid_ref}
                    buttons={inline.extra_buttons_emplacement}
                    extra_columns={inline.emplacement_extra_col}
                />
            </div>
        </M.BlankModal>
    </>;
}

export default DataSources;