import _ from "lodash";
import React from "react";
import * as M from "../../Modal";
import * as H from "../../../hooks";
import * as C from "../../../Common";
import * as S from "../../../services";
import { FormPanels } from "../../Form";
import * as PM from "../../../PurposeModal";
import { CellsTypes as CT } from "../AgGridDefs";
import { Table, TableProps, TableRef } from "../Grid";
import { FP, MISSION, RIGHTS, T, TABS, TB, TC } from "../../../Constants";

//#region Types
export type RemarquesTableProps<A = {}> = {
    /** The context to load the emplacements from */
    context?: T.ContextParams;
    /** The origin, to differentiate the saved configuration */
    origin?: string;
    /** Do not allow any edits */
    read_only?: boolean;
    /** Do not group the rows */
    no_group?: boolean;
    /** Which columns to auto-fit */
    auto_fit?: TableProps<Row<A>>["autoFit"];
    /** Extra columns */
    extra_columns?: TableProps<Row<A>>["columns"];
    /** Add extra data to the rows */
    process_rows?: (rows: Row[]) => Row<A>[];
    /** Callback for when the grid is ready */
    onReadyGrid?: TableProps<Row<A>>["onReadyGrid"];
    /** Only show some criticity */
    criticity_code?: T.AllowArray<Row["criticity_code"]>;
    /** Only show some status */
    status?: T.AllowArray<Row["status"]>;
    /** Buttons to show in the toolbar */
    buttons?: TableProps<Row<A>>["extra_buttons"];
    /** Callback for inline edits */
    onValueChange?: (params: Parameters<TableProps<Row<A>>["onValueChange"]>[0]) => "processed" | "to_process";
};
export type RemarquesTableRef<A = {}> = {
    /** The table Grid Ref */
    table: React.MutableRefObject<TableRef<Row<A>>>
    /** The loaded rows */
    rows: Row<A>[];
};
type Row<A = {}> = (ReturnType<T.API.EDL.GetRemarquesItems>[number]) & A;
//#endregion

//#region Constants
const TEXT_CODES = [
    TC.REM_COST_OFF, TC.REM_STATUS_CLOSED, TC.REM_STATUS_MEMO, TC.REM_STATUS_OPEN,
    FP.EMPLACEMENT_FORM, FP.EQUIPEMENT_FORM, TC.REM_COST_GT, TC.REM_COST_CONS, TC.REM_COST_MINI_GT,
    FP.REMARQUES_DEFAULTS, TC.GLOBAL_EDIT, TC.GLOBAL_DELETE, TC.REM_TABLE_ADD_ACTION,
];

export const OPTIONS = {
    status: [
        { value: "memo", label: TC.REM_STATUS_MEMO },
        { value: "open", label: TC.REM_STATUS_OPEN },
        { value: "closed", label: TC.REM_STATUS_CLOSED },
    ] as T.Option<{}, T.RemarqueDefault["status"]>[],
    cost_types: [
        { value: "GT", label: TC.REM_COST_GT },
        { value: "miniGT", label: TC.REM_COST_MINI_GT },
        { value: "consumable", label: TC.REM_COST_CONS },
        { value: "offContract", label: TC.REM_COST_OFF },
    ] as T.Option<{}, T.RemarqueDefault["costType"]>[],
}
//#endregion

const RenderRemarques = <A extends {},>({ process_rows, onValueChange, onReadyGrid, ...props }: RemarquesTableProps<A>, ref: React.ForwardedRef<RemarquesTableRef<A>>) => {
    const [forms] = H.useFormIds();
    const [{ userId }] = H.useAuth();
    const loading = H.useBoolean(false);
    const lg = H.useLanguage(TEXT_CODES);
    const rights = H.useRights(props.context);
    const table = React.useRef<TableRef<Row<A>>>(null);
    const [remarques, setRemarques, status] = H.useAsyncState<Row[]>([]);

    //#region Load rows
    React.useEffect(() => {
        let isSubscribed = true;
        S.getRemarquesItems({ context: props.context })
            .then(({ data }) => isSubscribed && setRemarques(data, "done"))
            .catch(() => isSubscribed && setRemarques([], "error"));
        return () => { isSubscribed = false };
    }, [props.context, setRemarques]);

    const rows = React.useMemo(() => {
        let base_process = remarques
            .map(r => {
                let tr_status = "", tr_costType = "", tr_responsible = "";

                let status = OPTIONS.status.filter(s => s.value === r.status)[0];
                if (status) tr_status = lg.getStaticText(status.label);

                let costType = OPTIONS.cost_types.filter(ct => ct.value === r.costType)[0];
                if (costType) tr_costType = lg.getStaticText(costType.label);

                let responsible = MISSION.RoleIntervenantsOptions.find(ro => ro.value === r.responsible)?.label;
                if (responsible) tr_responsible = lg.getStaticText(responsible);

                return {
                    ...r,
                    tr_status,
                    tr_costType,
                    tr_responsible,
                    tr_resource: lg.getStaticText(r.resource),
                    tr_criticity: lg.getTextObj(r.criticity, "level", r.criticity),
                    tr_elementName: lg.getTextObj(r.element, "name", r.elementName),
                    tr_elementCriticity: lg.getTextObj(r.elementCriticity, "level", r.elementCriticity),
                }
            }) as Row<A & any>[];

        if (typeof process_rows === "function") base_process = process_rows(base_process);
        if (props.criticity_code) {
            let codes = TB.arrayWrapper(props.criticity_code);
            base_process = base_process.filter(r => codes.includes(r.criticity_code));
        }
        if (props.status) {
            let status = TB.arrayWrapper(props.status);
            base_process = base_process.filter(r => status.includes(r.status));
        }

        return base_process;
    }, [remarques, lg, process_rows, props.criticity_code, props.status]);
    //#endregion

    //#region ContextMenu
    const rowEvent = React.useMemo(() => ({
        remove: (r: Row<A>, id?: string) => {
            M.askConfirm(null, id).then(confirmed => {
                if (confirmed) S.removeRemarques(r._id)
                    .then(() => setRemarques(p => p.filter(rem => rem._id !== r._id)))
                    .catch(M.Alerts.deleteError);
            })
        },
        edit: (r: Row<A>) => {
            M.renderFormModal<T.RemarqueDefault>({ path: FP.REMARQUES_DEFAULTS, submissionId: r._id }).then(update => {
                if (update) {
                    loading.setTrue();
                    S.getRemarquesItems({ ids: update._id, context: props.context })
                        .then(({ data }) => setRemarques(p => p.map(rem => _.find(data, d => rem._id === d._id) || rem)))
                        .catch(M.Alerts.loadError)
                        .finally(loading.setFalse);
                }
            });
        },
        move: (rem: Row<A>) => {
            const location_promise = new Promise<string>(resolve => {
                PM.renderSiteSelect({ context: props.context, isRequired: true }).then(root => {
                    if (root) M.renderLightTree({
                        root,
                        style: { size: "lg" },
                        restrictOwnLinks: true,
                        selection: rem.element,
                        linkRestriction: {
                            excludeIds: rem.element,
                            objForm: FP.GROUP_REMARQUES.map(p => forms[p]),
                        }
                    }).then(parent => resolve(parent))
                    else resolve(null);
                });
            });

            location_promise.then(new_origin => {
                if (new_origin) S.update_remarque_field({ _id: rem._id, force_update: true, new_value: new_origin, old_value: rem.element, field: "element" }).then(({ data }) => {
                    if (data !== "changed" && data.length > 0) setRemarques(p => {
                        const alreadyExists = p.map(d => d._id);
                        const newItems = data.filter(d => !alreadyExists.includes(d._id));
                        return p.map(d => data.filter(nd => nd._id === d._id)[0] || d)
                            .concat(newItems);
                    });
                }).catch(M.Alerts.loadError);
            });
        },
        edit_element: (row: Row<A>) => {
            // Find the right to edit the element
            let write_right = null;
            if (row.resource === FP.SITE_FORM) write_right = RIGHTS.TECH.EDIT_SITE;
            else if (row.resource === FP.BUILDING_FORM) write_right = RIGHTS.TECH.EDIT_BUILDING;
            else if (row.resource === FP.EQUIPEMENT_FORM) write_right = RIGHTS.TECH.EDIT_EQUIPMENT;
            else if (row.resource === FP.EMPLACEMENT_FORM) write_right = RIGHTS.TECH.EDIT_EMPLACEMENT;
            else write_right = RIGHTS.TECH.WRITE_OTHERS;
            // Check if the user has the right to edit the element
            let readOnly = !rights.isRightAllowed(write_right);
            M.renderPopUpFormModal({ submissionId: row.element, path: row.resource, readOnly }).then(submission => {
                if (submission) {
                    loading.setTrue();
                    S.getRemarquesItems({ ids: row._id, context: props.context })
                        .then(({ data }) => setRemarques(p => p.map(rem => _.find(data, d => rem._id === d._id) || rem)))
                        .catch(M.Alerts.loadError)
                        .finally(loading.setFalse);
                }
            });
        }
    }), [setRemarques, loading, rights, props.context, forms]);

    const actions = React.useMemo(() => ({
        showActions: (r: Row<A>) => M.renderBlankModal({
            size: "md",
            title: TC.REM_TABLE_ACTION_MODAL_TITLE,
            children: <FormPanels.ActionsRemarquesPanel elementId={r.element} remarque={r._id} description={r.description} context={props.context} />,
        }),
        addAction: (r: Row<A>) => {
            let submission: Partial<T.TicketData> = { remarque: r._id, type: "maintenance", equipment: r.element };
            M.renderFormModal<T.TicketData>({ forcedSubmission: TB.submissionToArrayUpdate(submission), path: FP.TICKET_FORM }).then(ticket => {
                if (ticket) setRemarques(p => p.map(rem => rem._id === r._id ? { ...rem, actions: r.actions + 1 } : rem));
            });
        }
    }), [setRemarques, props.context]);

    const getContextMenu = React.useCallback<TableProps<Row<A>>["getContextMenuItems"]>((params) => {
        let rem = params.node?.data;
        let menuItems: string | Record<string, any> = [];
        let defaultItems = TB.getArray(params.defaultItems);

        let isOwn = rem?.owner === userId;
        let editRight = isOwn ? RIGHTS.MISC.WRITE_OWN_REMARQUES : RIGHTS.MISC.WRITE_OTHER_REMARQUES;
        let canEdit = rights.isRightAllowed(editRight, rem?.element);

        let canAddRem = rights.isRightAllowed(RIGHTS.MISC.WRITE_OWN_REMARQUES, rem?.element);

        if (!props.read_only) {
            if (rem && canEdit) menuItems.push(
                {
                    action: () => rowEvent.edit(rem),
                    icon: "<i class='fa fa-pencil-alt'></i>",
                    name: lg.getStaticText(TC.GLOBAL_EDIT, FP.REMARQUES_DEFAULTS),
                },
                {
                    action: () => rowEvent.remove(rem),
                    icon: "<i class='fa fa-times'></i>",
                    name: lg.getStaticText(TC.GLOBAL_DELETE),
                },
                {
                    action: () => rowEvent.move(rem),
                    icon: '<i class="fa fa-arrows-alt"></i>',
                    name: lg.getStaticText(TC.TABLE_REMS_MOVE_REM),
                },
            );
            if (rem && canAddRem) menuItems.push({
                action: () => actions.addAction(rem),
                icon: "<i class='fa fa-calendar-plus'></i>",
                name: lg.getStaticText(TC.REM_TABLE_ADD_ACTION),
            });
        }

        if (menuItems.length > 0 && defaultItems.length > 0) menuItems.push("separator");
        return menuItems.concat(defaultItems);
    }, [rowEvent, lg, rights, userId, actions, props.read_only]);
    //#endregion

    //#region Columns
    const header_id = React.useMemo(() => forms[FP.REMARQUES_DEFAULTS], [forms]);

    const options = React.useMemo(() => ({
        tags: (row: Row) => new Promise<T.Option[]>((resolve, reject) => {
            S.getNoteTags({ context: { roots: row._id }, type: "rems" })
                .then(r => resolve(r.data))
                .catch(reject);
        }),
        create_tag: (text: string, row: Row) => new Promise<T.Option>((resolve, reject) => {
            let submission = { name: text, users: [userId], sites: [], type: "rems" } as T.NoteTag;
            S.createSubmission({ submission, path: FP.NOTE_TAG_FORM }).then(({ data }) => {
                let new_tag = data.submissions[0] as T.Submission<T.NoteTag>;
                if (new_tag) {
                    let new_option = { label: new_tag.data.name, value: new_tag._id } as T.Option;
                    resolve(new_option);
                }
                else resolve(null);
            }).catch(reject);
        }),
    }), [userId]);

    const columns = React.useMemo<TableProps<Row<A>>["columns"]>(() => [
        {
            field: "actions",
            type: CT.TYPE_ACTION_BUTTON,
            headerName: " ",
            params: {
                header_id,
                content: row => row?.actions,
                action: row => row && actions.showActions(row),
                isDisabled: row => props.read_only || row?.actions === 0,
                buttonProps: { variant: "primary", size: "sm", tip: TC.REM_TABLE_SHOW_ACTIONS },
            }
        },
        { field: "tr_resource", headerName: TC.RESOURCE },
        { field: "tr_elementName", headerName: "element", type: CT.TYPE_LINKED_ELEM, params: { header_id, render_form: rowEvent.edit_element } },
        { field: "date", headerName: "date", type: CT.TYPE_DATE, params: { header_id, certify: true } },
        {
            headerName: "tags",
            field: "tags_names",
            type: CT.TYPE_SELECT,
            editable: !props.read_only,
            params: {
                multiple: true,
                field_value: "tags",
                header_id: header_id,
                getValues: options.tags,
                typeahead: { allowNewOption: true, onAddOption: options.create_tag },
            }
        },
        { field: "created", headerName: TC.GLOBAL_CREATED_DATE, type: CT.TYPE_DATE },
        { field: "tr_elementCriticity", headerName: "elementCriticity", params: { header_id } },
        { field: "tr_criticity", headerName: "criticity", params: { header_id, certify: { prop: "criticity" } } },
        { field: "description", headerName: "description", params: { header_id, certify: true } },
        { field: "tr_responsible", headerName: "responsible", params: { header_id, certify: { prop: "responsible" } } },
        { field: "responsible_other", headerName: "responsible_other", params: { header_id, certify: true } },
        { field: "action", headerName: "action", params: { header_id, certify: true } },
        { field: "deadlineDate", headerName: "deadline", type: CT.TYPE_DATE, params: { header_id } },
        { field: "component", headerName: "component", params: { header_id } },
        { field: "tr_status", headerName: "status", params: { header_id, certify: { prop: "status" } } },
        { field: "tr_costType", headerName: "costType", params: { header_id, certify: { prop: "costType" } } },
        { field: "pictures", headerName: "pictures", type: CT.TYPE_FILE, params: { header_id } },
        { field: "site_name", headerName: FP.SITE_FORM },
        { field: "build_name", headerName: FP.BUILDING_FORM, rowGroupIndex: props.no_group ? undefined : 0 },
        { field: "floor_name", headerName: TC.GLOBAL_FLOOR, rowGroupIndex: props.no_group ? undefined : 1 },
        { field: "local_name", headerName: TC.GLOBAL_LOCAL, rowGroupIndex: props.no_group ? undefined : 2 },
        { field: "parking_name", headerName: TC.GLOBAL_LABEL_PARKING },
        { field: "full_loc_name", headerName: TC.GLOBAL_FULL_LOC },
        ...(props.extra_columns || []),
    ], [actions, rowEvent, header_id, options, props.read_only, props.extra_columns, props.no_group]);

    const expandRows = React.useCallback<TableProps<Row<A>>["onReadyGrid"]>(grid => {
        if (status !== "load") {
            onReadyGrid?.(grid);
            grid?.api?.expandAll?.();
        }
    }, [status, onReadyGrid]);
    //#endregion

    //#region Edits
    const change_value = React.useCallback<TableProps<Row<A>>["onValueChange"]>(params => {
        let row = params.data,
            old_value = params.oldValue,
            field = params.colDef.field as keyof typeof rows[number];

        if (props.read_only) return;
        let change = onValueChange?.(params) || "to_process";
        if (change === "to_process") {
            let prop: keyof T.RemarqueDefault;
            // Set the right prop
            if (field === "tags_names") {
                prop = "tags";
                old_value = row.tags;
            }
            else prop = field as typeof prop;

            const updatePromise = new Promise<"cancel" | Row[]>((resolve, reject) => {
                if (!_.isEqual(old_value, params.newValue)) {
                    let api_params = {
                        field: prop,
                        _id: row._id,
                        old_value: old_value,
                        new_value: params.newValue,
                    } as Parameters<typeof S.update_remarque_field>[0];

                    loading.setTrue();

                    S.update_remarque_field(api_params).then(({ data }) => {
                        if (data === "changed") M.askConfirm({ title: TC.UPDATE_FORCE_CHANGE, text: TC.UPDATE_VALUE_UNFRESH }).then(confirmed => {
                            if (confirmed) S.update_remarque_field({ ...api_params, force_update: true }).then(({ data }) => {
                                if (data === "changed") reject("Error");
                                else resolve(data);
                            }).catch(reject);
                            else resolve("cancel");
                        });
                        else resolve(data);
                    }).catch(reject);
                }
                else resolve("cancel");
            });

            updatePromise.then(rows => {
                if (rows !== "cancel") setRemarques(p => {
                    let existing_ids = p.map(r => r._id);
                    let [to_update, to_add] = _.partition(rows, r => existing_ids.includes(r._id));
                    return p
                        .map(r => to_update.filter(row => row._id === r._id)[0] || r)
                        .concat(to_add);
                })
            })
                .catch(M.Alerts.updateError)
                .finally(loading.setFalse);
        }
    }, [props.read_only, loading, onValueChange, setRemarques]);
    //#endregion

    React.useImperativeHandle(ref, () => ({ table, rows }), [rows]);

    return <div className="w-100">
        <C.Spinner error={status === "error"}>
            <Table<Row<A>>
                sideBar
                ref={table}
                rows={rows}
                status={status}
                columns={columns}
                loading={loading.value}
                onReadyGrid={expandRows}
                columns_base="all_but_edit"
                onValueChange={change_value}
                extra_buttons={props.buttons}
                getContextMenuItems={getContextMenu}
                adaptableId={props.origin || TABS.REMARQUES_TABLE}
                autoFit={React.useMemo(() => props.auto_fit || ["actions"], [props.auto_fit])}
                certification={React.useMemo(() => ({ form: forms[FP.REMARQUES_DEFAULTS], item_id_prop: "_id", context: props.context }), [forms, props.context])}
            />
        </C.Spinner>
    </div>;
}

export const Remarques = React.forwardRef(RenderRemarques) as <A>(props: RemarquesTableProps<A> & Partial<Record<"ref", React.ForwardedRef<RemarquesTableRef<A>>>>) => React.ReactElement;

export const RemarquesContext: React.FC = () => {
    const [roots] = H.useRoots();
    H.useCrumbs(TC.TAB_REMARQUES);
    H.useAuth({ tabName: TABS.REMARQUES_TABLE });

    return <Remarques context={roots} origin={TABS.REMARQUES_TABLE} />
}