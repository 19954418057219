import React from "react";
import * as H from "../../hooks";
import { TABS, TC } from "../../Constants";

const Environment: React.FC = () => {
    H.useCrumbs(TC.TAB_ENV_DASHBOARD);
    H.useAuth({ tabName: TABS.ENV_DASHBOARD });


    return <div>Environment</div>;
};

export default Environment;