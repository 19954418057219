/** List of rights for the reg package */
export const REG = {
    /** Write access over own reg docs */
    WRITE_OWN_REG_DOCS: "WRITE_OWN_REG_DOCS",
    /** Read-Only Access to other's reg docs */
    READ_OTHER_REG_DOCS: "READ_OTHER_REG_DOCS",
    /** Write access to other's reg docs */
    WRITE_OTHER_REG_DOCS: "WRITE_OTHER_REG_DOCS",
    /** Read Access to Site-specific reg actions */
    READ_SITE_REG_ACTIONS: "READ_SITE_REG_ACTIONS",
    /** Write access to Site-specific reg actions */
    WRITE_SITE_REG_ACTIONS: "WRITE_SITE_REG_ACTIONS",
    /** Write access to global reg actions */
    WRITE_REG_ACTIONS: "WRITE_REG_ACTIONS",
    /** Read access to an element's reg attributions */
    READ_ELEMENT_REG: "READ_ELEMENT_REG",
    /** Write access to an element's reg attributions */
    WRITE_ELEMENT_REG: "WRITE_ELEMENT_REG",
    /** Access to edit the applicable reg on an element */
    EDIT_APPLICABLE_REG: "EDIT_APPLICABLE_REG",
}

/** List of rights for the action package */
export const ACTION = {
    /** Allows for the generation of an action activity report */
    TICKET_REPORT: "TICKET_REPORT",
    /** Write access to own's event */
    WRITE_EVENTS: "WRITE_EVENTS",
    /** Read access to other's events */
    READ_OTHER_EVENTS: "READ_OTHER_EVENTS",
    /** Write access to other's events */
    WRITE_OTHER_EVENTS: "WRITE_OTHER_EVENTS",
    /** Write access to own's reg actions */
    WRITE_REG_ACTIONS: "WRITE_REG_ACTIONS",
    /** Read access to other's reg actions */
    READ_OTHER_REG_ACTION: "READ_OTHER_REG_ACTION",
    /** Write access to other's reg actions */
    WRITE_OTHER_REG_ACTION: "WRITE_OTHER_REG_ACTION",
    /** Write access to own's maintenance action */
    WRITE_MAINTENANCE_ACTION: "WRITE_MAINTENANCE_ACTION",
    /** Read access to other's maintenance action  */
    READ_OTHER_MAINTENANCE_ACTION: "READ_OTHER_MAINTENANCE_ACTION",
    /** Write access to other's maintenance action  */
    WRITE_OTHER_MAINTENANCE_ACTION: "WRITE_OTHER_MAINTENANCE_ACTION",
}

/** List of rights for the energy package */
export const NRJ = {
    /** Read access to an item's energy tags */
    READ_NRJ_TAGS: "READ_NRJ_TAGS",
    /** Write access to an item's MANUAL energy tags */
    WRITE_NRJ_TAGS: "WRITE_NRJ_TAGS",
    /** Write access to an item's AUTO energy tags */
    WRITE_NRJ_TAGS_AUTO: "WRITE_NRJ_TAGS_AUTO",
    /** Write access for own data */
    WRITE_OWN_DATA: "WRITE_OWN_DATA",
    /** Write access for other's data */
    WRITE_OTHER_DATA: "WRITE_OTHER_DATA",
    /** Write access for anonymous data */
    WRITE_ANON_DATA: "WRITE_ANON_DATA",
    /** Read access to other user's alarms */
    READ_OTHER_ALARMS: "READ_OTHER_ALARMS",
    /** Write access to other's alarms */
    WRITE_OTHER_ALARMS: "WRITE_OTHER_ALARMS",
}

/** List of rights for the tech package */
export const TECH = {
    /** Create access for an equipment */
    CREATE_EQUIPMENT: "CREATE_EQUIPMENT",
    /** Create access for an emplacement */
    CREATE_EMPLACEMENT: "CREATE_EMPLACEMENT",
    /** Create access for a building */
    CREATE_BUILDING: "CREATE_BUILDING",
    /** Create access for a site */
    CREATE_SITE: "CREATE_SITE",
    /** Edit access for an equipment */
    EDIT_EQUIPMENT: "EDIT_EQUIPMENT",
    /** Edit access for an emplacement */
    EDIT_EMPLACEMENT: "EDIT_EMPLACEMENT",
    /** Edit access for a building */
    EDIT_BUILDING: "EDIT_BUILDING",
    /** Edit access for a site */
    EDIT_SITE: "EDIT_SITE",
    /** Delete access for an equipment */
    DELETE_EQUIPMENT: "DELETE_EQUIPMENT",
    /** Delete access for an emplacement */
    DELETE_EMPLACEMENT: "DELETE_EMPLACEMENT",
    /** Delete access for a building */
    DELETE_BUILDING: "DELETE_BUILDING",
    /** Delete access for a site */
    DELETE_SITE: "DELETE_SITE",
    /** Move access for an equipment */
    MOVE_EQUIPMENT: "MOVE_EQUIPMENT",
    /** Move access for an emplacement */
    MOVE_EMPLACEMENT: "MOVE_EMPLACEMENT",
    /** Move access for a building */
    MOVE_BUILDING: "MOVE_BUILDING",
    /** Move access for a site */
    MOVE_SITE: "MOVE_SITE",
    /** Right access to other elements */
    WRITE_OTHERS: "WRITE_OTHERS",
    /** Write access to non-generic equipment types */
    WRITE_EQUIP_TYPE: "WRITE_EQUIP_TYPE",
}

/** List of rights for diverse package */
export const MISC = {
    /** Write access to own's remarques */
    WRITE_OWN_REMARQUES: "WRITE_OWN_REMARQUES",
    /** Read access to other's remarques */
    READ_OTHER_REMARQUES: "READ_OTHER_REMARQUES",
    /** Write access to other's remarques */
    WRITE_OTHER_REMARQUES: "WRITE_OTHER_REMARQUES",
    /** Write access to own's notes */
    WRITE_OWN_NOTES: "WRITE_OWN_NOTES",
    /** Read access to other's notes */
    READ_OTHER_NOTES: "READ_OTHER_NOTES",
    /** Write access to other's notes */
    WRITE_OTHER_NOTES: "WRITE_OTHER_NOTES",
    /** Write access to own's contracts */
    WRITE_OWN_CONTRACTS: "WRITE_OWN_CONTRACTS",
    /** Read access to other's contracts */
    READ_OTHER_CONTRACTS: "READ_OTHER_CONTRACTS",
    /** Write access to other's contracts */
    WRITE_OTHER_CONTRACTS: "WRITE_OTHER_CONTRACTS",
}

export const MISSION = {
    /** Allows to use the missions */
    ACCESS_MISSIONS: "ACCESS_MISSIONS",
    /** Allow the creation of CDM Missions */
    ALLOW_CDM_MISSIONS: "ALLOW_CDM_MISSIONS",
    /** Allow to see a report in the ID Card of CDM Missions */
    ALLOW_CDM_MISSIONS_REPORT: "ALLOW_CDM_MISSIONS_REPORT",
    /** Allow the creation of EDL Missions */
    ALLOW_EDL_MISSIONS: "ALLOW_EDL_MISSIONS",
    /** Allow to see a report in the ID Card of EDL Missions */
    ALLOW_EDL_MISSIONS_REPORT: "ALLOW_EDL_MISSIONS_REPORT",
    /** Allow the creation of NRJ report Missions */
    ALLOW_NRJ_MISSIONS: "ALLOW_NRJ_MISSIONS",
    /** Allow to see a report in the ID Card of NRJ report Missions */
    ALLOW_NRJ_MISSIONS_REPORT: "ALLOW_NRJ_MISSIONS_REPORT",
    /** Allow the creation of Red Flag Missions */
    ALLOW_RED_FLAG_MISSIONS: "ALLOW_RED_FLAG_MISSIONS",
    /** Allow to see a report in the ID Card of Red Flag Missions */
    ALLOW_RED_FLAG_MISSIONS_REPORT: "ALLOW_RED_FLAG_MISSIONS_REPORT",
    /** Allow the creation of Red Flag Missions */
    ALLOW_REG_MISSIONS: "ALLOW_REG_MISSIONS",
    /** Allow to see a report in the ID Card of Red Flag Missions */
    ALLOW_REG_MISSIONS_REPORT: "ALLOW_REG_MISSIONS_REPORT",
    /** Allow the creation of EU Taxonomy missions */
    ALLOW_EU_TAXONOMY_MISSIONS: "ALLOW_EU_TAXONOMY_MISSIONS",
    /** Allow to see a report in the ID Card of EU Taxonomy missions */
    ALLOW_EU_TAXONOMY_MISSIONS_REPORT: "ALLOW_EU_TAXONOMY_MISSIONS_REPORT",
    /** Allow the creation of RSC01 Missions */
    ALLOW_RSC01_MISSIONS: "ALLOW_RSC01_MISSIONS",
    /** Allow to see a report in the ID Card of RSC01 Missions */
    ALLOW_RSC01_MISSIONS_REPORT: "ALLOW_RSC01_MISSIONS_REPORT",
    /** Allow the creation of EPRA Missions */
    ALLOW_EPRA_MISSIONS: "ALLOW_EPRA_MISSIONS",
    /** Allow to see a report in the ID Card of EPRA Missions */
    ALLOW_EPRA_MISSIONS_REPORT: "ALLOW_EPRA_MISSIONS_REPORT",
}



// Not yet in new system or won't be at all
export const GGM_EDIT = "gGmEdit";
export const GU_EDIT = "gUserEdit";
export const TREE_READ = "treeRead";
export const TREE_LINK = "treeLink";
export const TREE_EDIT = "treeEdit";
export const GSE_EDIT = "gStEqEdit";
export const GTE_EDIT = "gTechEdit";
export const GE_CREATE = "gEqCreate";
export const GE_DELETE = "gEqDelete";
export const GE_EXPORT = "gEqExport";
export const GE_IMPORT = "gEqImport";
export const TREE_PERMS = "treePerms";
export const GGM_CREATE = "gGmCreate";
export const GGM_DELETE = "gGmDelete";
export const GGM_EXPORT = "gGmExport";
export const GGM_IMPORT = "gGmImport";
export const GU_CREATE = "gUserCreate";
export const GU_DELETE = "gUserDelete";
export const GU_EXPORT = "gUserExport";
export const TREE_CREATE = "treeCreate";
export const TREE_DELETE = "treeDelete";
export const TREE_UNLINK = "treeUnLink";
export const GSE_CREATE = "gStEqCreate";
export const GSE_DELETE = "gStEqDelete";
export const GSE_EXPORT = "gStEqExport";
export const GTE_CREATE = "gTechCreate";
export const GTE_DELETE = "gTechDelete";
export const GTE_EXPORT = "gtTechExport";
export const ER_DELETE = "evReaderDelete";
export const GU_PASSWORD = "gUserPassword";
export const GTE_PASSWORDS = "gTechPassword";
export const ER_CLOSE_TICKET = "evReaderClose";
export const P_EDIT_EVENT = "planningEditEvent";
export const GT_EDIT_EVENT = "gTicketEditEvent";
export const P_EDIT_TICKET = "planningEditTicket";
export const GT_EDIT_TICKET = "gTicketEditTicket";
export const GE_CREATE_TICKET = "gEqCreateTicket";
export const N_ACCESS_ALL_NOTES = "accessAllNotes";
export const N_ACCESS_OWN_NOTES = "accessOwnNotes";
export const P_CREATE_EVENT = "planningCreateEvent";
export const P_DELETE_EVENT = "planningDeleteEvent";
export const P_CLOSE_TICKET = "planningCloseTicket";
export const GT_CREATE_EVENT = "gTicketCreateEvent";
export const GT_CLOSE_TICKET = "gTicketCloseTicket";
export const P_DELETE_TICKET = "planningDeleteTicket";
export const P_CREATE_TICKET = "planningCreateTicket";
export const GT_CREATE_TICKET = "gTicketCreateTicket";
export const GT_DELETE_TICKET = "gTicketDeleteTicket";
export const NO_ACTION_REG_CREATE = "noActionRegCreate";
export const GTE_RIGHTS_ACCESS = "gTechEditRightsAccess";
export const ACTION_REG_CLIENT_CREATE = "actionRegClientCreate";
export const P_DELETE_EVENT_TICKETS = "planningDeleteEventTicket";
export const CAN_EDIT_ACTIONS_REGULATION = "canEditActionRegulation";
export const CAN_EDIT_ACTIONS_MAINTENANCE = "canEditActionMaintenance";
export const NO_ACTION_MAINTENANCE_CREATE = "noActionMaintenanceCreate";
export const ACTION_MAINTENANCE_CLIENT_CREATE = "actionMaintenanceClientCreate";

export const ALL_RIGHTS = [
    GU_EDIT,
    GGM_EDIT,
    GSE_EDIT,
    GTE_EDIT,
    TREE_READ,
    TREE_LINK,
    TREE_EDIT,
    GE_CREATE,
    GE_DELETE,
    GE_EXPORT,
    GE_IMPORT,
    GU_CREATE,
    GU_DELETE,
    GU_EXPORT,
    ER_DELETE,
    TREE_PERMS,
    GGM_CREATE,
    GGM_DELETE,
    GGM_EXPORT,
    GGM_IMPORT,
    GSE_CREATE,
    GSE_DELETE,
    GSE_EXPORT,
    GTE_CREATE,
    GTE_DELETE,
    GTE_EXPORT,
    TREE_CREATE,
    TREE_DELETE,
    TREE_UNLINK,
    GU_PASSWORD,
    P_EDIT_EVENT,
    GTE_PASSWORDS,
    GT_EDIT_EVENT,
    P_EDIT_TICKET,
    GT_EDIT_TICKET,
    P_CREATE_EVENT,
    P_DELETE_EVENT,
    P_CLOSE_TICKET,
    ER_CLOSE_TICKET,
    GT_CREATE_EVENT,
    GT_CLOSE_TICKET,
    P_DELETE_TICKET,
    P_CREATE_TICKET,
    GE_CREATE_TICKET,
    GT_CREATE_TICKET,
    GT_DELETE_TICKET,
    GTE_RIGHTS_ACCESS,
    N_ACCESS_ALL_NOTES,
    N_ACCESS_OWN_NOTES,
    NO_ACTION_REG_CREATE,
    P_DELETE_EVENT_TICKETS,
    ACTION_REG_CLIENT_CREATE,
    CAN_EDIT_ACTIONS_REGULATION,
    CAN_EDIT_ACTIONS_MAINTENANCE,
    NO_ACTION_MAINTENANCE_CREATE,
    ACTION_MAINTENANCE_CLIENT_CREATE,
];