import _ from "lodash";
import React from "react";
import moment from "moment";
import * as M from "../Modal";
import * as G from "../Gestion";
import * as H from "../../hooks";
import * as C from "../../Common";
import * as S from "../../services";
import * as BS from "react-bootstrap";
import { T, TB, TC, DS } from "../../Constants";

//#region Types
export type EnergyProps = {
    /** The id of the building */
    building: string;
    /** The default tab to open */
    tab?: Tab["code"];
    /** The datasets selected by default */
    datasets?: string[];
    /** The times selected by default */
    times?: Record<"from" | "to", string>;
    /** How to group the data by default */
    group?: Parameters<T.API.Utils.Energy.EntityDataCsv>[0]["group"];
}

type Tab = {
    /** The unique code of the tab */
    code: "global" | "cost" | "heatmap" | "energy_signature" | "datasets";
    /** The label reference of the tab */
    label: string;
    /** The list of tools available to configure the data of this tab */
    tools: ("time_group" | "cost" | "datasets" | "export")[];
    /** The name of the JSReport Template to load the HTML from */
    template: string;
    /** The groups that aren't available to use on this tab */
    disabled_groups: EnergyProps["group"][];
    /** Only allow a selection of years in the time selector */
    only_years?: boolean;
    /** Show the data table, representing the values loaded */
    show_data_table?: boolean;
    /** A forced type of energy to provide */
    energy?: "all" | T.DataSet["type"];
    /** A list of extra sub_menu to possibly render */
    sub_menu?: Record<"label" | "template", string>[];
    /** Enforce the rule that there should at least be a datasets before loading the template */
    enforce_datasets?: boolean;
    /** The default config for the tab */
    config: {
        /** The start date of the data */
        from?: Date;
        /** The end date of the data */
        to?: Date;
        /** The pre-defined time interval, instead of a from/to pair */
        interval?: string;
        /** The datasets selected */
        datasets?: string[];
        /** The different types of time groups */
        time_group?: EnergyProps["group"];
        /** Various costs that can vary */
        costs?: Record<"water" | "gas" | "elec", number>;
        /** The submissions paths to filter datasets on */
        path?: "all" | string;
        /** The type of energies to filter datasets on */
        energy?: Tab["energy"];
        /** The source of datasets to filter datasets on */
        source?: "all" | T.DataSet["src"];
    }
}

type Data = Awaited<ReturnType<typeof S.getEnergyGraphs>>["data"];
//#endregion

//#region Constants
const TABS: Tab[] = [
    {
        code: "global",
        template: "Global",
        disabled_groups: [],
        tools: ["time_group"],
        label: TC.GP_BUILD_TAB_GLOBAL,
        config: { time_group: "month", interval: "1 YEAR" },
    },
    {
        code: "cost",
        template: "Couts",
        label: TC.GP_BUILD_TAB_COST,
        tools: ["cost", "time_group"],
        disabled_groups: ["minute", "hour", "minuted"],
        config: { time_group: "day", interval: "15 DAY", costs: { water: 4, gas: 0.12, elec: 0.01 } },
    },
    {
        tools: [],
        energy: "CO2",
        template: "CO2",
        code: "heatmap",
        only_years: true,
        label: TC.NRJ_CO2,
        disabled_groups: ["minute", "minuted", "hour"],
        config: { from: new Date("01/01/2020 01:00"), to: new Date("12/31/2050"), energy: "CO2" },
    },
    {
        tools: ["time_group"],
        template: "SignatureE",
        code: "energy_signature",
        label: TC.GP_BUILD_TAB_ENERGY_SIGN,
        disabled_groups: ["minute", "minuted", "hour"],
        config: { time_group: "day", interval: "15 DAY" },
    },
    {
        energy: "all",
        code: "datasets",
        disabled_groups: [],
        template: "Compteur",
        show_data_table: true,
        enforce_datasets: true,
        label: TC.GP_BUILD_TAB_ENTITY,
        tools: ["datasets", "export", "time_group"],
        config: { interval: "15 DAY", time_group: "day", energy: "all", source: "all", path: "all", datasets: [] },
        sub_menu: [
            { label: TC.NRJ_DASH_SUB_MENU_BAR, template: "Compteur" },
            { label: TC.NRJ_DASH_SUB_MENU_HEATMAP, template: "CompteurHEATONLY" },
            { label: TC.NRJ_DASH_SUB_MENU_INDEX, template: "CompteurINDEXONLY" },
        ],
    },
];
//#endregion

const Energy: React.FC<EnergyProps> = props => {

    const default_config = React.useMemo(() => Object.fromEntries(
        TABS.map(tab => [tab.code, {
            ...tab.config,
            to: props.times?.to ? new Date(props.times.to) : tab.config.to,
            from: props.times?.from ? new Date(props.times.from) : tab.config.from,
            interval: props.times?.from && props.times?.to ? undefined : tab.config.interval,
            datasets: tab.config.datasets ? props.datasets || tab.config.datasets : undefined,
            time_group: tab.config.time_group ? props.group || tab.config.time_group : undefined,
        }])
    ) as Record<Tab["code"], Tab["config"]>, [props]);

    const lg = H.useLanguage();
    const [{ isAdmin }] = H.useAuth();
    const loading = H.useBoolean(false);
    const [configs, set_configs] = React.useState(default_config);
    const [data, set_data] = H.useAsyncState<Data>({ datasets: [] });
    const load_template_ref = React.useRef<typeof load_template>(null);
    const [html, set_html] = React.useState<Record<string, string>>({});
    const [current_tab, set_current_tab] = React.useState(props.tab || "global");
    const [current_sub_menu, set_current_sub_menu] = React.useState<string>(TABS.find(t => t.code === props.tab)?.sub_menu?.[0]?.template || undefined);
    const [{ dropdown }, { setFilters }] = H.useFavorite({ origin: props.building + "_energy", variant: "secondary", applyFav: set_configs, no_default: true });

    const load_template = React.useCallback((tab = current_tab, template = current_sub_menu) => {
        let isSubscribed = true;
        let config = configs[tab];
        let tab_definition = TABS.find(t => t.code === tab);
        let load_template = template || tab_definition.template;
        let time = TB.getFromTo({ from: config.from?.toISOString?.(), to: config.to?.toISOString?.(), interval: config.interval });

        let params = {
            to: time.to,
            from: time.from,
            group: config.time_group,
            building: props.building,
            priceGaz: config.costs?.gas,
            energy: tab_definition.energy,
            priceEau: config.costs?.water,
            priceElec: config.costs?.elec,
            entity: (config.datasets || []).join(),
        };
        // Don't try to load, because no datasets selected
        if (!tab_definition.enforce_datasets || config.datasets.length > 0) {
            loading.setTrue();
            S.html_report({ template: load_template, params })
                .then(({ data }) => isSubscribed && set_html(p => ({ ...p, [template || tab]: data })))
                .catch(() => isSubscribed && set_html(p => ({ ...p, [template || tab]: "<div></div>" })))
                .finally(loading.setFalse);
        }

        return () => {
            isSubscribed = false;
        };
    }, [configs, current_tab, current_sub_menu, loading, props.building]);

    const change = React.useMemo(() => ({
        tab: (tab: Tab["code"]) => {
            let tab_definition = TABS.find(t => t.code === tab);
            // The tab has sub-menus, so we need to load the first sub-menu
            if (Array.isArray(tab_definition.sub_menu)) {
                let sub_menu = tab_definition.sub_menu[0].template;
                // Load the new template, if it was not already loaded
                if (typeof html[sub_menu] !== "string") load_template(tab, sub_menu);
                // Set the new sub_menu as active
                set_current_sub_menu(sub_menu);
            }
            else {
                // Load the new template, if it was not already loaded
                if (typeof html[tab] !== "string") load_template(tab);
                // Reset the sub-menu
                set_current_sub_menu(undefined);
            }
            // Set the new tab as active
            set_current_tab(tab);
        },
        sub_menu: (tab: Tab["code"], sub_menu: string) => {
            // Load the new template, if it was not already loaded
            if (typeof html[sub_menu] !== "string") load_template(tab, sub_menu);
            // Set the new sub_menu as active
            set_current_sub_menu(sub_menu);
        }
    }), [load_template, html]);

    const current = React.useMemo(() => ({
        code: current_tab,
        config: configs[current_tab],
        tab: TABS.find(t => t.code === current_tab),
    }), [current_tab, configs]);

    React.useEffect(() => {
        let isSubscribed = true;
        S.getEnergyGraphs(props.building)
            .then(({ data }) => isSubscribed && set_data(data, "done"))
            .catch(() => isSubscribed && set_data({ datasets: [] }, "error"));
        return () => {
            isSubscribed = false;
            set_data({ datasets: [] }, "load");
        }
    }, [props.building, set_data]);

    // Keep the reference of the load_template function up to date
    React.useImperativeHandle(load_template_ref, () => load_template, [load_template]);
    // Reset the configuration when the building change or the language change
    React.useEffect(() => () => set_configs(default_config), [default_config, lg.prop]);
    // Reset the html when the building change or the language change
    React.useEffect(() => () => set_html({}), [props.building, lg.prop]);
    // Reload the html when the language changes + Load the first template when the pages load
    React.useEffect(() => load_template_ref.current(), [lg.prop, props.building]);
    // Update the favorite filters when the configuration changes
    React.useEffect(() => setFilters(configs), [configs, setFilters]);

    const events = React.useMemo(() => ({
        export: () => {
            let config = current.config;
            let time = TB.getFromTo({ from: config.from?.toISOString?.(), to: config.to?.toISOString?.(), interval: config.interval });
            let dismount = M.renderLoader();
            S.entityDataCsv({ entities: config.datasets || [], from: time.from, to: time.to, group: config.time_group })
                .then(({ data }) => TB.aoaToExcel(data, moment().format("YYYYMMDD_HHmmss"), "Data"))
                .catch(() => M.renderAlert({ type: "error", message: TC.GLOBAL_FAIL_GEN_FILE }))
                .finally(dismount);
        },
        set_cost: (type: keyof Tab["config"]["costs"], value?: number) => {
            set_configs(c => ({ ...c, [current_tab]: { ...c[current_tab], costs: { ...c[current_tab].costs, [type]: value } } }));
        },
        set_config: (update: Partial<Tab["config"]>) => {
            set_configs(c => ({ ...c, [current_tab]: { ...c[current_tab], ...update } }));
        },
        refresh: () => {
            // The tab has submenus, so reset those templates
            if (current.tab.sub_menu) current.tab.sub_menu.forEach(sub => set_html(p => ({ ...p, [sub.template]: undefined })));
            load_template(current_tab, current_sub_menu);
        }
    }), [current_tab, current.config, current.tab, current_sub_menu, load_template]);

    const datasets_filters = React.useMemo(() => {
        // The paths of the items that have a datasets attached to them
        let paths = _.uniq(data.datasets.map(d => d.path_parent))
            .map(path => ({ label: path, value: path }) as T.Option);
        // Add an option to select all
        paths.unshift({ label: TC.GLOBAL_ALL, value: "all" });

        // Filter the available energies found in the context's datasets
        let energies = _.uniq(data.datasets.map(d => d.type))
            .map(type => ({ label: DS.DATASETS.type.find(t => t.value === type)?.label || type, value: type }) as T.Option);
        // Add an option to select all energies
        energies.unshift({ label: TC.GLOBAL_ALL, value: "all" });

        // Filter the available sources found in the context's datasets
        let sources = _.uniq(data.datasets.map(d => d.src))
            .map(src => ({ label: DS.DATASETS.src.find(s => s.value === src)?.label || src, value: src }) as T.Option);
        // Add an option to select all sources
        sources.unshift({ label: TC.GLOBAL_ALL, value: "all" });

        return { paths, energies, sources };
    }, [data.datasets]);

    const datasets_options = React.useMemo(() => {
        let datasets = data.datasets || [];
        let selected = current.config.datasets || [];
        // Filter them by the selected path, is selected path is not "all" or undefined, and if datasets was not previously selected
        if (current.config.path !== "all" || !current.config.path) datasets = datasets.filter(d => d.path_parent === current.config.path || selected.includes(d._id));
        // Filter them by the selected energy, is selected energy is not "all" or undefined, and if datasets was not previously selected
        if (current.config.energy !== "all" || !current.config.energy) datasets = datasets.filter(d => d.type === current.config.energy || selected.includes(d._id));
        // Filter them by the selected source, is selected source is not "all" or undefined, and if datasets was not previously selected
        if (current.config.source !== "all" || !current.config.source) datasets = datasets.filter(d => d.src === current.config.source || selected.includes(d._id));
        return datasets.map(d => ({ ...d, label: `${d.name} (${d.parent})`, value: d._id }));
    }, [current.config.datasets, current.config.energy, current.config.path, current.config.source, data.datasets]);

    const tool_list = React.useMemo(() => {
        // Filter the time group options
        let time_group_no_display = current.tab.disabled_groups || [];
        if (!isAdmin) time_group_no_display.push("minuted");
        let time_group_options = DS.TIME_GROUPS.filter(o => !time_group_no_display.includes(o.value));

        return {
            export: <C.Button className="w-100" icon="file-csv" onClick={events.export} text={TC.EXPORT} />,
            paths: <C.Form.Select
                no_clear_btn
                noBottomMargin
                label={TC.NRJ_DASH_PATHS}
                value={current.config.path}
                options={datasets_filters.paths}
                onChange={path => events.set_config({ path })}
            />,
            energies: <C.Form.Select
                no_clear_btn
                noBottomMargin
                label={TC.NRJ_DASH_ENERGIES}
                value={current.config.energy}
                options={datasets_filters.energies}
                onChange={energy => events.set_config({ energy })}
            />,
            sources: <C.Form.Select
                no_clear_btn
                noBottomMargin
                label={TC.NRJ_DASH_SOURCES}
                value={current.config.source}
                options={datasets_filters.sources}
                onChange={source => events.set_config({ source })}
            />,
            datasets: <C.Form.Select
                multiple
                noBottomMargin
                options={datasets_options}
                label={TC.NRJ_DASH_DATASETS}
                value={current.config.datasets}
                tooltip={TC.NRJ_DASH_DATASETS_TIP}
                onChange={datasets => events.set_config({ datasets })}
                typeahead={{
                    height: "md",
                    keepOpenOnSelect: true,
                    select_all_on_enter: true,
                    renderItem: (opt: typeof datasets_options[number]) => <div children={<><i className={`fa fa-${DS.ICONS[opt.src]} me-2`} />{opt.label}</>} />,
                }}
            />,
            refresh: <C.Button
                variant="info"
                className="w-100"
                onClick={events.refresh}
                text={TC.GLOBAL_CONFIRM_PARAMS}
                icon={{ icon: "refresh", spin: loading.value }}
            />,
            time_groups: <C.Form.Select
                no_clear_btn
                noBottomMargin
                label={TC.GLOBAL_TIME_GROUP}
                options={time_group_options}
                value={current.config.time_group}
                onChange={v => events.set_config({ time_group: v })}
            />,
            costs: <C.Flex alignItems="center" justifyContent="between">
                <C.Form.NumField
                    suffix="€/m³"
                    noBottomMargin
                    customClass="me-1"
                    label={TC.GP_PRICE_WATER}
                    value={current.config.costs?.water}
                    onChange={v => events.set_cost("water", v)}
                />
                <C.Form.NumField
                    suffix="€/kWh"
                    noBottomMargin
                    customClass="mx-1"
                    label={TC.GP_PRICE_ELEC}
                    value={current.config.costs?.elec}
                    onChange={v => events.set_cost("elec", v)}
                />
                <C.Form.NumField
                    suffix="€/m³"
                    noBottomMargin
                    customClass="ms-1"
                    label={TC.GP_PRICE_GAS}
                    value={current.config.costs?.gas}
                    onChange={v => events.set_cost("gas", v)}
                />
            </C.Flex>,
            time: <C.TimeSelector
                button_class="w-100"
                min_relative_range="12 HOUR"
                interval={current.config.interval}
                to={current.config.to?.toISOString?.()}
                from={current.config.from?.toISOString?.()}
                only_years={current.tab.only_years}
                onChangeInterval={interval => events.set_config({ interval, to: undefined, from: undefined })}
                onChangeDatePicker={dates => events.set_config({ to: new Date(dates.to), from: new Date(dates.from), interval: undefined })}
            />,
        }
    }, [events, current.config, current.tab.only_years, current.tab.disabled_groups, datasets_filters, isAdmin, datasets_options, loading.value]);

    const toolbox = React.useMemo(() => {
        let expected_tools = TABS.find(t => t.code === current_tab)?.tools || [];
        let tools = [] as (Record<"node", React.ReactNode> & Record<"size", number>)[];
        let has_export_button = expected_tools.includes("export");

        // The time selector
        tools.push({ node: tool_list.time, size: has_export_button ? 4 : 6 });
        // Export Data Button
        if (has_export_button) tools.push({ node: tool_list.export, size: 2 });
        // The refresh button
        tools.push({ node: tool_list.refresh, size: 5 });
        // The favorite dropdown
        tools.push({ node: dropdown, size: 1 });
        // Time Group Selector
        if (expected_tools.includes("time_group")) tools.push({ node: tool_list.time_groups, size: 3 });
        // The costs configuration
        if (expected_tools.includes("cost")) tools.push({ node: tool_list.costs, size: 9 });
        // Datasets Selector
        if (expected_tools.includes("datasets")) tools.push(
            { node: tool_list.paths, size: 3 },
            { node: tool_list.energies, size: 3 },
            { node: tool_list.sources, size: 3 },
            { node: tool_list.datasets, size: 12 },
        );

        return <BS.Row className="g-1" children={tools.map((t, i) => <BS.Col key={i} xs={t.size} className="mb-2" children={t.node} />)} />;
    }, [current_tab, tool_list, dropdown]);

    const table_data = React.useMemo(() => {
        let data = [];
        let dataMatch = (html[current_sub_menu || current_tab] || "").match(/%%%.*%%%/g);
        if (dataMatch !== null) try {
            let str = TB.replaceStringPart(dataMatch[0], "%%%", "").trim();
            data = JSON.parse(str);
        } catch (error) { return };
        return data;
    }, [html, current_tab, current_sub_menu]);

    return <div>
        <C.Flex alignItems="stretch">
            {TABS.map((t, i) => <C.Button
                size="sm"
                key={t.code}
                text={t.label}
                active={t.code === current_tab}
                onClick={() => change.tab(t.code)}
                className={`m${i === 0 ? "e" : i === TABS.length - 1 ? "s" : "x"}-1 w-100`}
            />)}
        </C.Flex>

        <div className="border border-1 bg-light rounded p-3 my-2">
            <C.Title level={5} text={TC.NRJ_DASH_TOOLS} />
            {toolbox}
        </div>

        {Array.isArray(current.tab.sub_menu) && <C.Flex className="mb-2" alignItems="stretch" justifyContent="start">
            {current.tab.sub_menu?.map((sub, i) => <C.Button
                key={sub.template}
                text={sub.label}
                variant="secondary"
                active={sub.template === current_sub_menu}
                onClick={() => change.sub_menu(current_tab, sub.template)}
                className={`m${i === 0 ? "e" : i === TABS.length - 1 ? "s" : "x"}-1`}
            />)}
        </C.Flex>}

        <C.Spinner loading={loading.value}>
            <C.HtmlText no_full_height html={html[current_sub_menu || current_tab] || "<div></div>"} />
            {current.tab.enforce_datasets && current.config.datasets.length === 0 && <C.ErrorBanner type="info" message={TC.NRJ_DASH_NO_DATASETS} />}
            {current.tab.show_data_table && table_data.length > 0 && <div style={{ height: "85vh" }} children={<G.EntityDataTable isFullHeight data={table_data} />} />}
        </C.Spinner>
    </div>;
};

export default Energy;