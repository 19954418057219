import { COVID } from "./Data";

export const MIN_NOTE = 'min';
export const MAX_NOTE = "max";
export const UNASSIGNED = "NA";
export const ANOMALIES = "ano";

export const MIN_SURFACE_PER_PERS = COVID.M_SQUARE_PER_PERS;

export const IDA_1 = "IDA1";
export const IDA_2 = "IDA2";
export const IDA_3 = "IDA3";
export const IDA_4 = "IDA4";
export const NO_IDA = "IDA";

export const ALL_IDA = [IDA_1, IDA_2, IDA_3, IDA_4];

export const AIR_QUALITY_STYLE = (airQuality) => {
    switch (airQuality) {
        case IDA_1: return '#008000';
        case IDA_2: return '#00FF00';
        case IDA_3: return '#FFA500';
        case IDA_4: return '#FF0000';
        default: return '#000000';
    }
}

export const GET_AIR_QUALITY_NORM = (debitTotal, persCalc) => {
    if (debitTotal === 0 && persCalc === 0) return {};
    let idaValue = (debitTotal / persCalc).toFixed(2);
    return { ida: GET_AIR_QUALITY_ON_VALUE(idaValue), value: idaValue };
}

export const GET_AIR_QUALITY_ON_VALUE = (value) => {
    if (value > 54) return IDA_1
    if (value > 36) return IDA_2
    if (value > 22) return IDA_3
    return IDA_4
}

export const GET_NOTE = (note, quotation) => {
    switch (note) {
        case MIN_NOTE: return '0/' + quotation;
        case MAX_NOTE: return quotation + '/' + quotation;
        case UNASSIGNED: return 'Unassigned';
        case ANOMALIES: return quotation + '/' + quotation;
        default: return "0/" + quotation;
    }
}