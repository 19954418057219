import React from "react";
import * as F from "../../Form";
import * as M from "../../Modal";
import * as H from "../../../hooks";
import * as C from "../../../Common";
import * as BS from "react-bootstrap";
import * as S from "../../../services";
import { T, TC } from "../../../Constants";
import DataSources from "./DataSources";
import Repartition from "./Repartition";

type Tabs = T.Option<{}, "mission" | "datasource" | "repartition">;
type MissionResumeEpra = ReturnType<T.API.Utils.Missions.MissionResumeEpra>;

const TABS: Tabs[] = [
    { label: TC.CERT_HISTORY_MISSION, value: "mission" },
    { label: TC.EPRA_MISSION_PANEL_DATASOURCE, value: "datasource" },
    { label: TC.EPRA_MISSION_PANEL_REPARTITION, value: "repartition" },
];

const Main: React.FC = () => {
    const [, mission_id] = H.useRoots();
    const updated_mission = H.useBoolean(false);
    const mission_ref = React.useRef<F.Missions.MissionFormRef>(null);
    const { updateCrumbs, resetCrumbs } = H.useCrumbs(TC.MISSION_TYPES_EPRA);
    const [current_tab, set_current_tab] = React.useState<Tabs["value"]>("mission");
    const [loaded_tabs, set_loaded_tabs] = React.useState<Tabs["value"][]>(["mission"]);
    const [mission_state, set_mission_state, status] = H.useAsyncState<MissionResumeEpra>({ asset_id: "", mission_id: "", read_only: true, epra_data: null });

    //#region Tabs & Crumbs
    React.useEffect(() => {
        let tab = TABS.find(t => t.value === current_tab);
        if (tab) updateCrumbs({ label: tab.label }, 1);
        return () => { resetCrumbs() };
    }, [updateCrumbs, resetCrumbs, current_tab]);

    const change_tab = React.useCallback((tab: typeof current_tab) => {
        const tab_setter = (keep_mission_loaded = true) => {
            // Update the current tab
            set_current_tab(tab);
            // Update the list of loaded tab
            set_loaded_tabs(p => {
                let new_loaded = p.includes(tab) ? p : p.concat(tab);
                if (!keep_mission_loaded) return new_loaded.filter(t => t !== "mission");
                else return new_loaded;
            });
        }

        // Ask to confirm the changes made in the mission
        if (current_tab === "mission" && updated_mission.value) M.askConfirm({ title: TC.MISSION_WIZARD_AUTO_SAVE_MISSION, text: TC.MISSION_WIZARD_AUTO_SAVE_MISSION_TEXT }).then(confirmed => {
            if (confirmed) {
                let result = mission_ref.current?.save?.();

                if (result && result !== "errors") result.then(success => {
                    if (success) {
                        tab_setter();
                        updated_mission.setFalse();
                    }
                });
            }
            else tab_setter(false);
        });
        else tab_setter();
    }, [current_tab, updated_mission]);

    const on_save_form_mission = React.useCallback<F.Missions.MissionFormProps["onSave"]>(mission => {
        if (mission !== "deleted" && mission !== "error") {
            updated_mission.setFalse();
            set_mission_state(p => ({ ...p, has_crva: mission.tasks.some(t => t.type === "crva") }));
        }
    }, [updated_mission, set_mission_state]);

    // eslint-disable-next-line react-hooks/exhaustive-deps -- Dependency is not necessary, and I don't want the warning to show up
    React.useEffect(() => updated_mission.setFalse(), [mission_id]);
    //#endregion

    //#region Load & Save Data
    React.useEffect(() => {
        let isSubscribed = true;
        S.missionResumeEpra()
            .then(({ data }) => isSubscribed && set_mission_state(data, "done"))
            .catch(() => isSubscribed && set_mission_state({ asset_id: "", mission_id: "", read_only: true, epra_data: null }, "error"));
        return () => {
            isSubscribed = false;
            set_current_tab("mission");
            set_loaded_tabs(["mission"]);
            set_mission_state({ asset_id: "", mission_id: "", read_only: true, epra_data: null }, "load");
        }
    }, [set_mission_state]);

    const save_epra_data = React.useCallback<React.Dispatch<React.SetStateAction<ReturnType<T.API.Utils.Missions.MissionResumeEpra>["epra_data"]>>>(data => {
        let old_data = mission_state.epra_data;
        let new_data: ReturnType<T.API.Utils.Missions.MissionResumeEpra>["epra_data"] = null;
        // Calculate the new state
        if (typeof data === "function") new_data = data(mission_state.epra_data);
        else new_data = data;
        // Update the state directly, to avoid waiting for the database
        set_mission_state(p => ({ ...p, epra_data: new_data }));
        // Save the new state in the database
        S.saveMissionEpra(new_data)
            .catch(error => {
                // Notify the user of the error
                M.Alerts.updateError(error);
                // Reset the state in case of an error when saving
                set_mission_state(p => ({ ...p, epra_data: old_data }));
            });
    }, [set_mission_state, mission_state.epra_data]);
    //#endregion

    return <C.Flex direction="column" className="flex-grow-1">
        <C.Spinner status={status}>
            {/* Tabs */}
            <BS.Row className="g-1">
                {TABS.map(tab => <BS.Col key={tab.value}>
                    <C.Button
                        size="sm"
                        text={tab.label}
                        className="w-100"
                        onClick={() => change_tab(tab.value)}
                        variant={current_tab === tab.value ? "primary" : "link"}
                    />
                </BS.Col>)}
            </BS.Row>

            {/* Wizards */}
            {loaded_tabs.includes("mission") && <div className="flex-grow-1 my-3" hidden={current_tab !== "mission"}>
                <F.Missions.MissionForm
                    no_delete
                    ref={mission_ref}
                    onSave={on_save_form_mission}
                    asset={mission_state.asset_id}
                    onChange={updated_mission.setTrue}
                    mission_id={mission_state.mission_id}
                />
            </div>}

            <div className="flex-grow-1 my-3" hidden={current_tab !== "datasource"}>
                <DataSources
                    update={save_epra_data}
                    asset_id={mission_state.asset_id}
                    epra_data={mission_state.epra_data}
                    active={current_tab === "datasource"}
                />
            </div>

            <div className="flex-grow-1 my-3" hidden={current_tab !== "repartition"}>
                <Repartition epra_data={mission_state.epra_data} update={save_epra_data} />
            </div>

        </C.Spinner>
    </C.Flex>
}

export default Main;