import { AllowArray } from "../../Misc";
import { ContextParams } from "../../General";
import { RegAction, RegDoc, SiteData } from "../../Types";
import { BuildingData, DataSet, ParcelData } from "../../Data";

/** Gets a filtered list of targets */
export type GetTargetsFiltered = (params: Record<"ref" | "key" | "roots", string>) => string;
/** Temporary API Alban need to sort through OPERAT xcel file */
export type GetSiteRawData = (id: string) => any[];
/** Gets the sum of every building's surface found in the db */
export type GetSurfaceSum = () => number;
/** Updates a building's latitude & longitude */
export type UpdateBuildGeoPoints = (points: Record<"lat" | "lon" | "id", string | number>) => void;
/** Gets the list of sites an account has access to, based on it's API key */
export type SitesList = (key: string) => ReturnType<GetSiteAndBuilds>["sites"];
/** Gets the list of sites an account has access to, based on it's API key */
export type BuildingsList = (key: string) => ReturnType<GetSiteAndBuilds>["buildings"];
/** Retrieve a list of existing files and documents uploaded for a context and/or an action */
export type UploadedRegDocuments = (params: Partial<Record<"root" | "action", string>>) => Buffer;
/** Retrieve data about a reg, based on an id */
export type GetRegActions = (root: AllowArray<string>) => AllowArray<ReturnType<ContextRegActions>[number]>;

/** Get a list of useful data to identify equipments from a reg file */
export type GetEquipmentsIdentifiers = (root: AllowArray<string>) => {
    /** The list of stored equipment categories (only those that can be an equipment in itself) */
    categories: Record<"_id" | "name" | "omniclass", string>[];
    /** The list of existing equipments found under the provided root */
    equipments: {
        /** The _id of the equipment */
        _id: string;
        /** The name of the equipment */
        name: string;
        /** The name of the floor where the equipment is */
        floor: string;
        /** The name of the building where the equipment is */
        building: string;
        /** The name of the local where the equipment is */
        local: string;
        /** The name of the category of the equipment */
        category: string;
        /** The name of the brand of the equipment */
        brand: string;
        /** The name of the model of the equipment */
        model: string;
        /** The quantity of equipments grouped together into one */
        quantity: number;
    }[];
    /** The list of available locations found under the provided root */
    locations: {
        /** The _id of the location */
        _id: string;
        /** The name of the location */
        name: string;
        /** The name of the floor where the location is */
        floor: string;
        /** The name of the building where the location is */
        building: string;
        /** The name of the local where the location is */
        local: string;
        /** The type of location */
        type: string;
    }[];
    /** The list of stored brands and their models */
    brands: {
        /** The _id of the brand */
        _id: string;
        /** The name of the brand */
        brand: string;
        /** The list of models associated to the brand */
        models: Record<"_id" | "name", string>[];
    }[];
};

/** Get a formatted list of dataset */
export type GetPC = (params: {
    /** Where to search for datasets, could be dataset's id, building ref or an elem's id(s) */
    ref: string;
    /** Send only the head datasets */
    head?: string | boolean;
}) => {
    /** Latitude of the closest building found */
    lat: number;
    /** Longitude of the closest building found */
    lon: number;
    /** Id of the dataset */
    _id: string;
    /** Name of the dataset */
    name: string;
    /** Tag associated to the dataset */
    tag?: number;
    /** Is the dataset normalize */
    normalized?: DataSet["normalized"];
    /** The aggregation method */
    aggregate: DataSet["aggregate"];
    /** Is is cumulated or not */
    dataType: "CONSUMPTION" | "INDEX";
    /** For prediction dataset, the _id of the prediction model */
    pred_id?: string;
    /** For prediction dataset, the type of prediction to query */
    pred_type?: DataSet["pred_type"];
    /** Station associated to the dataset */
    station_id?: number;
    /** Is it global */
    global: boolean;
    /** The unit of the data */
    unit?: DataSet["unit"];
    /** The type of energy the dataset represent */
    typeEnergie?: DataSet["type"] | "PROD";
    /** The src of the dataset */
    readingType?: "CALCULATED" | "AUTOMATIC_READING" | "MANUAL" | "PREDICTION";
    /** Is the dataset considered 'head' */
    isHead: boolean;
    /** A list of formulas */
    formula?: DataSet["formulas"];
    /** The site's weather station */
    weather_station?: string | number;
    /** List of values needed to evaluate formulas */
    values?: {
        /** The value of the prop called in the formula */
        value?: any;
        /** The item call in the formula */
        item: string;
        /** The prop called in the formula */
        prop?: string;
        /** The dataset called in the formula */
        dataset?: string;
        /** The data of the dataset */
        data?: ReturnType<GetPC>[number];
    }[];
    /** The history of a prop, if necessary, as a dictionary [_id: [prop: value historic]] */
    value_history: Record<string, Record<string, {
        /** The date at which the value starts being used */
        from?: string;
        /** The date at which the value ends being used */
        to?: string;
        /** The value */
        value: any;
    }[]>>;
}[];

/** Gets Sites, Parcels and buildings for a given context */
export type GetParcelsSiteBuildings = (context: ContextParams) => {
    /** The data of the site */
    data: SiteData;
    /** The _id of the site */
    _id: string;
    /** A list of buildings found under the site */
    buildings: {
        /** The data of the building */
        data: BuildingData;
        /** The _id of the building */
        _id: string;
    }[];
    /** A list of parcels found under the site */
    parcels: {
        /** The data of the parcel */
        data: ParcelData;
        /** The _id of the parcel */
        _id: string;
        /** Buildings found under the parcel */
        buildings: ReturnType<GetParcelsSiteBuildings>[number]["buildings"];
    }[];
}[];

/** Get a list of the accessible datasets based on a provided root */
export type GetDatasets = (params: Record<"key" | "root", string>) => {
    /** _id of the dataset */
    _id: string;
    /** The name of the dataset */
    name: string;
    /** The id of the origin element of the dataset */
    origin: string;
    /** Is the dataset considered a 'Head dataset' */
    is_head: boolean;
    /** The name of the dataset origin element */
    origin_name: string;
    /** What type of energy does the dataset represent */
    energy: DataSet["type"];
    /** Where does the dataset find it's data */
    source: "AUTOMATIC_READING" | "CALCULATED" | "MANUAL" | "PREDICTION";
    /** The list of sites found above the dataset */
    sites: Record<"_id" | "name", string>[];
    /** The list of buildings found above the dataset */
    buildings: Record<"_id" | "name", string>[];
}[];

/** Get the data for a list of datasets */
export type GetData = (params: {
    /** The end time limit */
    to: string;
    /** The start time limit */
    from: string;
    /** The ids of the datasets to download */
    datasets: AllowArray<string>;
    /** Consumption or index data */
    type?: "consumption" | "index";
    /** How to group the data */
    group?: "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year" | "minuted";
}) => (Record<"datapoints" | "hourlydatapoints" | "index", [number, number][]> & Record<"shortname" | "_id" | "unit" | "target", string>)[];;

/** Get a list of sites and buildings */
export type GetSiteAndBuilds = (params: Record<"key", string>) => {
    /** A list of sites */
    sites: Record<"_id" | "name" | "country" | "region", string>[];
    /** A list of buildings */
    buildings: Record<"_id" | "name" | "address" | "site_name" | "site_id" | "country" | "region", string>[];
};

/** Get more data about buildings */
export type GetBuildings = (roots: AllowArray<string>) => AllowArray<{
    /** Is the element a site or not ? */
    is_site: boolean;
    /** The _id of the site / building */
    _id: string;
    /** The name of the site /building */
    name: string;
    /** The full concatenated address of the building */
    address?: string;
    /** The name of the site the building is on */
    site_name?: string;
    /** The _id of the site the building is on */
    site_id?: string;
    /** The country the building is in */
    country: string;
    /** The region the building is in */
    region: string;
    /** The zip code of the building (optional) */
    zip_code?: string;
    /** The town of the building (optional) */
    town?: string;
    /** The street name of the building (optional) */
    street_name?: string;
    /** The street number of the building (optional) */
    street_number?: string;
    /** The list of buildings found under the current site */
    buildings?: string[];
}>;

/** Retrieve the regs that applies to a context */
export type ContextRegActions = (root: string) => (Omit<RegAction, "regions" | "gammes" | "resources" | "frequency" | "type" | "order"> & {
    /** The _id of the reg action */
    _id: string;
    /** The regions the reg action applies to */
    regions: Record<"_id" | "name", string>[];
})[];

/** Check if there were files and or documents created for a pair root / action */
export type WasRegDocumentUploaded = (params: Record<"root" | "action", string>) => {
    /** Are there documents for this reg action in the context provided ? */
    exists: boolean;
    /** How many documents are present */
    count: number;
    /** All the files linked to that action */
    files: Record<"name" | "url", string>[];
}

/** Get a list of elements that can be attributed to a pair document / context, also existing elements if editing a Reg Doc */
export type GetRegElements = (params: Record<"root" | "action", string> & Partial<Record<"doc", string>>) => {
    /** The list of existing elements associated to a document */
    existing: RegDoc["currentElement"];
    /** The list of elements available to be associated to a document */
    available: Record<"label" | "id", string>[];
    /** The list of available status */
    status: (Record<"value", RegDoc["currentElement"][number]["status"]> & Record<"label" | "description", string>)[];
};

/** Get a list of fields needed for an auto reading of a reg doc */
export type GetRegDocFields = (action: string) => {
    /** The id of the action */
    action_id: string;
    /** The list of field to be completed */
    fields: {
        /** The name of the property */
        prop: string;
        /** The label of the property */
        label: string;
        /** The type of value possible */
        type: "string" | "number" | "date" | "boolean" | "select";
        /** For type='select', the possible values expected */
        select_options?: string[];
    }[];
};