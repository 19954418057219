import React from "react";
import * as M from "../../Modal";
import * as H from "../../../hooks";
import * as C from "../../../Common";
import * as S from "../../../services";
import Form, { FormProps } from "../form";
import ActionRemarquesPanel from "./ActionRemarque";
import { Accordion, ButtonGroup } from "react-bootstrap";
import { FP, RIGHTS, T, TB, TC } from "../../../Constants";
import { renderEquipIndicatorModal } from "../../../PurposeModal";

//#region Types
export type RemarquesPanelProps = {
    /** The id of the submission */
    _id: string;
    /** Do not allow any edits */
    read_only?: boolean;
    /** Callback after an edit or a creation of a remarques */
    onChange?: (
        type: "edit" | "add" | "delete",
        remarque: T.Submission<T.RemarqueDefault>
    ) => void;
}

export type RemarquesPanelRef = {
    /** Callback to update the element's criticity */
    set_criticity: (criticity?: string) => void;
}

type Data = ReturnType<T.API.EDL.GetElementRemarques>;
//#endregion

const TEXT_CODES = [
    TC.REM_DEF_STATUS_OPEN, TC.REM_DEF_STATUS_CLOSE, TC.REM_DEF_STATUS_MEMO,
    TC.NO_ITEM, TC.REM_FORM_PANEL_DROP_TITLE, TC.REM_FORM_PANEL_DROP_REM, TC.REM_FORM_PANEL_DROP_ACTION,
];

const RemarquesPanel = React.forwardRef<RemarquesPanelRef, RemarquesPanelProps>(({ onChange, ...props }, ref) => {
    const rights = H.useRights();
    const [{ userId }] = H.useAuth();
    const lg = H.useLanguage(TEXT_CODES);
    const [onEdit, setOnEdit] = React.useState("");
    const [actions, setActions] = React.useState<Record<"show" | "loaded", string[]>>({ show: [], loaded: [] });
    const [data, setData, status] = H.useAsyncState<Data>({ remarques: [], actions: {}, criticity_colors: {} });

    React.useImperativeHandle(ref, () => ({
        set_criticity: crit => setData(p => ({ ...p, criticity: crit })),
    }), [setData]);

    //#region Language
    const status_lang = React.useMemo<Record<T.RemarqueDefault["status"], React.ReactNode>>(() => ({
        open: lg.getStaticElem(TC.REM_DEF_STATUS_OPEN),
        memo: lg.getStaticElem(TC.REM_DEF_STATUS_MEMO),
        closed: lg.getStaticElem(TC.REM_DEF_STATUS_CLOSE),
    }), [lg]);
    //#endregion

    //#region Load data
    React.useEffect(() => {
        let isSubscribed = true;
        S.getElementRemarques(props._id)
            .then(({ data }) => isSubscribed && setData(data, "done"))
            .catch(() => setData({ remarques: [], actions: {}, criticity_colors: {} }, "error"));
        return () => { isSubscribed = false };
    }, [props._id, setData]);
    //#endregion

    //#region Edit
    const setRemarques = React.useCallback<React.Dispatch<React.SetStateAction<typeof data.remarques>>>(setter => {
        setData(p => {
            if (typeof setter === "function") return { ...p, remarques: setter(p.remarques) };
            return { ...p, setter };
        });
    }, [setData]);

    const events = React.useMemo(() => ({
        toEdit: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id?: string) => {
            e.preventDefault();
            setOnEdit(id || "");
        },
        onEdit: (submission => {
            if (submission) {
                setOnEdit("");
                onChange?.("edit", submission);
                setRemarques(p => p.map(r => r._id === submission._id ? submission : r));
            }
        }) as FormProps<T.RemarqueDefault>["onSave"],
        onDelete: (submission: Data["remarques"][number]) => {
            M.askConfirm().then(confirmed => {
                if (confirmed) S.removeRemarques(submission._id)
                    .then(() => {
                        onChange?.("delete", submission);
                        setRemarques(p => p.filter(r => r._id !== submission._id));
                    })
                    .catch(M.Alerts.deleteError);
            });
        },
        onAdd: () => {
            const criticityPromise = new Promise<string>((resolve, reject) => {
                if (TB.mongoIdValidator(data.criticity)) resolve(data.criticity);
                else renderEquipIndicatorModal({ type: "criticityEquipment", toUpdate: props._id, title: TC.REM_EQUIP_CRITICITY })
                    .then(indicator => resolve(indicator));
            });

            criticityPromise.then(indicator => {
                if (indicator) M.renderFormModal<T.RemarqueDefault>({
                    path: FP.REMARQUES_DEFAULTS,
                    forcedSubmission: [
                        { prop: "element", value: props._id },
                        { prop: "elementCriticity", value: indicator },
                    ],
                }).then(rem => {
                    if (rem) onChange?.("add", rem);

                    setData(p => {
                        let remarques = rem ? p.remarques.concat(rem) : p.remarques;
                        let actions = rem ? { ...p.actions, [rem._id]: 0 } : p.actions;
                        let criticity = indicator && p.criticity !== indicator ? indicator : p.criticity;
                        return { criticity, remarques, actions, criticity_colors: p.criticity_colors };
                    });
                });
            });
        },
        onAddAction: (remarque: string) => setData(p => ({
            ...p,
            actions: {
                ...p.actions,
                [remarque]: (p.actions[remarque] || 0) + 1,
            },
        })),
        onRemoveAction: (remarque: string) => setData(p => ({
            ...p,
            actions: {
                ...p.actions,
                [remarque]: (p.actions[remarque] || 1) - 1,
            }
        })),
    }), [onChange, setData, setRemarques, data.criticity, props._id]);

    const getRemButton = React.useCallback((rem: Data["remarques"][number]) => {
        let isOwn = rem.owner === userId;
        let canEdit = isOwn
            ? rights.isRightAllowed(RIGHTS.MISC.WRITE_OWN_REMARQUES)
            : rights.isRightAllowed(RIGHTS.MISC.WRITE_OTHER_REMARQUES);

        if (!canEdit || props.read_only) return null;

        return <ButtonGroup>
            {onEdit === rem._id
                ? <C.Button variant="secondary" icon="times" onClick={events.toEdit} text={TC.GLOBAL_CANCEL} />
                : <C.Button onClick={e => events.toEdit(e, rem._id)} icon="pencil-alt" text={TC.GLOBAL_EDIT} />}

            <C.Button variant="danger" icon="trash" onClick={() => events.onDelete(rem)} />
        </ButtonGroup>;
    }, [rights, userId, onEdit, events, props.read_only]);
    //#endregion

    //#region View
    const actionEvent = React.useMemo(() => ({
        show: (id: string) => setActions(p => ({
            show: p.show.includes(id) ? p.show : p.show.concat(id),
            loaded: p.loaded.includes(id) ? p.loaded : p.loaded.concat(id),
        })),
        hide: (id: string) => setActions(p => ({
            loaded: p.loaded,
            show: p.show.filter(r => r !== id),
        })),
    }), []);
    //#endregion

    return <C.Spinner status={status}>
        {data.remarques.length === 0
            ? <C.CenterMessage>
                <div className="p-2 text-center">
                    <div className="mb-2">{lg.getStaticElem(TC.NO_ITEM)}</div>
                    <C.Button
                        icon="plus"
                        variant="link"
                        text={TC.NEW_REM_DEF}
                        onClick={events.onAdd}
                        disabled={props.read_only || !rights.isRightAllowed(RIGHTS.MISC.WRITE_OWN_REMARQUES)}
                    />
                </div>
            </C.CenterMessage>
            : <>
                <C.Flex justifyContent="end" className="mb-2">
                    <C.Button
                        icon="plus"
                        text={TC.NEW_REM_DEF}
                        onClick={events.onAdd}
                        disabled={props.read_only || !rights.isRightAllowed(RIGHTS.MISC.WRITE_OWN_REMARQUES)}
                    />
                </C.Flex>
                <Accordion className='mt-3'>
                    {data.remarques.map(r => <Accordion.Item key={r._id} eventKey={r._id} style={{ opacity: r.data.status === "closed" ? 0.65 : 1 }}>
                        <Accordion.Header>
                            <C.Flex className="w-100" alignItems="center" justifyContent="between">
                                <div>{r.data.description}</div>
                                <C.Flex className="me-2" alignItems="center" justifyContent="end">
                                    <div className="me-2">{status_lang[r.data.status]}</div>
                                    <C.ColorBox
                                        shape='circle'
                                        color={data.criticity_colors[r.data.criticity] || "#000000"}
                                    />
                                </C.Flex>
                            </C.Flex>
                        </Accordion.Header>

                        <Accordion.Body>
                            <C.Flex className='w-100 mb-3' alignItems='center' justifyContent='between'>

                                <ButtonGroup size="sm">
                                    <C.Button variant="info" onClick={() => actionEvent.hide(r._id)} text={TC.REM_FORM_PANEL_DROP_REM} />
                                    <C.Button onClick={() => actionEvent.show(r._id)}>
                                        <>
                                            <span className="me-2">{lg.getStaticElem(TC.REM_FORM_PANEL_DROP_ACTION)}</span>
                                            ({data.actions[r._id] || 0})
                                        </>
                                    </C.Button>
                                </ButtonGroup>

                                {!actions.show.includes(r._id) && getRemButton(r)}
                            </C.Flex>

                            <div hidden={actions.show.includes(r._id)}>
                                <Form<T.RemarqueDefault>
                                    submissionId={r._id}
                                    onSave={events.onEdit}
                                    readOnly={r._id !== onEdit}
                                    path={FP.REMARQUES_DEFAULTS}
                                />
                            </div>

                            {actions.loaded.includes(r._id) && <div hidden={!actions.show.includes(r._id)} className="border rounded bg-light m-2 p-2">
                                <ActionRemarquesPanel
                                    remarque={r._id}
                                    elementId={props._id}
                                    read_only={props.read_only}
                                    description={r.data.description}
                                    context={{ roots: r.data.element }}
                                    onCreate={() => events.onAddAction(r._id)}
                                    onRemove={() => events.onRemoveAction(r._id)}
                                />
                            </div>}

                        </Accordion.Body>
                    </Accordion.Item>)}
                </Accordion>
            </>}
    </C.Spinner>;
});

export default RemarquesPanel;