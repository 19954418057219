import React from "react";
import * as I from "../Input";
import * as H from "../../../hooks";
import { Select } from "../Components";
import * as S from "../../../services";
import { T, TB } from "../../../Constants";

export type GammeSelectProps = I.InputProps;
type GammeOption = ReturnType<T.API.Utils.Gammes.GetGammesOptions>[number];
type Option = GammeOption & GammeSelectProps["options"][number];

const GammeSelect: React.FC<GammeSelectProps> = ({ onChange, ...props }) => {
    const lg = H.useLanguage();
    const [options, set_options, status] = H.useAsyncState<Option[]>([]);
    const isDisabled = React.useMemo(() => props.disabled || props.noOverride, [props.disabled, props.noOverride]);

    const gammesTree = React.useMemo(() => {
        let currentGamme = options.filter(o => o.value === props.value)[0];
        if (!currentGamme) return [];

        let tree: typeof options = [];
        const getParent = (option: Option) => {
            let parent = options.filter(o => o.value === option.parent)[0];
            if (parent) {
                tree.push(parent);
                getParent(parent);
            }
        }

        getParent(currentGamme);
        return tree.reverse().concat(currentGamme);
    }, [props.value, options]);

    const getOptions = React.useCallback((value: string) => {
        let currentValue = options.filter(o => o.value === value)[0];
        if (!currentValue) return options;

        const findDescendants = (ids: T.AllowArray<string>): typeof options => {
            ids = TB.arrayWrapper(ids);
            let descendants = options.filter(o => ids.includes(o.parent));

            if (descendants.length > 0) return descendants
                .concat(findDescendants(descendants.map(d => d.value)));
            return [];
        }

        return findDescendants(value);
    }, [options]);

    const updateValue = React.useCallback((id: string) => onChange?.(id), [onChange]);

    const currentHasChildren = React.useMemo(() => {
        let current = options.filter(o => o.value === props.value)[0];
        if (!current) return true;
        return options.some(o => o.parent === props.value);
    }, [props.value, options]);

    React.useEffect(() => {
        let isSubscribed = true;
        S.getGammesOptions(lg.prop)
            .then(({ data }) => isSubscribed && set_options(data, "done"))
            .catch(() => isSubscribed && set_options([], "error"));
        return () => {
            isSubscribed = false;
            set_options([], "load");
        }
    }, [set_options, lg.prop]);

    return <I.ComponentWrapper {...props} disabled={isDisabled}>
        {gammesTree.map(g => <Select
            key={g.value}
            positionFixed
            value={g.value}
            disabled={isDisabled}
            options={getOptions(g.parent)}
            onChange={id => updateValue(id || g.parent)}
            typeahead={{ extra_search_props: "omniclass" }}
        />)}

        {currentHasChildren && <Select
            positionFixed
            key={props.value}
            disabled={isDisabled}
            loading={status === "load"}
            onChange={updateValue}
            options={getOptions(props.value)}
            typeahead={{ extra_search_props: "omniclass" }}
        />}

    </I.ComponentWrapper>
};

export default GammeSelect;