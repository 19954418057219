import _ from "lodash";
import React from "react";
import * as M from "../../Modal";
import * as H from "../../../hooks";
import * as C from "../../../Common";
import * as S from "../../../services";
import * as PM from "../../../PurposeModal";
import { CellsTypes as CT } from "../AgGridDefs";
import { Table, TableRef, TableProps } from "../Grid";
import { FP, RIGHTS, T, TABS, TB, TC } from "../../../Constants";

//#region Types & Constants
const EDIT_FIELDS = ["subject", "note", "tags_names"];

export type NotesTableProps = {
    /** The context to load the notes from */
    context?: T.ContextParams;
    /** The origin, to differentiate the saved configuration */
    origin?: string;
}

type Row = ReturnType<T.API.Utils.Tables.GetNotesRows>[number];
//#endregion

export const Notes: React.FC<NotesTableProps> = props => {
    const lg = H.useLanguage();
    const [forms] = H.useFormIds();
    const [{ userId }] = H.useAuth();
    const rights = H.useRights(props.context);
    const grid = React.useRef<TableRef<Row>>(null);
    const [context_info] = H.useContextInfos(props.context);
    const [notes, set_notes, status] = H.useAsyncState<Row[]>([]);

    //#region Load Rows
    React.useEffect(() => {
        let isSubscribed = true;
        S.getNotesRows(props.context)
            .then(({ data }) => isSubscribed && set_notes(data, "done"))
            .catch(() => isSubscribed && set_notes([], "error"));
        return () => {
            isSubscribed = false;
            set_notes([], "load");
        }
    }, [props.context, set_notes]);
    //#endregion

    //#region Formatter
    const translations = React.useMemo(() => ({
        category: (category: string, category_name?: string) => {
            // Category is an equip gamme id
            if (TB.mongoIdValidator(category)) return lg.getTextObj(category, "name", category_name);
            // Category is a form path
            else return lg.getStaticText(category);
        },
    }), [lg]);

    const rows = React.useMemo(() => notes.map(n => ({
        ...n,
        tr_category: translations.category(n.category, n.category_name),
    })), [notes, translations]);
    //#endregion

    //#region Context Menu & Changes
    const notes_updates = React.useMemo(() => ({
        /** Replace old rows with new ones */
        replace: (rows: Row[]) => set_notes(p => p.map(r => rows.filter(row => row._id === r._id)[0] || r)),
        /** Remove a row */
        remove: (id: string) => set_notes(p => p.filter(r => r._id !== id)),
        /** Add or updates rows */
        add: (rows: Row[]) => set_notes(p => {
            let existing_ids = p.map(r => r._id);
            let [to_update, to_add] = _.partition(rows, r => existing_ids.includes(r._id));
            return p.map(r => to_update.filter(row => row._id === r._id)[0] || r)
                .concat(to_add);
        })
    }), [set_notes]);

    const get_location = React.useCallback(() => new Promise<string>(resolve => {
        // Choose a site
        PM.renderSiteSelect({ context: props.context, isRequired: true }).then(root => {
            if (root) M.renderLightTree({ root, restrictOwnLinks: true, style: { size: "lg", title: TC.GLOBAL_PICK_PARENT } })
                .then(parent => resolve(parent));
            else resolve(null);
        });
    }), [props.context]);

    const events = React.useMemo(() => ({
        add: () => get_location().then(parent => {
            // A parent was picked
            if (parent) M.renderFormModal<T.NoteData>({ path: FP.NOTES_PATH, forcedSubmission: [{ value: parent, prop: "origin" }] }).then(note => {
                if (note) S.getNotesRowsByIds(note._id)
                    .then(({ data }) => notes_updates.add(data))
                    .catch(M.Alerts.loadError);
            });
        }),
        edit: (row: Row) => {
            M.renderPopUpFormModal({ submissionId: row._id, path: FP.NOTES_PATH }).then(edited => {
                if (edited) S.getNotesRowsByIds(edited._id)
                    .then(({ data }) => notes_updates.replace(data))
                    .catch(M.Alerts.loadError);
            });
        },
        remove: (row: Row) => M.askConfirm().then(confirmed => {
            if (confirmed) S.removeNote(row._id)
                .then(() => notes_updates.remove(row._id))
                .catch(M.Alerts.deleteError);
        }),
        zip: () => {
            grid.current.grid.api.selectAll();
            let rows = grid.current.grid.api.getSelectedNodes();
            grid.current.grid.api.deselectAll();
            let content: Parameters<typeof S.createZip>[0]["content"] = [];
            // To avoid re-grouping by element if user has already grouped by element
            let already_group_by_elem = false;
            // Is there any groups currently in the table
            let is_grouped = rows.some(r => r.group);
            // Treat the groups
            if (is_grouped) {
                const recursive = (sub_rows: typeof rows, parent: typeof content, level = 0) => {
                    let has_group = sub_rows.some(r => r.group && r.level === level);
                    if (has_group) {
                        sub_rows = sub_rows.filter(r => r.group && r.level === level);
                        for (let row of sub_rows) {
                            let sub_content = [] as typeof content;
                            if (row.field === "origin_name") already_group_by_elem = true;
                            /* for (let grouped_row of row.childrenAfterGroup)  */recursive(row.childrenAfterGroup, sub_content, level + 1);
                            if (sub_content.length > 0) parent.push({ type: "directory", label: row.key || "None", content: sub_content });
                        }
                    }
                    else for (let row of sub_rows) {
                        let note = row.data;
                        if (Array.isArray(note.files) && note.files.length > 0) {
                            // Table is not already grouped by element
                            if (!already_group_by_elem) parent.push({
                                type: "directory",
                                label: note.origin_name,
                                content: note.files.map(f => ({ type: "file", originalName: f.originalName, url: f.url })),
                            });
                            else note.files.forEach(f => parent.push({ type: "file", originalName: f.originalName, url: f.url }));
                        }
                    }
                }
                // rows.forEach(r => recursive(r, content));
                recursive(rows, content);
            }
            // Auto-group by element
            else for (let row of rows) {
                let note = row.data;
                if (Array.isArray(note.files) && note.files.length > 0) content.push({
                    type: "directory",
                    label: note.origin_name,
                    content: note.files.map(f => ({ type: "file", originalName: f.originalName, url: f.url })),
                });
            }
            // Create the zip archive
            let name = `${lg.getStaticText(TC.TAB_NOTES_TABLE)} - ${context_info.name}`;
            S.createZip({ content, name }).catch(M.Alerts.file_generation_fail);
        },
        edit_element: (row: Row) => {
            let path = "";
            if (TB.mongoIdValidator(row.category)) path = FP.EQUIPEMENT_FORM;
            else path = row.category;

            // Find the right to edit the element
            let write_right = null;
            if (path === FP.SITE_FORM) write_right = RIGHTS.TECH.EDIT_SITE;
            else if (path === FP.BUILDING_FORM) write_right = RIGHTS.TECH.EDIT_BUILDING;
            else if (path === FP.EQUIPEMENT_FORM) write_right = RIGHTS.TECH.EDIT_EQUIPMENT;
            else if (path === FP.EMPLACEMENT_FORM) write_right = RIGHTS.TECH.EDIT_EMPLACEMENT;
            else write_right = RIGHTS.TECH.WRITE_OTHERS;
            // Check if the user has the right to edit the element
            let readOnly = !rights.isRightAllowed(write_right);

            M.renderPopUpFormModal({ submissionId: row.origin, path, readOnly }).then(submission => {
                if (submission) S.getNotesRowsByIds(row._id)
                    .then(({ data }) => notes_updates.replace(data))
                    .catch(M.Alerts.loadError);
            });
        }
    }), [get_location, notes_updates, lg, rights, context_info.name]);

    const contextMenu = React.useCallback<TableProps<Row>["getContextMenuItems"]>(event => {
        let row = event.node?.data;
        let items = [] as ReturnType<TableProps<Row>["getContextMenuItems"]>;
        let is_own = userId === row?.owner;
        let can_edit = rights.isRightAllowed(is_own ? RIGHTS.MISC.WRITE_OWN_NOTES : RIGHTS.MISC.WRITE_OTHER_NOTES);

        // Create options
        if (rights.isRightAllowed(RIGHTS.MISC.WRITE_OWN_NOTES)) items.push({
            action: events.add,
            icon: "<i class='fa fa-plus'></i>",
            name: lg.getStaticText(TC.TABLE_NEW_NOTE),
        });
        // Edit
        if (row && can_edit) items.push({
            action: () => events.edit(row),
            name: lg.getStaticText(TC.GLOBAL_EDIT),
            icon: "<i class='fa fa-pencil-alt'></i>",
        });
        // Delete
        if (row && can_edit) items.push({
            action: () => events.remove(row),
            name: lg.getStaticText(TC.GLOBAL_DELETE),
            icon: "<i class='fa fa-times text-danger'></i>",
        });
        // Add a separator
        if (items.length > 0 && event.defaultItems?.length > 0) items.push("separator");
        // Add the default options
        if (event.defaultItems?.length > 0) items.push(...event.defaultItems);
        return items;
    }, [rights, lg, userId, events]);

    const onChange = React.useCallback<TableProps<Row>["onValueChange"]>(params => {
        let row = params.data,
            old_value = params.oldValue,
            is_own = row.owner === userId,
            field = params.colDef.field as keyof typeof rows[number],
            edit_right = is_own ? RIGHTS.MISC.WRITE_OWN_NOTES : RIGHTS.MISC.WRITE_OTHER_NOTES;

        // This field can't be edited
        if (!EDIT_FIELDS.includes(field)) M.Alerts.updateError();
        // The user doesn't have the right to edit this note
        else if (!rights.isRightAllowed(edit_right)) M.Alerts.haveNotRight();
        // All seems ok
        else {
            let prop: keyof T.NoteData;
            if (field === "tags_names") {
                prop = "tags";
                old_value = row.tags;
            }
            else prop = field as typeof prop;

            const updatePromise = new Promise<Row[]>((resolve, reject) => {
                let api_params = {
                    field: prop,
                    _id: row._id,
                    origin: row.origin,
                    old_value: old_value,
                    new_value: params.newValue,
                } as Parameters<typeof S.editNoteField>[0];

                S.editNoteField(api_params).then(({ data }) => {
                    if (data === "changed") M.askConfirm({ title: TC.UPDATE_FORCE_CHANGE, text: TC.UPDATE_VALUE_UNFRESH }).then(confirmed => {
                        if (confirmed) S.editNoteField({ ...api_params, force_update: true }).then(({ data }) => {
                            if (data === "changed") reject("Error");
                            else resolve(data);
                        }).catch(reject);
                    });
                    else resolve(data);
                }).catch(reject);
            });

            updatePromise
                .then(rows => notes_updates.add(rows))
                .catch(M.Alerts.updateError);
        }
    }, [notes_updates, rights, userId]);
    //#endregion

    //#region Columns
    const form_id = React.useMemo(() => forms[FP.NOTES_PATH], [forms]);

    const options = React.useMemo(() => ({
        tags: (row: Row) => new Promise<T.Option[]>((resolve, reject) => {
            S.getNoteTags({ context: { roots: row.origin }, type: "note" })
                .then(r => resolve(r.data))
                .catch(reject);
        }),
        create_tag: (text: string, row: Row) => new Promise<T.Option>((resolve, reject) => {
            let submission = { name: text, type: "note", users: [userId], sites: [] } as T.NoteTag;
            S.createSubmission({ submission, path: FP.NOTE_TAG_FORM }).then(({ data }) => {
                let new_tag = data.submissions[0] as T.Submission<T.NoteTag>;
                if (new_tag) {
                    let new_option = { label: new_tag.data.name, value: new_tag._id } as T.Option;
                    resolve(new_option);
                }
                else resolve(null);
            }).catch(reject);
        }),
    }), [userId]);

    const columns = React.useMemo<TableProps<Row>["columns"]>(() => [
        { field: "subject", headerName: "subject", editable: true, params: { required: true, header_id: form_id } },
        { field: "note", headerName: "note", editable: true, params: { header_id: form_id } },
        { field: "owner_name", headerName: TC.GLOBAL_CREATED_BY },
        { field: "create_date", headerName: TC.GLOBAL_CREATED_DATE, type: CT.TYPE_DATE },
        { field: "files", headerName: "files", type: CT.TYPE_FILE, params: { header_id: form_id } },
        {
            editable: true,
            headerName: "tags",
            field: "tags_names",
            type: CT.TYPE_SELECT,
            params: {
                multiple: true,
                header_id: form_id,
                field_value: "tags",
                getValues: options.tags,
                typeahead: { allowNewOption: true, onAddOption: options.create_tag }
            },
        },
        {
            headerName: TC.GLOBAL_LOCATION,
            children: [
                { field: "origin_name", headerName: TC.GLOBAL_ELEMENT, type: CT.TYPE_LINKED_ELEM, params: { render_form: events.edit_element } },
                { field: "tr_category", headerName: TC.GLOBAL_CATEGORY },
                { field: "sites_names", headerName: FP.SITE_FORM, hide: true },
                { field: "building_names", headerName: FP.BUILDING_FORM, hide: true },
                { field: "floor_names", headerName: TC.GLOBAL_FLOOR, hide: true },
                { field: "local_names", headerName: TC.GLOBAL_LOCAL, hide: true },
                { field: "parking_names", headerName: TC.GLOBAL_LABEL_PARKING, hide: true },
                { field: "location", headerName: TC.GLOBAL_FULL_LOC },
            ]
        },
    ], [form_id, events, options]);
    //#endregion

    return <div className="w-100">
        <C.Spinner error={status === "error"}>
            <Table<Row>
                sideBar
                ref={grid}
                rows={rows}
                status={status}
                columns={columns}
                onValueChange={onChange}
                columns_base="all_but_edit"
                getContextMenuItems={contextMenu}
                adaptableId={props.origin || TABS.NOTES_TABLE}
                extra_buttons={{
                    onClick: events.zip,
                    label: lg.getStaticText(TC.GLOBAL_ZIP_EXPORT),
                    icon: { element: "<i class='fa fa-file-archive me-2'></i>" },
                }}
            />
        </C.Spinner>
    </div>;
}

export const NotesContext: React.FC = () => {
    const [roots] = H.useRoots();
    H.useCrumbs(TC.TAB_NOTES_TABLE);
    H.useAuth({ tabName: TABS.NOTES_TABLE });
    return <Notes context={roots} origin={TABS.NOTES_TABLE} />;
}