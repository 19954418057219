import _ from "lodash";
import Flex from "./Flex";
import { Grid } from "@material-ui/core";
import { T, TB, TC } from "../../Constants";
import { ToggleButtonGroup, ToggleButton } from "@mui/material";
import { Button, ButtonGroup, Col, Row } from "react-bootstrap";
import { useDark, useLanguage, usePagination } from "../../hooks";
import React, { ReactNode, useCallback, useEffect, useMemo, useState } from "react";

//#region Types
type ButtonStyle = { style?: {}, color?: "info" };
type RowFormatProps = "xs" | "sm" | "md" | "lg" | "xl";
type RowFormat = { [key in RowFormatProps]?: RowFormatValues };
export type GridCat = { value: string, icon: string, title?: string };
type RowFormatValues = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
export type ButtonsProps = { label: string, icon: string, action: () => void, variant?: T.ColorTypes, hide?: boolean };

type AdvancedGridProps<E = unknown> = {
    elements: E[];
    label?: string;
    pageLimit?: number;
    rowFormat?: RowFormat;
    emptyGridMsg?: string;
    exclusiveCat?: boolean;
    categories?: GridCat[];
    optionsLoading?: boolean;
    buttons?: ButtonsProps[];
    extraComponent?: ReactNode;
    refreshOptions?: () => void;
    defaultCategory?: string | string[];
    renderElement: (element: E, index: number) => ReactNode;
    categoryFilter?: (element: E, categories: string[]) => boolean;
}
//#endregion

//#region Constants
const getDefaultCat = (defaults?: string | string[], categories?: GridCat[]) => {
    if (defaults) return TB.arrayWrapper(defaults).filter(TB.validString);
    if (categories) return TB.getArray(categories).map(c => c?.value).filter(TB.validString);
    return [];
}
//#endregion

const AdvancedGrid = <E,>({
    renderElement, categoryFilter, refreshOptions,
    defaultCategory, categories, buttons, elements,
    rowFormat, extraComponent, label, pageLimit,
    exclusiveCat, emptyGridMsg, optionsLoading
}: AdvancedGridProps<E>) => {
    const [sCategories, setCategories] = useState<string[] | null>(getDefaultCat(defaultCategory, categories));

    const filteredElements = useMemo(() => {
        if (typeof categoryFilter !== "function") return elements;
        return elements.filter(e => categoryFilter(e, sCategories));
    }, [elements, sCategories, categoryFilter]);

    const isDark = useDark();
    const { getStaticText, fetchStaticTranslations } = useLanguage();
    const { pagination, currentPage, pageSize } = usePagination({ initialPageSize: pageLimit, totalItems: filteredElements?.length || 0 });

    //#region Labels
    const labelList = useMemo(() => {
        let buttonsLabels = TB.getArray(buttons).map(b => b.label);
        let catLabels = TB.getArray(categories).map(c => c.title);
        return _.concat(buttonsLabels, catLabels, label, emptyGridMsg).filter(TB.isTextCode);
    }, [buttons, categories, label, emptyGridMsg]);

    useEffect(() => {
        if (labelList.length > 0) fetchStaticTranslations(labelList);
    }, [labelList, fetchStaticTranslations]);

    const vLabel = useMemo(() => TB.isTextCode(label) ? getStaticText(label) : label, [label, getStaticText]);
    const getLabel = useCallback((label?: string) => TB.isTextCode(label) ? getStaticText(label) : TB.getString(label), [getStaticText]);
    const vEmptyGrid = useMemo(() => TB.isTextCode(emptyGridMsg) ? getStaticText(emptyGridMsg) : emptyGridMsg, [emptyGridMsg, getStaticText]);
    //#endregion

    //#region Categories
    const onChangeCategory = useCallback((e: any, val: string[]) => setCategories(TB.arrayWrapper(val).filter(val => val !== 'reload')), []);
    const buttonStyle = useMemo<ButtonStyle>(() => isDark ? { style: { borderColor: "white", color: "white" }, color: "info" } : {}, [isDark]);

    const cCategories = useMemo(() => {
        if ((!Array.isArray(categories) || categories.length <= 1) && typeof refreshOptions !== "function") return null;
        return <ToggleButtonGroup exclusive={exclusiveCat} value={sCategories} onChange={onChangeCategory}>
            {typeof refreshOptions === "function" && <ToggleButton onClick={refreshOptions} sx={buttonStyle.style} title={getStaticText(TC.HOME_RELOAD_TITLE)} value="reload">
                <i className={"fa fa-refresh " + (optionsLoading ? "fa-spin" : "")}></i>
            </ToggleButton>}

            {TB.getArray(categories).map(c => <ToggleButton key={c.value} sx={buttonStyle.style} color={buttonStyle.color} title={getLabel(c.title)} value={c.value}>
                <i className={`fa fa-${c.icon}`}></i>
            </ToggleButton>)}
        </ToggleButtonGroup>
    }, [categories, exclusiveCat, optionsLoading, sCategories, buttonStyle, getLabel, getStaticText, onChangeCategory, refreshOptions]);
    //#endregion

    //#region Buttons
    const cButtons = useMemo(() => {
        if (!Array.isArray(buttons) || buttons.length === 0) return null;
        return <ButtonGroup>
            {buttons.filter(b => !b.hide).map((b, i) => <Button key={i} variant={b.variant} onClick={() => b?.action?.()}>
                <i className="fa fa-plus mr-2"></i> {getLabel(b.label)}
            </Button>)}
        </ButtonGroup>
    }, [buttons, getLabel]);
    //#endregion

    //#region Elements
    const currentElements = useMemo(() => _.slice(filteredElements, currentPage * pageSize, (currentPage + 1) * pageSize), [filteredElements, currentPage, pageSize]);
    //#endregion

    return <Row className="g-2 align-items-center">
        <Col md={12}>
            <Flex alignItems="center" justifyContent="between">
                <p className="h4">{vLabel}</p>
                {pagination}
                {cButtons}
                {cCategories}
            </Flex>
        </Col>
        {extraComponent && <Col md={12}>
            {extraComponent}
        </Col>}
        <Col md={12}>
            {currentElements.length > 0 && <Grid container spacing={1}>
                {currentElements.map((elem, i) => <Grid key={i} item {...rowFormat}>
                    {renderElement?.(elem, i)}
                </Grid>)}
            </Grid>}

            {currentElements.length === 0 && <Flex justifyContent="center" alignItems="center" className="py-4">
                <span className="fst-italic">{vEmptyGrid}</span>
            </Flex>}
        </Col>
    </Row>
}

export default AdvancedGrid;