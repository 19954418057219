import React from "react";
import * as H from "../../hooks";
import HtmlText from "./HtmlText";
import { TB } from "../../Constants";
import * as BS from "react-bootstrap";

export type IconTipProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> & {
    /** The icon when state is not spinning */
    icon: string;
    /** Should the icon be spinning */
    spin?: boolean;
    /** The icon when state is spinning */
    spinIcon?: string;
    /** The content of the tooltip */
    tipContent?: string | React.ReactNode;
    /** The content of the tooltip */
    children?: string | React.ReactNode;
    /** Color of the tooltip */
    color?: React.CSSProperties["backgroundColor"];
    /** To manually control when to open the tooltip */
    show?: boolean;
    /** Is the tip content a string that represents HTML */
    tip_text_html?: boolean;
    /** Define the trigger of the tool tip */
    trigger?: BS.OverlayTriggerProps["trigger"];
}

const IconTip: React.FC<IconTipProps> = ({ icon, className, tipContent, spin, spinIcon, children, ...props }) => {
    const lg = H.useLanguage();

    React.useEffect(() => {
        if (TB.isTextCode(tipContent)) lg.fetchStaticTranslations([tipContent]);
    }, [tipContent, lg]);

    const content = React.useMemo(() => {
        let content = children || tipContent;
        if (TB.isTextCode(content)) {
            let lgContent = lg.getStaticText(content);
            if (props.tip_text_html) return <HtmlText html={lgContent} />;
            else return lg.getStaticText(content);
        }
        return content;
    }, [children, tipContent, props.tip_text_html, lg]);

    const iconClass = React.useMemo(() => {
        let str = `fa`;
        if (spin) str += ` fa-spin fa-${TB.getString(spinIcon, icon)}`;
        else str += ` fa-${TB.getString(icon)}`;
        if (className) str += " " + TB.getString(className);
        return str;
    }, [icon, spin, className, spinIcon]);

    const onEntering = React.useCallback<BS.OverlayTriggerProps["onEntering"]>(e => {
        if (props.color) (e.children[1] as any).style.backgroundColor = props.color;
    }, [props.color]);

    const renderToolTip = React.useCallback(p => <BS.Tooltip {...p} className={TB.getString(p.className) + " z-index-higher-modal"} children={content} />, [content]);

    if (!content) return <i className={iconClass} {...props} />;
    return <BS.OverlayTrigger trigger={props.trigger} show={props.show} onEntering={onEntering} overlay={renderToolTip} children={<i className={iconClass} {...props} />} />;
};

export default IconTip;