import _ from "lodash";
import React from "react";
import moment from "moment";
import * as M from "../../Modal";
import * as H from "../../../hooks";
import * as C from "../../../Common";
import * as S from "../../../services";
import * as PM from "../../../PurposeModal";
import { CellsTypes as CT } from "../AgGridDefs";
import { Table, TableProps, TableRef } from "../Grid";
import { renderEquipIndicatorModal } from "../../../PurposeModal";
import { FP, RIGHTS, T, TB, TABS, TC, LT, PANELS } from "../../../Constants";

//#region Types
export type EmplacementTableProps<A = {}> = {
    /** The context to load the emplacements from */
    context?: T.ContextParams;
    /** The origin, to differentiate the saved configuration */
    origin?: string;
    /** A class to add to the main container */
    className?: string;
    /** Restrict the rows to a certain type of emplacement */
    restrict_type?: T.AllowArray<T.EmplacementData["type"]>;
    /** Callback that fires when a new emplacement is created */
    on_create?: (rows: Row[]) => void;
    /** Callback that fires when an emplacement is deleted */
    on_delete?: (ids: string[]) => void;
    /** Callback that fires when an emplacement is edited */
    on_edit?: (id: string) => void;
    /** Callback that fires when the data is loaded, can be call multiple times, if multiple data loading */
    on_data_loaded?: () => void;
    /** Load a specific view depending on the needs */
    view?: "nrj_mission_parking";
    /** Extra columns */
    extra_columns?: TableProps<Row<A>>["columns"];
    /** Add extra data to the rows */
    process_rows?: (rows: Row[]) => Row<A>[];
    /** Buttons to show in the toolbar */
    buttons?: TableProps<Row<A>>["extra_buttons"];
}

export type EmplacementTableRef<A = {}> = {
    /** The ref of the grid */
    grid: React.MutableRefObject<TableRef<Row<A>>>;
    /** The rows currently in the state */
    rows: Row<A>[];
    /** The status of the data */
    status: T.AsyncStates;
    /** Callback to update the rows */
    setRows: (rows: Row<A>[]) => void;
}

type Row<A = {}> = ReturnType<T.API.Utils.Tables.GetEmplacementRows>[number] & A;
//#endregion

//#region Constants
const TEXT_CODES = [
    TC.REM_X_REMARQUES, TC.NEW_REM_DEF, TC.GLOBAL_ADD_LOCATION, TC.GLOBAL_NEW_LOCATION, TC.AG_DUPLICATE, TC.GLOBAL_SAME_LOC,
    TC.AG_QUICK_INSERT, TC.GLOBAL_EDIT, TC.GLOBAL_DELETE, TC.GLOBAL_BULK_INSERT, TC.TABLE_EQUIP_MOVE_ELEM, TC.GLOBAL_TABLE_UNPIN_TOP_ROW,
];

const NO_EDIT_FIELDS = [
    "plan", "employees", "floor_ids", "local_ids", "num_sites", "reference", "remarques",
    "renter_id", "sites_ids", "selectIcon", "enseigne_id", "floor_names", "local_names",
    "parking_ids", "renter_name", "sites_names", "buildings_ids", "enseigne_name", "parking_names",
    "building_names", "reglementations", "pictures"
];

const OPTIONS = {
    type: [
        { label: TC.GLOBAL_FLOOR, value: "floor" },
        { label: TC.GLOBAL_LOCAL, value: "local" },
        { label: TC.GLOBAL_ZONE, value: "zone" },
        { label: TC.GLOBAL_LABEL_PARKING, value: "parking" },
    ] as T.Option<{}, Row["type"]>[],
    affectations: [
        { value: "lab", label: TC.AFFECT_LAB },
        { value: "food", label: TC.AFFECT_FOOD },
        { value: "hotel", label: TC.AFFECT_HOTEL },
        { value: "sport", label: TC.AFFECT_SPORT },
        { value: "retail", label: TC.AFFECT_RETAIL },
        { value: "offices", label: TC.AFFECT_OFFICE },
        { value: "health", label: TC.AFFECT_HEALTH },
        { value: "public", label: TC.AFFECT_PUBLIC },
        { value: "airport", label: TC.AFFECT_AIRPORT },
        { value: "industry", label: TC.AFFECT_INDUSTRY },
        { value: "community", label: TC.AFFECT_COMMUNITY },
        { value: "education", label: TC.AFFECT_EDUCATION },
        { value: "residential", label: TC.AFFECT_RESIDENTIAL },
        { value: "entertainment", label: TC.AFFECT_ENTERTAINMENT },
        { value: "other", label: TC.AFFECT_OTHER },
    ] as T.Option<{}, T.EmplacementData["actifType"]>[],
};
//#endregion

const RenderEmplacement = <A extends {},>({ on_create, on_delete, process_rows, on_edit, on_data_loaded, ...props }: EmplacementTableProps<A>, ref: React.ForwardedRef<EmplacementTableRef<A>>) => {
    const [formsIds] = H.useFormIds();
    const loading = H.useBoolean(false);
    const lg = H.useLanguage(TEXT_CODES);
    const [{ userId, isAdmin }] = H.useAuth();
    const rights = H.useRights(props.context);
    const pinned_ref = React.useRef<Row<A>[]>([]);
    const grid = React.useRef<TableRef<Row<A>>>(null);
    const [emplacements, setEmplacements, status] = H.useAsyncState<Row<A>[]>([]);

    React.useImperativeHandle(ref, () => ({
        grid,
        status,
        rows: emplacements,
        setRows: setEmplacements,
    }), [emplacements, status, setEmplacements]);

    React.useEffect(() => {
        if (status === "done") on_data_loaded?.();
    }, [status, on_data_loaded]);

    //#region Load rows
    React.useEffect(() => {
        let isSubscribed = true;
        S.getEmplacementRows({ context: props.context, restrict_types: props.restrict_type })
            .then(({ data }) => isSubscribed && setEmplacements(data as Row<A>[], "done"))
            .catch(() => isSubscribed && setEmplacements([], "error"));
        return () => {
            isSubscribed = false;
            setEmplacements([], "load");
        }
    }, [setEmplacements, props.context, props.restrict_type]);
    //#endregion

    //#region Formatter
    const translations = React.useMemo(() => ({
        criticity: (id?: string, label?: string) => id ? lg.getTextObj(id, "level", label) : "",
        type: (type: Row["type"]) => lg.getStaticText(OPTIONS.type.filter(t => t.value === type)[0]?.label),
        affect: (affect: Row["actifType"]) => lg.getStaticText(OPTIONS.affectations.filter(a => a.value === affect)[0]?.label),
    }), [lg]);

    const rows = React.useMemo(() => {
        let table_rows = emplacements.map(e => ({
            ...e,
            type_name: translations.type(e.type),
            affect_name: translations.affect(e.actifType),
            criticity_name: translations.criticity(e.criticity, e.criticity_label),
        })) as Row<A>[];

        if (typeof process_rows === "function") table_rows = process_rows(table_rows);
        return table_rows;
    }, [translations, emplacements, process_rows]);

    const allowed_types = React.useMemo(() => {
        if (!props.restrict_type) return OPTIONS.type.map(t => t.value);
        else return TB.arrayWrapper(props.restrict_type);
    }, [props.restrict_type]);

    React.useEffect(() => {
        // Update the pinned rows to always show the translations
        let tr_rows = pinned_ref.current.map(r => rows.filter(tr => tr._id === r._id)[0] || null).filter(r => r !== null);
        grid.current?.grid?.api?.setPinnedTopRowData(tr_rows);
    }, [rows]);
    //#endregion

    //#region Remarques
    const remarque_updates = React.useMemo(() => ({
        /** Update the number of remarques on an item */
        updates: (id: string, delta: number) => {
            if (delta !== 0) S.getEmplacementRowsByIds(id)
                .then(({ data }) => setEmplacements(p => p.map(r => (data as Row<A>[]).find(row => row._id === r._id) || r)))
                .catch(M.Alerts.loadError);
        },
        /** If an emplacement doesn't have a criticity yet, pick one */
        getCriticity: (row: Row) => new Promise((resolve, reject) => {
            // Already has a criticity
            if (TB.mongoIdValidator(row.criticity)) resolve(row.criticity);
            // Set a new criticity and push it directly in database
            else renderEquipIndicatorModal({ type: "criticityEquipment", title: TC.REM_EQUIP_CRITICITY, toUpdate: row._id })
                .then(criticity => resolve(criticity));
        }),
    }), [setEmplacements]);

    const remarques_event = React.useMemo(() => ({
        show: (row: Row) => M.renderPopUpFormModal({
            title: row.name,
            defaultKey: "rem",
            submissionId: row._id,
            path: FP.EMPLACEMENT_FORM,
            updateRem: edits => remarque_updates.updates(row._id, edits.add.length - edits.delete.length),
        }),
        add: (row: Row) => remarque_updates.getCriticity(row).then(criticity => {
            let params = {
                title: TC.NEW_REM_DEF,
                path: FP.REMARQUES_DEFAULTS,
                forcedSubmission: [
                    { prop: "element", value: row._id },
                    { prop: "elementCriticity", value: criticity },
                ],
            } as Parameters<typeof M.renderFormModal>[0];

            if (TB.mongoIdValidator(criticity)) M.renderFormModal<T.RemarqueDefault>(params).then(remarque => {
                if (remarque) {
                    let freq = TB.splitFrequency(remarque.data.deadline);
                    // Ask to create a maintenance ticket
                    M.askConfirm({ text: TC.REM_TABLE_ADD_ACTION, title: TC.G_ACTION, noText: TC.GLOBAL_NO, yesText: TC.GLOBAL_YES }).then(confirmed => {
                        let ticketPromise = new Promise<void>(r => {
                            // Create a maintenance ticket
                            if (confirmed) M.renderFormModal<T.TicketData>({
                                path: FP.TICKET_FORM,
                                title: row.name,
                                forcedSubmission: [
                                    { prop: "equipment", value: row._id },
                                    { prop: "remarque", value: remarque._id },
                                    { prop: "description", value: remarque.data.description },
                                    { prop: "end", value: moment().add(freq.num, freq.unit).toISOString() },
                                ]
                            })
                                .then(() => r());
                            else r();
                        });

                        ticketPromise.then(() => remarque_updates.updates(row._id, 1));
                    });
                }
                // No remarque added, but criticity was updated
                else if (!TB.mongoIdValidator(row.criticity)) setEmplacements(p => p.map(e => e._id !== row._id ? e : { ...e, criticity }));
            });
        }),
        /** Opens a graph modal */
        graphs: (row: Row) => {
            // Ask for which building to load
            PM.renderBuildingSelect({ context: { roots: row._id }, isRequired: true }).then(building => {
                // Render the energy dashboard
                if (building) PM.renderEnergyDashboard({
                    building,
                    group: "day",
                    tab: "datasets",
                    isFullScreen: true,
                    datasets: row.datasets_ids,
                });
            });
        }
    }), [setEmplacements, remarque_updates]);
    //#endregion

    //#region Edits
    const location_edits = React.useMemo(() => ({
        pick: (element_id?: string) => new Promise<string>((resolve, reject) => {
            PM.renderSiteSelect({ context: props.context, isRequired: true }).then(root => {
                if (root) M.renderLightTree({
                    root,
                    selection: element_id,
                    restrictOwnLinks: true,
                    style: { size: "lg", title: TC.GLOBAL_PICK_PARENT },
                    linkRestriction: {
                        isInput: true,
                        no_children: true,
                        excludeIds: element_id,
                        linkType: LT.LINK_TYPE_OWN,
                        objForm: formsIds[FP.EMPLACEMENT_FORM],
                    }
                })
                    .then(parent => resolve(parent))
                    .catch(reject);
                else resolve(null);
            }).catch(reject);
        }),
    }), [formsIds, props.context]);

    const pinned = React.useMemo(() => ({
        /** Update the pinned rows if they changed */
        update: (rows: T.AllowArray<Row<A>>) => {
            if (!Array.isArray(rows)) rows = [rows];
            let new_pinned = [] as typeof rows, changed = false;
            // Update the ref with the updated version
            for (let row of pinned_ref.current) {
                let updated_version = rows.filter(r => r._id === row._id)[0];
                if (updated_version) {
                    changed = true;
                    new_pinned.push(updated_version);
                }
                else new_pinned.push(row);
            }
            // If there was at least one change, update the grid
            if (changed) pinned.pin(new_pinned);
        },
        pin: (rows: T.AllowArray<Row<A>>) => {
            if (!Array.isArray(rows)) rows = [rows];
            let already_in = pinned_ref.current.map(r => r._id);
            let [to_update, to_add] = _.partition(rows, r => already_in.includes(r._id));
            pinned_ref.current = pinned_ref.current
                // Replace the rows already in the ref
                .map(r => to_update.filter(u => u._id === r._id)[0] || r)
                // Add the new rows
                .concat(to_add);
            grid.current?.grid?.api?.setPinnedTopRowData(pinned_ref.current);
        },
        unpin: (rows_ids: T.AllowArray<string>) => {
            if (!Array.isArray(rows_ids)) rows_ids = [rows_ids];
            pinned_ref.current = pinned_ref.current.filter(r => !rows_ids.includes(r._id));
            grid.current?.grid?.api?.setPinnedTopRowData(pinned_ref.current);
        },
        unpin_all: () => {
            // Ask the grid to unpin the rows
            grid.current?.grid?.api?.setPinnedTopRowData();
            // Clear the ref
            pinned_ref.current = [];
        },
    }), []);

    const emplacement_updates = React.useMemo(() => ({
        /** Remove a row */
        remove: (id: string) => setEmplacements(p => p.filter(r => r._id !== id)),
        /** Replace old rows with new ones */
        replace: (rows: Row<A>[]) => setEmplacements(p => p
            .map(r => rows.filter(row => row._id === r._id)[0] || r)
            .filter(r => allowed_types.includes(r.type))
        ),
        /** Add or updates rows */
        add: (rows: Row<A>[]) => setEmplacements(p => {
            let existing_ids = p.map(r => r._id);
            let [to_update, to_add] = _.partition(rows, r => existing_ids.includes(r._id));
            return p
                .map(r => to_update.filter(row => row._id === r._id)[0] || r)
                .concat(to_add)
                .filter(r => allowed_types.includes(r.type));
        })
    }), [setEmplacements, allowed_types]);

    const emplacement_events = React.useMemo(() => ({
        /** Create a new emplacement */
        add: (row?: Row<A>, type?: "dupe" | "same_loc") => {
            // Find the parent's id
            let location_promise = new Promise<string | boolean>(r => r(false));
            if (type !== "dupe" && type !== "same_loc") location_promise = location_edits.pick();

            location_promise.then(parent => {
                // User did not cancel parent pick
                if (typeof parent === "string" || typeof parent === "boolean") {
                    // Create an emplacement
                    let emplacement_promise = new Promise<T.Submission<T.EmplacementData>>((resolve, reject) => {
                        if (type === "dupe") S.duplicateSubmissions(row._id)
                            .then(({ data }) => resolve(data[0] as T.Submission<T.EmplacementData>))
                            .catch(reject);
                        else {
                            let forcedSubmission = [] as Parameters<typeof M.renderFormModal>[0]["forcedSubmission"];
                            if (allowed_types.length === 1) forcedSubmission.push({ prop: "type", value: allowed_types[0], disable: true });
                            M.renderFormModal<T.EmplacementData>({ path: FP.EMPLACEMENT_FORM, modalProps: { size: "lg" }, forcedSubmission })
                                .then(emp => resolve(emp))
                                .catch(reject);
                        }
                    });

                    let parent_id = typeof parent === "string" ? parent : undefined;

                    emplacement_promise.then(new_emp => {
                        if (new_emp) {
                            let unmount = M.renderLoader();
                            S.attachNode({ parent: parent_id, sibling: row?._id, children: new_emp._id, type: LT.LINK_TYPE_OWN }).then(() => {
                                S.getEmplacementRowsByIds(new_emp._id)
                                    .then(({ data }) => {
                                        let loaded_rows = data as Row<A>[];
                                        // Notify parent that emplacements were created
                                        on_create?.(loaded_rows);
                                        // Update local state
                                        emplacement_updates.add(loaded_rows);
                                        // Pin new row to the top
                                        let new_row = loaded_rows.filter(r => r._id === new_emp._id)[0];
                                        if (new_row) pinned.pin(loaded_rows);
                                    })
                                    .catch(M.Alerts.updateError)
                                    .finally(unmount);
                            }).catch(e => {
                                unmount();
                                M.Alerts.updateError(e);
                            });
                        }
                    }).catch(M.Alerts.updateError);
                }
            }).catch(M.Alerts.loadError)
        },
        /** Creates many emplacements in the database */
        bulk_create: (emplacements: BulkRowData[]) => {
            let unmount = M.renderLoader();
            S.bulk_emplacements(emplacements).then(({ data }) => {
                let loaded_rows = data as Row<A>[];
                // Notify parent that emplacements were created
                on_create?.(loaded_rows);
                // Update local state
                emplacement_updates.add(loaded_rows);
                // Pin new rows to the top
                pinned.pin(loaded_rows);
            })
                .catch(M.Alerts.updateError)
                .finally(unmount);
        },
        /** Create multiple new emplacements */
        bulk: () => {
            M.renderBlankModal({
                isFullScreen: true,
                disableEscapeKeyDown: true,
                title: TC.GLOBAL_BULK_INSERT,
                renderContent: resolve => <EmplacementQuickInput context={props.context} onSubmit={resolve} />,
            })
                .then(emplacements => emplacements && emplacement_events.bulk_create(emplacements))
                .catch(M.Alerts.updateError);
        },
        /** Edit an existing emplacement */
        edit: (row: Row) => {
            let params: Parameters<typeof M.renderPopUpFormModal>[0] = {
                title: row.name,
                submissionId: row._id,
                path: FP.EMPLACEMENT_FORM,
                updateRem: edits => remarque_updates.updates(row._id, edits.add.length - edits.delete.length),
            };

            M.renderPopUpFormModal(params).then(edited => {
                if (edited) S.getEmplacementRowsByIds(edited._id).then(({ data }) => {
                    let loaded_rows = data as Row<A>[];
                    // Notify the parent that an emplacement was edited
                    on_edit?.(params.submissionId);
                    // Update in the pinned rows
                    pinned.update(loaded_rows);
                    // Update local state
                    emplacement_updates.replace(loaded_rows);
                }).catch(M.Alerts.updateError);
            });
        },
        /** Remove an emplacement */
        remove: (row: Row<A>) => M.askConfirm().then(confirmed => {
            if (confirmed) S.removeEmplacements(row._id).then(({ data }) => {
                // Notify parent that an emplacement was deleted
                on_delete?.([row._id]);
                // Remove Pinned rows
                pinned.unpin(row._id);
                if (data === "has_children") M.renderAlert({ type: "warning", message: TC.ERROR_DELETE_DESCENDANT_FIRST });
                else emplacement_updates.remove(row._id);
            }).catch(M.Alerts.deleteError);
        }),
        /* Move an emplacement */
        move: (row: Row<A>) => location_edits.pick(row._id).then(parent_id => {
            if (parent_id) {
                let unmount = M.renderLoader();
                S.moveNode({ removeOld: "sameType", children: row._id, type: LT.LINK_TYPE_OWN, parent: parent_id }).then(() => {
                    // Update the row
                    S.getEmplacementRowsByIds(row._id).then(({ data }) => {
                        let loaded_rows = data as Row<A>[];
                        // Notify parent that an emplacement was edited
                        on_edit?.(row._id);
                        // Update in the pinned rows
                        pinned.update(loaded_rows);
                        // Update the local state
                        emplacement_updates.replace(loaded_rows);
                    })
                        .catch(M.Alerts.loadError)
                        .finally(unmount);
                }).catch(error => {
                    unmount();
                    M.Alerts.updateError(error);
                })
            }
        }),
        /** Manage rent */
        manage_rent: (row: Row<A>) => {
            M.renderBailForm({ origin: row._id }).then(() => {
                let unmount = M.renderLoader();
                // Update the row
                S.getEmplacementRowsByIds(row._id).then(({ data }) => {
                    let loaded_rows = data as Row<A>[];
                    emplacement_updates.replace(loaded_rows);
                    // Update the pinned rows
                    pinned.update(loaded_rows);
                })
                    .catch(M.Alerts.loadError)
                    .finally(unmount);
            });
        }
    }), [remarque_updates, pinned, emplacement_updates, location_edits, allowed_types, props.context, on_create, on_delete, on_edit]);

    const onValueChange = React.useCallback<TableProps<Row<A>>["onValueChange"]>(params => {
        let row = params.data,
            old_value = params.oldValue,
            field = params.colDef.field as keyof typeof rows[number];

        // This field can't be edited
        if (NO_EDIT_FIELDS.includes(field as string)) M.Alerts.updateError();
        // Can the user edit this emplacement
        else if (rights.isRightAllowed(RIGHTS.TECH.EDIT_EMPLACEMENT, row._id)) {
            let prop: keyof T.EmplacementData;
            // Set the right prop
            if (field === "criticity_name") {
                prop = "criticity";
                old_value = row.criticity;
            }
            else if (field === 'type_name') {
                prop = "type";
                old_value = row.type;
            }
            else if (field === "affect_name") {
                prop = "actifType";
                old_value = row.actifType;
            }
            else if (field === "tags_names") {
                prop = "tags";
                old_value = row.tags;
            }
            else prop = field as typeof prop;

            const updatePromise = new Promise<"cancel" | Row<A>[]>((resolve, reject) => {
                if (!_.isEqual(old_value, params.newValue)) {
                    let api_params = {
                        field: prop,
                        _id: row._id,
                        old_value: old_value,
                        new_value: params.newValue,
                    } as Parameters<typeof S.update_emplacement_field>[0];

                    loading.setTrue();

                    S.update_emplacement_field(api_params).then(({ data }) => {
                        if (data === "changed") M.askConfirm({ title: TC.UPDATE_FORCE_CHANGE, text: TC.UPDATE_VALUE_UNFRESH }).then(confirmed => {
                            if (confirmed) S.update_emplacement_field({ ...api_params, force_update: true }).then(({ data }) => {
                                if (data === "changed") reject("Error");
                                else resolve(data as Row<A>[]);
                            }).catch(reject);
                            else resolve("cancel");
                        });
                        else resolve(data as Row<A>[]);
                    }).catch(reject);
                }
                else resolve("cancel");
            });

            updatePromise.then(rows => {
                if (rows !== "cancel") {
                    // Notify the parent that there was an edit
                    on_edit?.(row._id);
                    // Update local state
                    emplacement_updates.add(rows);
                }
            })
                .catch(M.Alerts.updateError)
                .finally(loading.setFalse);
        }
        else M.Alerts.haveNotRight();
    }, [rights, emplacement_updates, loading, on_edit]);
    //#endregion

    //#region Columns
    const form_id = React.useMemo(() => formsIds[FP.EMPLACEMENT_FORM], [formsIds]);

    const get_options = React.useMemo(() => ({
        type: () => new Promise<T.Option[]>((resolve, reject) => {
            S.getEmplacementResource({ type: "emplacement_type", lang: lg.prop })
                .then(({ data }) => resolve(data as T.Option[])).catch(reject);
        }),
        criticity: () => new Promise<T.Option[]>((resolve, reject) => {
            S.getPreciseEquipIndicator("criticityEquipment")
                .then(({ data }) => resolve(data.map(d => ({
                    value: d._id,
                    prop: "level",
                    label: d.data.level,
                }))))
                .catch(reject);
        }),
        affect: () => new Promise<T.Option[]>((resolve, reject) => {
            S.getEmplacementResource({ type: "emplacement_affectation", lang: lg.prop })
                .then(({ data }) => resolve(data as T.Option[])).catch(reject);
        }),
        tags: (row: Row) => new Promise<T.Option[]>((resolve, reject) => {
            S.getNoteTags({ context: { roots: row._id }, type: "emplacement" })
                .then(r => resolve(r.data))
                .catch(reject);
        }),
        create_tag: (text: string, row: Row) => new Promise<T.Option>((resolve, reject) => {
            let submission = { name: text, users: [userId], sites: [], type: "emplacement" } as T.NoteTag;
            S.createSubmission({ submission, path: FP.NOTE_TAG_FORM }).then(({ data }) => {
                let new_tag = data.submissions[0] as T.Submission<T.NoteTag>;
                if (new_tag) {
                    let new_option = { label: new_tag.data.name, value: new_tag._id } as T.Option;
                    resolve(new_option);
                }
                else resolve(null);
            }).catch(reject);
        }),
    }), [userId, lg.prop]);

    const columns = React.useMemo<TableProps<Row<A>>["columns"]>(() => {
        let table_columns = [] as TableProps<Row<A>>["columns"];

        if (props.view === "nrj_mission_parking") table_columns = [
            { field: "name", headerName: "name", params: { required: true, header_id: form_id } },
            {
                headerName: TC.BR_B_AREA,
                children: [
                    { field: "surfaceNFA", headerName: "surfaceNFA", type: CT.TYPE_NUMBER, params: { maxDigit: 2, header_id: form_id } },
                ],
            },
            {
                headerName: TC.GLOBAL_LOCATION,
                children: [
                    { field: "floor_names", headerName: TC.GLOBAL_FLOOR, editable: false },
                    { field: "local_names", headerName: TC.GLOBAL_LOCAL, editable: false },
                ]
            },
            { field: "affect_name", headerName: "actifType", type: CT.TYPE_SELECT, params: { header_id: form_id, getValues: get_options.affect } },
            { field: "actif_detail", headerName: "actif_detail", params: { header_id: form_id } },
            { field: "pictures", headerName: "pictures", sortable: false, type: CT.TYPE_FILE, params: { header_id: form_id } },
            { field: "plan", headerName: "plan", sortable: false, type: CT.TYPE_FILE, params: { header_id: form_id } },
        ];
        else table_columns = [
            {
                headerName: FP.REMARQUES_DEFAULTS,
                children: [
                    {
                        headerName: " ",
                        field: "remarques",
                        type: CT.TYPE_REM_BUTTON,
                        params: {
                            action: row => remarques_event.show(row),
                            isDisabled: row => row && row.remarques.nb_remarques === 0,
                            isHidden: !rights.isRightAllowed(RIGHTS.MISC.WRITE_OWN_REMARQUES),
                            buttonProps: row => ({ size: "sm", variant: row?.remarques?.remarque_color }),
                            content: row => row && lg.getStaticElem(TC.REM_X_REMARQUES, row.remarques.nb_remarques.toString()),
                        }
                    },
                    {
                        headerName: " ",
                        editable: false,
                        field: "add_remarque",
                        type: CT.TYPE_ACTION_BUTTON,
                        params: {
                            action: row => remarques_event.add(row),
                            buttonProps: { icon: "plus", size: "sm", tip: TC.NEW_REM_DEF },
                        }
                    },
                ]
            },
            {
                headerName: " ",
                editable: false,
                field: "to_data",
                type: CT.TYPE_ACTION_BUTTON,
                params: {
                    buttonProps: { icon: "pie-chart", size: "sm" },
                    action: row => remarques_event.graphs(row),
                    isDisabled: row => !rights.isPanelAllowed(PANELS.TABS_BUILD_ENERGY, row?._id) || !Array.isArray(row?.datasets_ids) || row.datasets_ids.length === 0,
                }
            },
            // Emplacement data
            { field: "name", headerName: "name", params: { required: true, header_id: form_id, certify: true } },
            { field: "type_name", headerName: "type", type: CT.TYPE_SELECT, params: { header_id: form_id, getValues: get_options.type, certify: { prop: "type" } } },
            {
                headerName: "tags",
                field: "tags_names",
                type: CT.TYPE_SELECT,
                params: {
                    multiple: true,
                    header_id: form_id,
                    field_value: "tags",
                    getValues: get_options.tags,
                    typeahead: { allowNewOption: true, onAddOption: get_options.create_tag },
                }
            },
            { field: "color", headerName: "color", type: CT.TYPE_COLOR, params: { header_id: form_id } },
            { field: "criticity_name", headerName: "criticity", type: CT.TYPE_SELECT, params: { header_id: form_id, getValues: get_options.criticity, certify: { prop: "criticity" } } },
            { field: "floorNumber", headerName: "floorNumber", type: CT.TYPE_NUMBER, params: { header_id: form_id, certify: true } },
            { field: "isRentable", headerName: "isRentable", type: CT.TYPE_CHECKBOX, params: { header_id: form_id, certify: true } },
            { field: "affect_name", headerName: "actifType", type: CT.TYPE_SELECT, params: { header_id: form_id, getValues: get_options.affect, certify: { prop: "actifType" } } },
            { field: "actif_detail_name", headerName: "actif_detail", params: { header_id: form_id, certify: { prop: "actif_detail" } } },
            { field: "pictures", headerName: "pictures", sortable: false, type: CT.TYPE_FILE, params: { header_id: form_id } },
            { field: "plan", headerName: "plan", sortable: false, type: CT.TYPE_FILE, params: { header_id: form_id } },
            {
                headerName: TC.BR_B_AREA,
                children: [
                    { field: "surfaceLA", headerName: "surfaceLA", type: CT.TYPE_NUMBER, params: { maxDigit: 2, header_id: form_id, certify: true } },
                    { field: "area", headerName: "area", type: CT.TYPE_NUMBER, params: { maxDigit: 2, header_id: form_id, certify: true } },
                    { field: "surfaceIFA", headerName: "surfaceIFA", type: CT.TYPE_NUMBER, params: { maxDigit: 2, header_id: form_id, certify: true } },
                    { field: "surfaceNFA", headerName: "surfaceNFA", type: CT.TYPE_NUMBER, params: { maxDigit: 2, header_id: form_id, certify: true } },
                    { field: "surfaceNRA", headerName: "surfaceNRA", type: CT.TYPE_NUMBER, params: { maxDigit: 2, header_id: form_id, certify: true } },
                    { field: "surfaceTA", headerName: "surfaceTA", type: CT.TYPE_NUMBER, params: { maxDigit: 2, header_id: form_id, certify: true } },
                    { field: "surfaceUTA", headerName: "surfaceUTA", type: CT.TYPE_NUMBER, params: { maxDigit: 2, header_id: form_id, certify: true } },
                    { field: "surfaceCA", headerName: "surfaceCA", type: CT.TYPE_NUMBER, params: { maxDigit: 2, header_id: form_id, certify: true } },
                    { field: "surfaceUCA", headerName: "surfaceUCA", type: CT.TYPE_NUMBER, params: { maxDigit: 2, header_id: form_id, certify: true } },
                    { field: "surfaceAA", headerName: "surfaceAA", type: CT.TYPE_NUMBER, params: { maxDigit: 2, header_id: form_id, certify: true } },
                    { field: "surfaceUAA", headerName: "surfaceUAA", type: CT.TYPE_NUMBER, params: { maxDigit: 2, header_id: form_id, certify: true } },
                    { field: "surfacePA", headerName: "surfacePA", type: CT.TYPE_NUMBER, params: { maxDigit: 2, header_id: form_id, certify: true } },
                    { field: "surfaceUPA", headerName: "surfaceUPA", type: CT.TYPE_NUMBER, params: { maxDigit: 2, header_id: form_id, certify: true } },
                    { field: "surfaceBHS", headerName: "surfaceBHS", type: CT.TYPE_NUMBER, params: { maxDigit: 2, header_id: form_id, certify: true } },
                    { field: "surfaceRENT", headerName: "surfaceRENT", type: CT.TYPE_NUMBER, params: { maxDigit: 2, header_id: form_id, certify: true } },
                    { field: "surfaceRENTED", headerName: "surfaceRENTED", type: CT.TYPE_NUMBER, params: { maxDigit: 2, header_id: form_id, certify: true } },
                    { field: "surfaceHEAT", headerName: "surfaceHEAT", type: CT.TYPE_NUMBER, params: { maxDigit: 2, header_id: form_id, certify: true } },
                    { field: "surfaceCold", headerName: "surfaceCold", type: CT.TYPE_NUMBER, params: { maxDigit: 2, header_id: form_id, certify: true } },
                ],
            },
            {
                headerName: TC.GLOBAL_LOCATION,
                children: [
                    { field: "num_site", headerName: "Site reference", editable: false },
                    { field: "sites_names", headerName: FP.SITE_FORM, hide: true, editable: false },
                    { field: "building_names", headerName: FP.BUILDING_FORM, hide: true, editable: false },
                    { field: "floor_names", headerName: TC.GLOBAL_FLOOR, hide: true, editable: false },
                    { field: "local_names", headerName: TC.GLOBAL_LOCAL, hide: true, editable: false },
                    { field: "parking_names", headerName: TC.GLOBAL_LABEL_PARKING, hide: true, editable: false },
                    { field: "location", headerName: TC.GLOBAL_FULL_LOC, editable: false },
                ]
            },
            {
                headerName: TC.GLOBAL_RENTAL,
                children: [
                    { field: "renter_name", headerName: TC.GLOBAL_LABEL_RENTER, editable: false },
                    { field: "enseigne_name", headerName: FP.ENSEIGNE_FORM, editable: false },
                    { field: "rent_end", headerName: TC.EMP_END_RENT, editable: false, type: CT.TYPE_DATE },
                ]
            }
        ];

        if (Array.isArray(props.extra_columns)) table_columns.push(...props.extra_columns);
        else if (props.extra_columns) table_columns.push(props.extra_columns);

        if (isAdmin) table_columns.push({ field: "_id", headerName: "_id", editable: false, hide: true });

        return table_columns;
    }, [lg, remarques_event, form_id, isAdmin, get_options, rights, props.view, props.extra_columns]);
    //#endregion

    //#region Context Menu
    const contextMenu = React.useCallback<TableProps<Row<A>>["getContextMenuItems"]>(event => {
        let row = event.node?.data;
        let items = [] as ReturnType<TableProps<Row<A>>["getContextMenuItems"]>;
        let pinned_rows = grid.current?.grid?.api?.getPinnedTopRowCount?.() || 0;

        // Create options
        if (rights.isRightAllowed(RIGHTS.TECH.CREATE_EMPLACEMENT)) items.push(
            {
                icon: "<i class='fa fa-plus'></i>",
                action: () => emplacement_events.add(),
                name: lg.getStaticText(TC.GLOBAL_ADD_LOCATION),
                subMenu: [
                    {
                        icon: "<i class='fa fa-plus'></i>",
                        action: () => emplacement_events.add(),
                        name: lg.getStaticText(TC.GLOBAL_NEW_LOCATION),
                    },
                    {
                        disabled: !row,
                        icon: "<i class='fa fa-copy'></i>",
                        name: lg.getStaticText(TC.AG_DUPLICATE),
                        action: () => emplacement_events.add(row, "dupe"),
                    },
                    {
                        disabled: !row,
                        name: lg.getStaticText(TC.GLOBAL_SAME_LOC),
                        icon: "<i class='fa fa-location-arrow'></i>",
                        action: () => emplacement_events.add(row, "same_loc"),
                    },
                ]
            },
            {
                action: emplacement_events.bulk,
                name: lg.getStaticText(TC.AG_QUICK_INSERT),
                icon: "<i class='text-warning fa fa-bolt'></i>",
            }
        );
        // Create many equipments at once
        if (row && rights.isRightAllowed(RIGHTS.TECH.CREATE_EMPLACEMENT)) items.push({
            action: emplacement_events.bulk,
            icon: "<i class='fa fa-bolt text-info'></i>",
            name: lg.getStaticText(TC.GLOBAL_BULK_INSERT),
        });
        // Edit options
        if (row && rights.isRightAllowed(RIGHTS.TECH.EDIT_EMPLACEMENT, row._id)) items.push({
            name: lg.getStaticText(TC.GLOBAL_EDIT),
            icon: "<i class='fa fa-pencil-alt'></i>",
            action: () => emplacement_events.edit(row),
        });
        // Delete options
        if (row && rights.isRightAllowed(RIGHTS.TECH.DELETE_EMPLACEMENT, row._id)) items.push({
            name: lg.getStaticText(TC.GLOBAL_DELETE),
            action: () => emplacement_events.remove(row),
            icon: "<i class='text-danger fa fa-times'></i>",
        });
        // Move an equipment
        if (row && rights.isRightAllowed(RIGHTS.TECH.EDIT_EMPLACEMENT, row._id)) items.push({
            action: () => emplacement_events.move(row),
            icon: "<i class='fa fa-arrows-alt'></i>",
            name: lg.getStaticText(TC.TABLE_EMP_MOVE_ELEM),
        });
        // Manage the bails
        if (row) items.push({
            name: lg.getStaticText(TC.BAIL_MANAGER),
            icon: "<i class='fa fa-handshake'></i>",
            action: () => emplacement_events.manage_rent(row),
        });
        // Add a separator
        if (items.length > 0) items.push("separator");
        // Unpin top rows
        items.push({
            action: pinned.unpin_all,
            disabled: pinned_rows === 0,
            icon: "<i class='fa fa-thumbtack text-danger'></i>",
            name: lg.getStaticText(TC.GLOBAL_TABLE_UNPIN_TOP_ROW),
        });

        if (items.length > 0 && event.defaultItems?.length > 0) items.push("separator", ...event.defaultItems);
        return items;
    }, [emplacement_events, lg, rights, pinned]);
    //#endregion

    //#region Languages
    React.useEffect(() => lg.fetchObjectTranslations(form_id), [form_id, lg]);

    React.useEffect(() => {
        let ids = [] as string[];
        for (let emp of emplacements) ids.push(emp.criticity);
        if (ids.length > 0) lg.fetchObjectTranslations(ids);
    }, [lg, emplacements]);

    React.useEffect(() => {
        let codes = OPTIONS.type.map(t => t.label);
        if (codes.length > 0) lg.fetchStaticTranslations(codes);
    }, [lg]);
    //#endregion

    //#region Visual
    const auto_fit = React.useMemo(() => [
        "to_data",
        "remarques",
        "add_remarque",
    ], []);
    //#endregion

    return <div className={"w-100 " + (props.className || "")}>
        <C.Spinner error={status === "error"}>
            <Table<Row<A>>
                sideBar
                ref={grid}
                rows={rows}
                enableCharts
                status={status}
                columns={columns}
                autoFit={auto_fit}
                columns_base="all"
                enableRangeSelection
                rowSelection="multiple"
                getRowId={r => r.data._id}
                onValueChange={onValueChange}
                extra_buttons={props.buttons}
                getContextMenuItems={contextMenu}
                loading={status === "load" || loading.value}
                adaptableId={props.origin || TABS.EMPLACEMENTS_TABLE}
                certification={React.useMemo(() => ({ form: formsIds[FP.EMPLACEMENT_FORM], item_id_prop: "_id", context: props.context }), [formsIds, props.context])}
            />
        </C.Spinner>
    </div>;
};

export const Emplacement = React.forwardRef(RenderEmplacement) as <A>(props: EmplacementTableProps<A> & Partial<Record<"ref", React.ForwardedRef<EmplacementTableRef<A>>>>) => React.ReactElement;

export const EmplacementContext: React.FC = () => {
    const [roots] = H.useRoots();
    H.useCrumbs(TC.BR_GP_LOCATION);
    H.useAuth({ tabName: TABS.EMPLACEMENTS_TABLE });

    return <Emplacement context={roots} origin={TABS.EMPLACEMENTS_TABLE} />;
}

//#region QuickInput Props
type EmplacementQuickInputProps = {
    /** If true, the data load must be activated through the ref */
    asyncLoad?: boolean;
    /** The context, to load the locations */
    context: T.ContextParams;
    /** Callback for submit */
    onSubmit: (equipments: T.EmplacementData[]) => void;
}
type EmplacementQuickInputRef = {
    /** Trigger the data loading, just once, won't do anything if already loaded */
    load: () => void;
    /** If locations were already loaded, reload them */
    reload_locations: () => void;
}

type BulkRowData = T.EmplacementData & { input: string };
//#endregion

const EmplacementQuickInput = React.forwardRef<EmplacementQuickInputRef, EmplacementQuickInputProps>((props, ref) => {
    const [types, setTypes, types_status] = H.useAsyncState<Awaited<ReturnType<typeof S.getEmplacementResource>>["data"]>([]);
    const [locations, setLocations, location_status] = H.useAsyncState<ReturnType<T.API.Utils.Context.GetContextOptions>>([]);

    //#region Work on options
    const locations_icons = React.useMemo(() => locations.map(l => {
        let icon = "question";
        if (l.type === FP.BUILDING_FORM) icon = "building";
        else if (l.type === FP.EMPLACEMENT_FORM && l.emplacement === "parking") icon = "parking";
        else if (l.type === FP.EMPLACEMENT_FORM) icon = "map-marker-alt";
        return { ...l, icon };
    }), [locations]);
    //#endregion

    //#region Loader
    const load_locations = React.useCallback(() => {
        S.getContextOptions({ context: props.context, add_parent: true, forms: [FP.BUILDING_FORM, FP.EMPLACEMENT_FORM] })
            .then(({ data }) => setLocations(data, "done"))
            .catch(() => setLocations([], "error"));
    }, [props.context, setLocations]);

    const load_types = React.useCallback(() => {
        S.getEmplacementResource({ type: "emplacement_type" })
            .then(({ data }) => setTypes(data, "done"))
            .catch(() => setTypes([], "error"));
    }, [setTypes]);
    //#endregion

    //#region Sync Load
    React.useEffect(() => {
        if (!props.asyncLoad) load_locations();
    }, [props.asyncLoad, load_locations]);

    React.useEffect(() => {
        if (!props.asyncLoad) load_types();
    }, [props.asyncLoad, load_types]);
    //#endregion

    //#region Ref
    React.useImperativeHandle(ref, () => ({
        reload_locations: load_locations,
        load: () => {
            if (types_status !== "done") load_types();
            if (location_status !== "done") load_locations();
        },
    }), [location_status, types_status, load_locations, load_types]);
    //#endregion

    //#region Check data validity
    const check = React.useCallback<C.QuickInputProps2<BulkRowData>["onCheck"]>(values => {
        if (!Array.isArray(values) || values.length === 0) return null;
        return values.map(v => {
            let error: T.Errors<BulkRowData> = {};

            // Check name
            if (!TB.validString(v.name)) error.name = TC.GLOBAL_REQUIRED_FIELD;
            // Check emplacement
            if (!TB.mongoIdValidator(v.input)) error.input = TC.GLOBAL_REQUIRED_FIELD;
            // Check Type
            if (!v.type) error.input = TC.GLOBAL_REQUIRED_FIELD;
            return error;
        });
    }, []);
    //#endregion

    //#region Columns
    const formats = React.useMemo<C.QuickInputColumn<BulkRowData>[]>(() => [
        {
            size: 2,
            prop: "name",
            type: "text",
            label: TC.GLOBAL_NAME,
            placeholder: TC.GLOBAL_NAME,
        },
        {
            prop: "input",
            type: "select",
            options: locations_icons,
            label: TC.BUILD_EDIT_LOCAL_FLOOR,
            loading: location_status === "load",
            error: location_status === "error" ? { code: TC.GLOBAL_FAILED_LOAD } : null,
            typeahead: {
                renderItem: (option: typeof locations_icons[number]) => <C.Flex className="w-100" alignItems="center" justifyContent="between" >
                    <i className={`fa fa-${option.icon} me-2`} ></i>
                    <span className="me-2">{option.label}</span>
                    <span className="text-muted">{option.parent}</span>
                </C.Flex>,
            }
        },
        {
            prop: "type",
            type: "select",
            options: types,
            label: TC.TYPE,
            loading: types_status === "load",
            error: types_status === "error" ? { code: TC.GLOBAL_FAILED_LOAD } : null,
        },
        {
            size: 2,
            type: "number",
            prop: "floorNumber",
            label: TC.GLOBAL_FLOOR_NUMBER,
        },
    ], [location_status, locations_icons, types, types_status]);
    //#endregion

    return <C.QuickInput2<BulkRowData>
        onCheck={check}
        columns={formats}
        onSubmit={props.onSubmit}
    />;
});