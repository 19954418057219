export { default as Options } from "./Options";
export { default as Translations } from "./translations";
export { default as ContractsTable } from "./ContractsTable";
export { default as RegActionTable } from "./RegActionTable";
export { default as ActionPlanTable } from "./ActionPlanTable";
export { default as EntityDataTable } from "./EntityDataTable";
export { default as MaintenanceGamme } from "./MaintenanceGammes";

// New Tables
export * from "./Capex";
export * from "./Gammes";
export * from "./Ticket";
export * from "./Mission";
export * from "./EmpTable";
export * from "./EquipTable";
export * from "./AlarmTable";
export * from "./AlertTable";
export * from "./NotesTable";
export * from "./InfoBubble";
export * from "./RegDocTable";
export * from "./DataCorrect";
export * from "./RemarquesTable";
export * from "./equipStoreTable";