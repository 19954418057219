export * as Form from "./Form";
export * as Reg from "./RegDoc";
export * as Dia from "./NewDia";
export * as Login from "./Login";
export * as Modal from "./Modal";
export * as Matrix from "./Matrix";
export * as Table from "./Gestion";
export * as NavBar from "./NavBar";
export * as OldForms from "./Forms";
export * as Profile from "./Profile";
export * as Formio from "./newFormio";
export * as Mission from "./Missions";
export * as Calendar from "./Calendar";
export * as Build from "./BuildEditor";
export * as Dashboard from "./Dashboard";
export * as Access from "./AccessManager";
export * as QR from "./Events_Tickets_QR";