import React from "react";
import * as M from "../BlankModal";
import * as H from "../../../hooks";
import * as C from "../../../Common";
import * as S from "../../../services";
import { T, TB, TC } from "../../../Constants";
import { Alerts } from "../MiscModal";

export type MultiLinkModalProps = {
    /** Callback for closing the modal */
    onClose: () => void;
    /** A fixed input */
    input: string;
    /** Callback for the created links */
    onCreate: (links: T.Link[]) => void;
    /** The context to search for elements in, if none provided, current context is selected */
    context?: T.ContextParams;
    /** The restriction on the types of links */
    types?: T.AllowArray<string>;
    /** Extra params to change the modal */
    modal?: M.StyleModalProps;
    /** Restriction on what types of elements is expected */
    paths?: T.AllowArray<string>;
}

type Options = ReturnType<T.API.Utils.Link.NewLinkOptions>;

const MultiLinkModal: React.FC<MultiLinkModalProps> = ({ onCreate, ...props }) => {
    const isSaving = H.useBoolean(false);
    const [context] = H.useRoots(props.context);
    const [outputs, set_outputs] = React.useState<string[]>([]);
    const [link_type, set_link_type] = React.useState<string>("");
    const [errors, set_errors] = React.useState<T.Errors<T.Link>>({});
    const [input_node, set_input_node, input_status] = H.useAsyncState<T.NodeDia>(null);
    const [options, set_options, status] = H.useAsyncState<Options>({ nodes: [], types: [], links: [] });

    React.useEffect(() => {
        let isSubscribed = true;
        S.getDiaNodes(props.input)
            .then(({ data }) => isSubscribed && set_input_node(data[0], "done"))
            .catch(() => isSubscribed && set_input_node(null, "error"));
        return () => {
            isSubscribed = false;
            set_input_node(null, "load");
        }
    }, [props.input, set_input_node]);

    React.useEffect(() => {
        let isSubscribed = true;
        S.newLinkOptions({ context, types: props.types, paths: props.paths })
            .then(({ data }) => isSubscribed && set_options(data, "done"))
            .catch(() => isSubscribed && set_options({ nodes: [], types: [], links: [] }, "error"));
        return () => {
            isSubscribed = false;
            set_options({ nodes: [], types: [], links: [] }, "load");
        }
    }, [set_options, props.types, props.paths, context]);

    const onSave = React.useCallback(() => {
        let new_errors = {} as typeof errors;

        if (outputs.length === 0) new_errors.output = TC.GLOBAL_REQUIRED_FIELD;
        if (!TB.mongoIdValidator(link_type)) new_errors.type = TC.GLOBAL_REQUIRED_FIELD;
        if (!TB.mongoIdValidator(props.input)) new_errors.input = TC.GLOBAL_REQUIRED_FIELD;

        if (Object.keys(new_errors).length > 0) set_errors(new_errors);
        else {
            isSaving.setTrue();
            S.attachNode({ children: outputs, parent: props.input, type: link_type })
                .then(links_res => onCreate(links_res.data))
                .catch(Alerts.updateError)
                .finally(isSaving.setFalse);
        }
    }, [outputs, link_type, props.input, isSaving, onCreate]);

    const status_list = React.useMemo(() => [status, input_status], [status, input_status]);
    const input_options = React.useMemo(() => [{ value: props.input, label: input_node?.title || "N/A" }], [props.input, input_node?.title]);
    const type_options = React.useMemo(() => options.types.filter(t => t.pairs.some(p => p.input === input_node?.form)), [options.types, input_node?.form]);

    const change = React.useMemo(() => ({
        type: (type: string) => {
            // Update link type
            set_link_type(type);
            // Might have to remove some outputs
            if (!type) set_outputs([]);
            else {
                let pairs = options.types.find(t => t.value === type)?.pairs || [];
                let ok_output_types = pairs.map(p => p.output);
                let new_outputs = outputs.filter(n => {
                    let node = options.nodes.find(o => o.value === n);
                    return node && ok_output_types.includes(node.form);
                });
                if (new_outputs.length !== outputs.length) set_outputs(new_outputs);
            }
        },
        output: (output: string[]) => set_outputs(output),
    }), [options, outputs]);

    const allowed_outputs = React.useMemo(() => {
        if (!link_type) return options.nodes;
        else {
            let pairs = options.types.find(t => t.value === link_type)?.pairs || [];
            let ok_output_types = pairs.map(p => p.output);

            let parents = options.links.filter(l => l.output === props.input).map(l => l.input);
            let existing_children = options.links.filter(l => l.type === link_type && l.input === props.input).map(l => l.output);
            return options.nodes.filter(n => ok_output_types.includes(n.form) && !existing_children.includes(n.value) && !parents.includes(n.value));
        }
    }, [options, link_type, props.input]);

    const footer = React.useMemo(() => <C.Button
        onClick={onSave}
        text={TC.GLOBAL_SAVE}
        icon={{ icon: "save", spinIcon: "spinner", spin: isSaving.value }}
    />, [onSave, isSaving.value]);

    return <M.default
        {...props.modal}
        footer={footer}
        onQuit={props.onClose}
        size={props.modal?.size || "md"}
        title={props.modal?.title || TC.DATASET_LINK_ADD}
    >
        <C.Spinner status={status_list}>
            <C.Form.Select
                disabled
                positionFixed
                value={props.input}
                error={errors.input}
                options={input_options}
                label={TC.DATASET_LINK_INPUT}
            />

            <C.Form.Select
                no_clear_btn
                positionFixed
                value={link_type}
                error={errors.type}
                onChange={change.type}
                options={type_options}
                label={TC.DATASET_LINK_TYPE}
            />

            <C.Form.Select
                multiple
                positionFixed
                value={outputs}
                error={errors.output}
                onChange={change.output}
                options={allowed_outputs}
                label={TC.DATASET_LINK_OUTPUT}
            />
        </C.Spinner>
    </M.default>;
}

export default MultiLinkModal;