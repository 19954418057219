import React from "react";
import * as ID from "uuid";
import * as M from "../../Modal";
import * as G from "../../Gestion";
import * as ME from "./ModalEdits";
import * as H from "../../../hooks";
import * as C from "../../../Common";
import { T, TB, MISSION, TC } from "../../../Constants";

export type RepartitionProps = {
    /** The mission's epra data */
    epra_data: ReturnType<T.API.Utils.Missions.MissionResumeEpra>["epra_data"];
    /** Callback to update the EPRA data */
    update: React.Dispatch<React.SetStateAction<ReturnType<T.API.Utils.Missions.MissionResumeEpra>["epra_data"]>>;
}

type Row = {
    /** The EPRA code of the value */
    epra_code: keyof T.Mission["epra"]["repartition"];
    /** The EPRA label of the main categories */
    epra_label: string;
    /** The name of sub category */
    category_id: string;
    /** The id of the sub category */
    category: string;
    /** The assigned categories */
    values: string[];
    /** The label of the assigned categories */
    values_label: string[];
}

const CHAPTER_LABELS = {
    dhc_abs: "DH&C",
    elec_abs: "ELEC",
    fuel_abs: "FUEL",
    waste_abs: "WASTE",
    water_abs: "WATER",
} as Record<keyof T.Mission["epra"]["categories"], string>;

const Repartition: React.FC<RepartitionProps> = ({ update, ...props }) => {
    const lg = H.useLanguage();

    //#region Events & Data update
    const events = React.useMemo(() => ({
        add_category: (epra_label: string) => {
            let epra_code = MISSION.EPRA_REPARTITION_DATA.find(c => c.label === epra_label)?.value;
            if (!epra_code) M.renderAlert({ message: TC.EPRA_FAIL_CAT_CREATION, type: "error" });
            else {
                let new_categories = props.epra_data.repartition[epra_code] || [];
                // There wasn't any category yet, so add two (One to replace the 'fake' one created in rows, and one for the one created by the press of the button)
                if (new_categories.length === 0) new_categories.push({ id: ID.v4(), name: "", sources: [] }, { id: ID.v4(), name: "", sources: [] });
                else new_categories.push({ id: ID.v4(), name: "", sources: [] });
                // Update the state
                update(p => ({ ...p, repartition: { ...p.repartition, [epra_code]: new_categories } }));
            }
        },
        edit_favorite: () => {
            ME.render_edit<ME.SavedViewsManagerProps>({ type: "views" }).then(data => {
                if (data) update(data.epra);
            });
        },
        inline_change: (event => {
            let row = event.data,
                epra_code = row.epra_code,
                new_value = event.newValue,
                field = event.colDef.field as keyof Row;

            // Check that the updated field is an updatable one
            if (field === "category") {
                // Check that the field is not left empty
                if (!TB.validString(new_value)) M.renderAlert({ message: TC.GLOBAL_REQUIRED_FIELD, type: "warning" });
                else {
                    let new_categories = props.epra_data.repartition[epra_code] || [];
                    // The user edited the default category, so we need to create a new one
                    if (new_categories.length === 0) new_categories.push({ id: ID.v4(), name: new_value, sources: [] });
                    // The user edited an existing category
                    else new_categories = new_categories.map(c => c.id === row.category_id ? { ...c, name: new_value } : c);
                    // Update the state
                    update(p => ({ ...p, repartition: { ...p.repartition, [epra_code]: new_categories } }));
                }
            }
            else if (field === "values_label") {
                let new_values = props.epra_data.repartition[epra_code] || [];
                new_values = new_values.map(c => c.id === row.category_id ? { ...c, sources: new_value } : c);
                update(p => ({ ...p, repartition: { ...p.repartition, [epra_code]: new_values } }))
            }
            // The field can't be updated
            else M.renderAlert({ message: TC.EPRA_WARNING_ONLY_CAT_TO_BE_UPDATED, type: "warning" });
        }) as G.TableProps<Row>["onValueChange"],
        remove_category: (epra_code: Row["epra_code"], cat_id: Row["category_id"]) => {
            M.askConfirm().then(confirmed => {
                if (confirmed) {
                    // Remove the category from the list of categories
                    let new_categories = (props.epra_data.repartition[epra_code] || []).filter(c => c.id !== cat_id);
                    // Update the state
                    update(p => ({ ...p, repartition: { ...p.repartition, [epra_code]: new_categories } }));
                }
            });
        },
    }), [props.epra_data, update]);
    //#endregion

    //#region Rows & Columns
    const rows = React.useMemo<Row[]>(() => {
        let rows: Row[] = [];

        const get_values_label = (sources: string[]): string[] => {
            if (sources.length === 0) return [];
            let named_sources = [] as string[];
            for (let [key, value] of Object.entries(props.epra_data.categories)) {
                let categories = value.filter(c => sources.includes(c.id));
                if (categories.length > 0) named_sources.push(...categories.map(c => `[${CHAPTER_LABELS[key]}] ${c.name}`));
            }
            return named_sources;
        };

        for (let main_cat of MISSION.EPRA_REPARTITION_DATA) {
            let sub_categories = props.epra_data.repartition?.[main_cat.value] || [];
            // No categories yet, but need something otherwise no rows will be displayed
            if (sub_categories.length === 0) rows.push({
                values: [],
                values_label: [],
                category_id: "empty",
                epra_code: main_cat.value,
                epra_label: main_cat.label,
                category: lg.getStaticText(TC.EPRA_CAT_EDIT_TIP)
            });
            // There are categories, so display them
            else for (let category of sub_categories) rows.push({
                category: category.name,
                category_id: category.id,
                epra_code: main_cat.value,
                epra_label: main_cat.label,
                values: category.sources || [],
                values_label: get_values_label(category.sources || []),
            });
        }

        return rows;
    }, [lg, props.epra_data.repartition, props.epra_data.categories]);

    const columns = React.useMemo<G.TableProps<Row>["columns"]>(() => [
        { field: "epra_label", headerName: TC.EPRA_CODE_LABEL, rowGroup: true, hide: true },
        { field: "category", headerName: TC.EPRA_CATEGORY_LABEL, editable: true },
        {
            field: "add_cat_button",
            headerName: " ",
            type: G.CellsTypes.TYPE_FREE_RENDER,
            params: {
                render: (row, field, all_props) => {
                    // Only should appear on the main categories group header
                    if (all_props.node?.group && (all_props.node?.field as keyof Row) === "epra_label") return <C.Flex className="h-100" alignItems="center" justifyContent="center">
                        <C.Button
                            size="sm"
                            icon="plus"
                            variant="link"
                            text={TC.EPRA_ADD_CAT_TITLE}
                            onClick={() => events.add_category(all_props.node.key)}
                        />
                    </C.Flex>;
                    else return null;
                }
            }
        },
        {
            field: "values_label",
            type: G.CellsTypes.TYPE_SELECT,
            headerName: TC.EPRA_REPARTITION_VALUES_LABEL,
            editable: (row) => row.data?.category_id !== "empty",
            params: {
                multiple: true,
                field_value: "values",
                getValues: row => {
                    let epra_code = row.epra_code;
                    let main_cat = MISSION.EPRA_REPARTITION_DATA.find(c => c.value === epra_code);
                    if (!main_cat) return [];
                    return main_cat.src.map(src => props.epra_data.categories[src].map(cat => ({ label: cat.name, value: cat.id }))).flat();
                },
            }
        }
    ], [events, props.epra_data.categories]);
    //#endregion

    //#region Extra buttons & Context menu
    const extra_buttons = React.useMemo<G.TableProps<Row>["extra_buttons"]>(() => [{
        onClick: events.edit_favorite,
        buttonStyle: { className: "me-2" },
        icon: { element: "<i class='fa fa-bookmark me-2'></i>" },
        label: lg.getStaticText(TC.EPRA_MISSION_TAB_BUTTON_FAVORITE),
    }], [events, lg]);

    const context_menu = React.useCallback<G.TableProps<Row>["getContextMenuItems"]>(params => {
        let row = params.node?.data as Row;
        let default_actions = params.defaultItems || [];
        let actions: ReturnType<G.TableProps<Row>["getContextMenuItems"]> = [];

        // If there is a category for the current row, add the option to remove it
        if (row?.category_id && row.category_id !== "empty") actions.unshift({
            icon: "<i class='fa fa-times'></i>",
            action: () => events.remove_category(row.epra_code, row.category_id),
            name: lg.getStaticText(TC.EPRA_CONTEXT_ACTION_REMOVE_CAT, row.category),
        });
        // Add a separator between custom and default actions
        if (actions.length > 0 && default_actions.length > 0) actions.push("separator");
        // Add the default actions
        if (default_actions.length > 0) actions.push(...default_actions);
        return actions;
    }, [events, lg]);
    //#endregion

    return <G.Table
        rows={rows}
        columns={columns}
        remove_unknown_columns
        sideBar="filters_columns"
        columns_base="all_but_edit"
        extra_buttons={extra_buttons}
        adaptableId="epra_repartition"
        getContextMenuItems={context_menu}
        onValueChange={events.inline_change}
        onReadyGrid={grid => grid?.api?.expandAll?.()}
    />
}

export default Repartition;