import Flex from "./Flex";
import React from "react";
import { T, TB, TC } from "../../Constants";
import { ErrorBanner, ErrorBannerProps } from "../Item";

export type SpinnerProps = {
    /** Should the spinner be displayed */
    loading?: boolean;
    /** What should be displayed when the loading is done */
    children?: T.AllowArray<React.ReactNode>;
    /** The size of the spinner */
    size?: "sm" | "md" | "lg";
    /** The icon that will be spinner */
    icon?: string;
    /** The type of spinning */
    type?: "spin" | "pulse" | "reverse";
    /** The color of the spinner */
    color?: T.ColorTypes;
    /** Error message or just error boolean */
    error?: boolean | string;
    /** A shortcut for the error & loading props */
    status?: T.AllowArray<T.AsyncStates>;
    /** Extra classnames */
    loader_className?: string;
    /** Do not display the spinner inside a flex container */
    no_flex_loader?: boolean;
    /** Minimum size of the loader */
    min_load_size?: string;
}

const Spinner: React.FC<SpinnerProps> = props => {

    const size = React.useMemo(() => {
        if (props.size === "lg") return { spinner: 3, banner: "md" as ErrorBannerProps["size"] };
        if (props.size === "md") return { spinner: 2, banner: "md" as ErrorBannerProps["size"] };
        return { spinner: 1, banner: "sm" as ErrorBannerProps["size"] };
    }, [props.size]);

    const spin = React.useMemo(() => {
        if (props.type === "reverse") return "spin-reverse";
        if (props.type === "pulse") return "spin-pulse";
        return "spin";
    }, [props.type]);

    const icon = React.useMemo(() => TB.getString(props.icon, "spinner"), [props.icon]);
    const color = React.useMemo(() => props.color ? "text-" + props.color : "", [props.color]);

    const status = React.useMemo<T.ExcludeArray<typeof props.status>>(() => {
        let status_list = TB.arrayWrapper(props.status);
        if (props.error || status_list.some(s => s === "error")) return "error";
        else if (props.loading || status_list.some(s => s === "load")) return "load";
        else return "done";
    }, [props.status, props.error, props.loading]);

    if (status === "done") return <>{props.children}</>;
    else if (status === "error") return <ErrorBanner size={size.banner} type="danger" textCode={TB.getString(props.error, TC.GLOBAL_FAILED_LOAD)} />;
    else if (props.no_flex_loader) return <div className={"text-center " + (props.loader_className || "")}>
        <i className={`fa fa-${icon} fa-${size.spinner}x fa-${spin} ${color}`} ></i>
    </div>
    return <Flex className={"w-100 h-100 " + (props.loader_className || "")} style={{ minHeight: props.min_load_size }} alignItems="center" justifyContent="center">
        <i className={`fa fa-${icon} fa-${size.spinner}x fa-${spin} ${color}`} ></i>
    </Flex>;
}

export default Spinner;