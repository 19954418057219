import _ from "lodash";
import React from "react";
import * as F from "../Form";
import * as M from "../Modal";
import * as H from "../../hooks";
import * as S from "../../services";
import * as BS from "react-bootstrap";
import { T, TABS, TB, TC } from "../../Constants";
import { RegDocs, RegTableRef } from "../Gestion";
import { ErrorBanner, Flex, Button, Spinner } from "../../Common";
import AutoFillReg, { UnMountRef as AutoFillRef } from "./AutoFillReg";
import RegEquipment, { UnMountRef as EquipTabRef } from "./RegEquipments";
import RegDataFiller, { UnMountRef as RegDataFillerRef } from "./RegDataFiller";

//#region Type
type RegPageProps = {
    context?: T.ContextParams;
    /** Do not allow any edits */
    read_only?: boolean;
    /** The current mission, if an EDL reg is active */
    mission_id?: string;
};
//#endregion

//#region Constants 
const TABS_MARGIN = { rem: 0.5, size: 2 };
const CONTAINER_MARGIN = { rem: 1, size: 3 };
const TABS_CONTAINER_ID = "regTabsContainer";
const TABS_CODES = { mission: "mission", missingData: "missingData", equipments: "equipments", applicable: "applicable", table: "table" };
const TABS_LABELS = { missingData: TC.REG_DATA_FILLING, equipments: TC.EQUIPMENTS, applicable: TC.REG_ACTION_APPLICABLES, table: TC.REG_TABLE_TITLE, mission: TC.CERT_HISTORY_MISSION };
const INIT_RESOURCES: T.API.Reg.GetRegTabResults = { actions: [], gammesProps: {}, elements: [], categoryTree: {}, actionConformity: {}, gammes: [], mainTree: { gammes: [], categoryTree: {} } };
//#endregion

export const RegWizard: React.FC<RegPageProps> = props => {
    const isMounted = H.useIsMounted();
    const editedRegTable = H.useBoolean(false);
    const editedElements = H.useBoolean(false);
    const updated_mission = H.useBoolean(false);
    const editedRegAffect = H.useBoolean(false);
    const regTableRef = React.useRef<RegTableRef>(null);
    const { fetchStaticTranslations } = H.useLanguage();
    const equipTabRef = React.useRef<EquipTabRef | null>(null);
    const autoFillRef = React.useRef<AutoFillRef | null>(null);
    const navBarContainerRef = H.usePortal("topNavBarContainer");
    const regDataRef = React.useRef<RegDataFillerRef | null>(null);
    const mission_ref = React.useRef<F.Missions.MissionFormRef>(null);
    const [navBarContainerRefForSize, navBarSize] = H.useElementSize();
    const [tabsContainerRef, tabsSize] = H.useElementSize<HTMLElement>();
    const [activeTab, setActiveTab] = React.useState(props.mission_id ? TABS_CODES.mission : TABS_CODES.missingData);
    const [resources, setResources, resourcesStatus, setResourcesStatus] = H.useAsyncState<T.API.Reg.GetRegTabResults, "invisible">(INIT_RESOURCES);

    //#region Load Resources
    const fetchResources = React.useCallback(() => {
        return S.getRegTabResources(props.context)
            .then(({ data }) => isMounted() && setResources(data, "done"))
            .catch(() => isMounted() && setResourcesStatus("error"));
    }, [isMounted, setResourcesStatus, setResources, props.context]);

    React.useEffect(() => {
        fetchResources();
        return () => setResources(INIT_RESOURCES, "load");
    }, [fetchResources, setResources]);

    const resourcesBanner = React.useMemo(() => {
        switch (resourcesStatus) {
            case "load": return <M.Loader />;
            case "error": return <ErrorBanner type="danger" textCode={TC.GLOBAL_FAILED_LOAD} />;
            default: return null;
        }
    }, [resourcesStatus]);
    //#endregion

    //#region Crumbs
    const confirmSaveBeforeQuit = React.useCallback(() => M.askConfirm({
        noText: TC.ANNOT_NO_SAVE,
        text: TC.SCH_SAVE_BEFORE_QUIT,
        title: TC.GLOBAL_SAVE_BEFORE_QUIT,
        yesText: TC.GLOBAL_SAVE_AND_QUITE,
    }), []);

    const closePreviousTab = React.useCallback(() => new Promise<void>((resolve, reject) => {
        let regTableChanged = activeTab === TABS_CODES.table && editedRegTable.value;
        let save_mission = activeTab === TABS_CODES.mission && updated_mission.value;
        let equipTableChanged = activeTab === TABS_CODES.equipments && equipTabRef.current?.hasChanged;
        let saveData = activeTab === TABS_CODES.missingData && regDataRef.current?.askSave && !!regDataRef.current.save;
        let saveAttribution = activeTab === TABS_CODES.applicable && autoFillRef.current?.askSave && !!autoFillRef.current.save;

        if (saveData) confirmSaveBeforeQuit().then(confirmed => {
            if (confirmed) regDataRef.current.save().then(() => resolve()).catch(() => reject());
            else resolve();
        });
        else if (saveAttribution) confirmSaveBeforeQuit().then(confirmed => {
            if (confirmed) autoFillRef.current.save().then(() => resolve()).catch(() => reject());
            else resolve();
        });
        else if (save_mission) M.askConfirm({ title: TC.MISSION_WIZARD_AUTO_SAVE_MISSION, text: TC.MISSION_WIZARD_AUTO_SAVE_MISSION_TEXT }).then(confirmed => {
            if (confirmed) {
                let result = mission_ref.current?.save?.();
                if (result && result !== "errors") result.then(success => {
                    if (success) {
                        updated_mission.setFalse();
                        resolve();
                    }
                });
                else reject();
            }
            else reject();
        });
        else if (regTableChanged || equipTableChanged) {
            let dismount = M.renderLoader(TC.REG_LOAD_RECALCULATE);
            fetchResources()
                .then(() => equipTabRef.current?.reset?.())
                .finally(() => {
                    dismount?.();
                    resolve();
                });
        }
        else resolve();

    }), [confirmSaveBeforeQuit, fetchResources, activeTab, editedRegTable.value, updated_mission]);

    const dealWithNewTab = React.useCallback((tab: string) => new Promise<void>(resolve => {
        if ((tab === TABS_CODES.applicable || tab === TABS_CODES.equipments) && editedElements.ref.current) {
            let dismount = M.renderLoader(TC.REG_LOAD_RECALCULATE);
            fetchResources()
                .then(() => editedElements.setFalse())
                .finally(() => {
                    dismount?.();
                    resolve();
                });
        }
        else if (tab === TABS_CODES.table && editedRegAffect.ref.current && regTableRef.current?.reload) {
            let dismount = M.renderLoader(TC.REG_LOAD_RECALCULATE);
            regTableRef.current.reload()
                .then(() => editedRegAffect.setFalse())
                .finally(() => {
                    dismount?.();
                    resolve();
                });
        }
        else resolve();
    }), [editedElements, editedRegAffect, fetchResources]);

    const onChangeTab = React.useCallback((tab: string) => {
        closePreviousTab()
            .then(() => dealWithNewTab(tab).then(() => setActiveTab(tab)))
            // Cancel the opening of the next tab
            .catch(() => { });
    }, [closePreviousTab, dealWithNewTab]);
    //#endregion

    //#region Table Reg Height
    React.useEffect(() => {
        let htmlNode = document.getElementById(TABS_CONTAINER_ID)?.childNodes?.[0] || null;
        /* @ts-ignore */
        tabsContainerRef(htmlNode);
    });

    React.useEffect(() => navBarContainerRefForSize(navBarContainerRef.current));

    const tableContainerHeight = React.useMemo(() => {
        let height = TB.getVH() - navBarSize.height - TB.remToPx(TABS_MARGIN.rem) - TB.remToPx(CONTAINER_MARGIN.rem) - tabsSize.height;
        if (height < 0) return 0;
        return height;
    }, [tabsSize.height, navBarSize.height]);
    //#endregion

    //#region Save
    const saveDataFiller = React.useCallback((changes: T.DataChange[]) => {
        let updatePromise = S.applyDataChange(changes);

        updatePromise.then(() => {
            editedElements.setTrue();
            setResources(p => {
                let updates = _.groupBy(changes, "_id");

                let elements = p.elements.map(e => {
                    let changes = updates[e.submission._id];
                    if (Array.isArray(changes)) {
                        let elem = _.cloneDeep(e);
                        changes.forEach(c => _.set(elem, "submission.data." + c.prop, c.value));
                        return elem;
                    }
                    return e;
                });

                return { ...p, elements };
            });
        })
            .catch(() => M.renderAlert({ type: "error", message: TC.GLOBAL_ERROR_UPDATE }));

        return updatePromise;
    }, [setResources, editedElements]);

    const saveAutoFill = React.useCallback((changes: Parameters<T.API.Reg.RegSwitch>[0]) => {
        let updatePromise = S.switchReg(changes);

        updatePromise.then(() => {
            // Only relevant if the table has been mounted yet
            if (typeof regTableRef.current?.reload === "function") editedRegAffect.setTrue();
            setResources(p => {
                let updates = _.groupBy(changes, c => c.elem);

                let elements = p.elements.map(e => {
                    let elemUpdates = updates[e.submission._id];
                    if (Array.isArray(elemUpdates)) {
                        let regArray = TB.getArray<T.ActionValueItem>(e.submission.data.reglementations);

                        elemUpdates.forEach(c => {
                            if (c.isSet && regArray.filter(a => a.action === c.reg).length === 0) regArray = regArray.concat({ action: c.reg });
                            else if (!c.isSet) regArray = regArray.filter(a => a.action !== c.reg);
                        });

                        let clone = _.cloneDeep(e);
                        _.set(clone, "submission.data.reglementations", regArray);
                        return clone;
                    }
                    return e;
                });

                return { ...p, elements };
            });
        })
            .catch(() => M.renderAlert({ type: "error", message: TC.GLOBAL_ERROR_UPDATE }));
        return updatePromise;
    }, [editedRegAffect, setResources]);
    //#endregion

    //#region Language
    React.useEffect(() => {
        let codes = Object.values(TABS_LABELS).filter(TB.isTextCode);
        fetchStaticTranslations(codes);
    }, [fetchStaticTranslations]);
    //#endregion

    //#region Update Elements
    const updateElements = React.useCallback(setResources, [setResources]);
    //#endregion

    return <Flex id={TABS_CONTAINER_ID} direction="column" className={`flex-grow-1 mb-${CONTAINER_MARGIN.size}`}>

        {resourcesBanner}

        <BS.Row className="g-1 pb-2">
            {Object.entries(TABS_CODES).map(([key, value]) => (value !== TABS_CODES.mission || props.mission_id) && <BS.Col key={key}>
                <Button
                    size="sm"
                    className="w-100"
                    text={TABS_LABELS[key]}
                    onClick={() => onChangeTab(value)}
                    variant={activeTab === value ? "primary" : "link"}
                />
            </BS.Col>)}
        </BS.Row>

        <Spinner status={resourcesStatus === "invisible" ? "done" : resourcesStatus}>

            {activeTab === TABS_CODES.mission && <F.Missions.MissionForm
                no_delete
                ref={mission_ref}
                mission_id={props.mission_id}
                asset={props.context?.roots?.[0]}
                onSave={updated_mission.setFalse}
                onChange={updated_mission.setTrue}
            />}

            {activeTab === TABS_CODES.missingData && <RegDataFiller
                api={regDataRef}
                resources={resources}
                save={saveDataFiller}
                context={props.context}
                reload={fetchResources}
                read_only={props.read_only}
            />}

            {activeTab === TABS_CODES.equipments && <RegEquipment
                api={equipTabRef}
                resources={resources}
                context={props.context}
                read_only={props.read_only}
                updateElements={updateElements}
                containerSize={tableContainerHeight}
            />}

            {activeTab === TABS_CODES.applicable && <AutoFillReg
                resources={resources}
                read_only={props.read_only}
                save={saveAutoFill} api={autoFillRef}
            />}

            {activeTab === TABS_CODES.table && <Flex style={{ height: tableContainerHeight + 'px' }}>
                <RegDocs
                    ref={regTableRef}
                    context={props.context}
                    hide_buttons="doc_type"
                    read_only={props.read_only}
                    hasChanged={editedRegTable.setTrue}
                />
            </Flex>}
        </Spinner>
    </Flex>
}

export const RegPage: React.FC = () => {
    H.useCrumbs(TC.CRUMB_REG);
    const [roots] = H.useRoots();
    H.useAuth({ tabName: TABS.REG_WIZARD });
    return <RegWizard context={roots} />;
};