import React from "react";
import * as H from "../../../hooks";
import * as C from "../../../Common";
import * as S from "../../../services";
import { T, TC } from "../../../Constants";

export type CoverageProps = {
    /** The list of datasets to take into the coverage */
    datasets: string[];
    /** The periods to consider */
    periods: Record<"from" | "to", string>;
    /** The epra_code used */
    epra_code: keyof T.Mission["epra"]["categories"];
}

type CoverageData = ReturnType<T.API.Utils.Missions.GetCoverage>;

const Coverage: React.FC<CoverageProps> = props => {
    const lg = H.useLanguage();
    const [coverage, set_coverage, status] = H.useAsyncState<CoverageData>({ covered_area: NaN, total_area: NaN, value: NaN, percentage: NaN });

    React.useEffect(() => {
        let period = [props.periods?.from || "", props.periods?.to || ""].join();
        if (!Array.isArray(props.datasets) || props.datasets.length === 0) set_coverage({ covered_area: NaN, total_area: NaN, value: NaN, percentage: NaN }, "done");
        else S.getCoverage({ datasets: props.datasets.join(), period, epra_code: props.epra_code })
            .then(({ data }) => set_coverage(data, "done"))
            .catch(e => set_coverage({ covered_area: NaN, total_area: NaN, value: NaN, percentage: NaN }, "error"))
    }, [set_coverage, props.datasets, props.epra_code, props.periods]);

    const texts = React.useMemo(() => ({
        value: `${lg.getStaticText(TC.EPRA_COVERAGE_LABEL)} : ${coverage.value ?? "NaN"}`,
        coverage: `${lg.getStaticText(TC.EPRA_VALUE_LABEL)} : ${coverage.percentage ?? "NaN"}% ( ${coverage.covered_area} m² / ${coverage.total_area} m² )`,
    }), [lg, coverage]);

    return <C.Spinner status={status}>
        <div className="text-start">
            <div children={texts.value} />
            <div children={texts.coverage} />
        </div>
    </C.Spinner>
}

export default Coverage;