import * as C from "../Common";
import * as S from "../services";
import * as PM from "../PurposeModal";
import * as M from "../Components/Modal";
import { FP, T, TB } from "../Constants";
import { store as async_store } from "../Redux";

//#region Types
/** The function to import a file of new equipments. Catch sends back, argument for a Modal ALert */
type Import = (params?: {
    /** The context to restraint the selection of building from */
    context?: T.ContextParams;
}) => Promise<"cancelled" | "empty" | Record<"rems", T.Submission<T.RemarqueDefault>[]> & Record<"tickets", T.Submission<T.TicketData>[]>>;

type ImportElem = {
    /** The way  to retrieve the equipment the ticket / remarque is linked to */
    code: string;
    /** The criticity of the remarque, on a scale from 1 to 6 */
    crit: number;
    /** The OVAT cost of the action */
    cost: number;
    /** A description for the remarque */
    rem_desc: string;
    /** A description for the action */
    action_desc: string;
    /** The title of the action */
    action_title: string;
    /** The year at which the action should be performed */
    action_start: number;
}//#endregion

const ticket_remarque_import: Import = params => new Promise((resolve, reject) => {
    async_store.then(store => {

        //#region Find Root Building
        const building_root_promise = new Promise<string>(resolve => {
            if (params?.context) PM.renderBuildingSelect({ isRequired: true, context: params.context })
                .then(build => resolve(build));
            else M.renderSearchContext({ searchedType: "building" })
                .then(pick => resolve(pick?.id));
        });
        //#endregion

        //#region Languages
        const redux_state = store.getState(),
            translations = redux_state.storedLanguages,
            lang = localStorage.getItem("lang") || "fr_fr" as T.LanguageProps,
            ticket_form = redux_state.formIds.filter(f => f.path === FP.TICKET_FORM)[0]?._id,
            rem_form = redux_state.formIds.filter(f => f.path === FP.REMARQUES_DEFAULTS)[0]?._id;

        const rem_translations = translations.object.filter(t => t._id === rem_form),
            ticket_translations = translations.object.filter(t => t._id === ticket_form);

        const col_name = (property: string, is_ticket = false): string => {
            let form_translations = (is_ticket ? ticket_translations : rem_translations);
            let prop_tr = form_translations.filter(t => t.prop === property)[0];
            if (prop_tr) return prop_tr[lang] || property;
            return translations.static.filter(tr => tr.ref === property)[0]?.[lang] || property;
        }
        //#endregion

        building_root_promise.then(building => {
            if (!TB.mongoIdValidator(building)) resolve("cancelled");
            else {
                // Create the structure of what to import
                let columns_format = {
                    code: { label: "Code", type: "string", key: "F" },
                    crit: { label: col_name("criticity"), type: "number", key: "AD" },
                    cost: { label: col_name("cost", true), type: "number", key: "AC" },
                    rem_desc: { label: col_name("description"), type: "string", key: "U" },
                    action_title: { label: col_name("title", true), type: "string", key: "V" },
                    action_start: { label: col_name("start", true), type: "number", key: "X" },
                    action_desc: { label: col_name("description", true), type: "string", key: "W" },
                } as T.ImportFormat<keyof ImportElem>;
                // Set the params for the file import and the mapping of the properties
                const mapper_params = {
                    required: true,
                    columnFormat: columns_format,
                    save: "ticket_remarque_import",
                    title: "TODO Importation de remarques et de ticket",
                } as Parameters<typeof M.renderExcelMapper>[0];
                // File import + user mapping
                M.renderExcelMapper(mapper_params).then((rows: ImportElem[]) => {
                    if (!rows) resolve("cancelled");
                    else if (rows.length === 0) resolve("empty");
                    else {
                        let ignored_list = [] as C.Import.ValueIgnorerProps['values'];

                        const add_to_list = (prop: string, value: string) => {
                            // Pair prop/value not already in the list
                            if (!ignored_list.some(i => i.prop === prop && i.value === value)) {
                                let label = columns_format[prop]?.label || prop;
                                ignored_list.push({ prop, value, label, ignored: true });
                            }
                        }
                        // Check for potentially ignorable values
                        for (let row of rows) {
                            for (let [prop, value] of Object.entries(row)) {
                                // Check for parasite values (ex: - / x ...)
                                if (typeof value === "string" && value.length > 0) {
                                    // 1 char long non-word character
                                    if (value.length === 1 && value.match(/\W/g)) add_to_list(prop, value);
                                }
                            }
                        }
                        // Ask the user to determine which values are 'parasites'
                        M.renderValueIgnorer({ values: ignored_list }).then(ignored => {
                            if (!ignored) resolve("cancelled");
                            else {
                                // Remove the parasite values from the rows
                                for (let row of rows) {
                                    for (let i of ignored) {
                                        if (row[i.prop] === i.value) delete row[i.prop];
                                    }
                                }
                                // Load the equipments, to be able to link the ticket / remarque to them
                                S.getEquipmentRows({ context: { roots: building } }).then(equipments => {
                                    // Load the criticity levels
                                    S.getCriticityLevels().then(criticity_levels => {
                                        // Create the remarques
                                        let new_remarques = [] as T.RemarqueDefault[];

                                        for (let row of rows) {
                                            // If there is a need to create a remarque
                                            if (row.rem_desc) {
                                                let equipment = equipments.data.find(e => e.code === String(row.code));
                                                if (equipment) {
                                                    let criticity_score = TB.getNumber(row.crit, 1) / 2;
                                                    let criticity = criticity_levels.data.low;

                                                    if (criticity_score > 2) criticity = criticity_levels.data.high;
                                                    else if (criticity_score > 1) criticity = criticity_levels.data.medium;

                                                    // Create a remarques
                                                    new_remarques.push({
                                                        criticity,
                                                        status: "open",
                                                        costType: undefined,
                                                        element: equipment._id,
                                                        description: row.rem_desc,
                                                        date: new Date().toISOString(),
                                                        elementCriticity: equipment.criticity,
                                                        deadline: criticity_score > 2 ? "1W" : criticity_score > 1 ? "1M" : "3M",
                                                    });
                                                }
                                            }
                                        }

                                        S.createSubmission({ path: FP.REMARQUES_DEFAULTS, submission: new_remarques }).then(remarques_data => {
                                            const remarques = remarques_data.data.submissions as T.Submission<T.RemarqueDefault>[];

                                            let new_tickets = [] as T.TicketData[];
                                            for (let row of rows) {
                                                let equipment = equipments.data.find(e => e.code === String(row.code));
                                                if (equipment) {
                                                    let year_start: string;
                                                    let cost = TB.getNumber(row.cost);
                                                    if (typeof row.action_start === "number") year_start = new Date("01/01/" + row.action_start).toISOString();
                                                    let remarque = remarques.find(s => s.data.element === equipment._id && s.data.description === row.rem_desc);

                                                    // Create an action
                                                    new_tickets.push({
                                                        capex: true,
                                                        internal: false,
                                                        type: undefined,
                                                        start: year_start,
                                                        remarque: remarque?._id,
                                                        title: row.action_title,
                                                        equipment: equipment._id,
                                                        tags: ["660d300e2869404ba303be52"],
                                                        description: row.action_desc || "",
                                                        cost: isNaN(cost) ? undefined : cost,
                                                    });
                                                }
                                            }

                                            S.createSubmission({ path: FP.TICKET_FORM, submission: new_tickets }).then(ticket_data => {
                                                console.log({ remarques, tickets: ticket_data.data.submissions });
                                                resolve({ rems: remarques, tickets: ticket_data.data.submissions as T.Submission<T.TicketData>[] });
                                            }).catch(reject);
                                        }).catch(reject);
                                    }).catch(reject);
                                }).catch(reject);
                            }
                        });
                    }
                });
            }
        })
    }).catch(reject);
});

export default ticket_remarque_import;