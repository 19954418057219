import React from "react";
import * as M from "../../Modal";
import * as H from "../../../hooks";
import * as C from "../../../Common";
import * as BS from "react-bootstrap";
import * as S from "../../../services";
import { FP, T, TB, TC } from "../../../Constants";

//#region Types
export type RentProps = {
    /** The id of the cell (emplacement) */
    origin: string;
    /** Should the form be in a popup ? */
    popup?: boolean;
    /** Extra modal style params */
    modal?: M.StyleModalProps;
    /** Callback for quitting */
    onQuit?: () => void;
}

type Data = ReturnType<T.API.Utils.Rent.GetRentInfo>;
type Options = ReturnType<T.API.Utils.Rent.GetRenterOptions>;
//#endregion

const Rent: React.FC<RentProps> = props => {
    const lg = H.useLanguage();
    const [forms_ids] = H.useFormIds();
    const [data, set_data, status] = H.useAsyncState<Data>({ bails: [], emplacement: null });
    const [options, set_options, options_status] = H.useAsyncState<Options>({ enterprise: [], enseigne: [] });

    React.useEffect(() => {
        let isSubscribed = true;
        if (props.origin) S.getRentInfo(props.origin)
            .then(({ data }) => isSubscribed && set_data(data, "done"))
            .catch(() => isSubscribed && set_data({ bails: [], emplacement: null }, "error"));
        else set_data({ bails: [], emplacement: null }, "error");
        return () => {
            isSubscribed = false;
            set_data({ bails: [], emplacement: null }, "load");
        };
    }, [props.origin, set_data]);

    React.useEffect(() => {
        let isSubscribed = true;
        S.getRenterOptions()
            .then(({ data }) => isSubscribed && set_options(data, "done"))
            .catch(() => isSubscribed && set_options({ enterprise: [], enseigne: [] }, "error"));
        return () => {
            isSubscribed = false;
            set_options({ enterprise: [], enseigne: [] }, "load");
        }
    }, [set_options]);

    const forms = React.useMemo(() => ({
        bail: forms_ids[FP.BAIL_FORM],
        emp: forms_ids[FP.EMPLACEMENT_FORM],
    }), [forms_ids]);

    const bails_list = React.useMemo(() => {
        if (data.bails.some(b => b._id === "")) return data.bails;
        else return data.bails.concat({ _id: "", enseigne: "", enseigne_name: "", renter: "", renter_name: "" });
    }, [data.bails]);

    const get = React.useMemo(() => ({
        show_franchised: (renter_id: string) => options.enterprise.some(e => e.value === renter_id),
        franchised: (renter_id: string) => options.enterprise.find(e => e.value === renter_id)?.franchised || false,
        renters: (bail_id?: string) => {
            let bail = data.bails.find(b => b._id === bail_id);
            if (options.enterprise.some(e => e.value === bail?.renter)) return options.enterprise;
            else return options.enterprise.concat({ value: bail?.renter || "", label: bail?.renter_name || "", franchised: false });
        },
        enseignes: (bail_id?: string) => {
            let bail = data.bails.find(b => b._id === bail_id);
            if (options.enseigne.some(e => e.value === bail?.enseigne)) return options.enseigne;
            else return options.enseigne.concat({ value: bail?.enseigne || "", label: bail?.enseigne_name || "", enterprises: [] });
        },
    }), [options, data.bails]);

    const updates = React.useMemo(() => ({
        set_renter: (renter_id: string, bail_id: string) => {
            let bail = data.bails.find(b => b._id === bail_id);
            let renter_name = options.enterprise.find(e => e.value === renter_id)?.label || "";

            // Update an existing bail
            if (bail_id) S.updateBailRenter({ bail: bail_id, enseigne: bail.enseigne, new_renter: renter_id, old_renter: bail.renter })
                .then(() => set_data(p => ({ ...p, bails: p.bails.map(b => b._id === bail_id ? { ...b, renter: renter_id, renter_name } : b) })))
                .catch(M.Alerts.updateError);
            // Only update locally, waiting for the enseigne to be selected
            else set_data(p => ({
                ...p,
                bails: p.bails.some(b => b._id === "")
                    ? p.bails.map(b => b._id === "" ? { ...b, renter: renter_id, renter_name } : b)
                    : p.bails.concat({ _id: "", enseigne: "", renter: renter_id, enseigne_name: "", renter_name }),
            }));
        },
        set_enseigne: (enseigne_id: string, bail_id: string) => {
            let bail = data.bails.find(b => b._id === bail_id);
            let enseigne_name = options.enseigne.find(e => e.value === enseigne_id)?.label || "";

            // Create a new bail
            if (!bail_id) S.createSubmission({ path: FP.BAIL_FORM, submission: {} as T.BailData }).then(({ data }) => {
                let new_bail_id = data.submissions?.[0]?._id;
                S.linkBail({ bail: new_bail_id, cell: props.origin, enseigne: enseigne_id, renter: bail.renter })
                    .then(() => set_data(p => ({
                        ...p,
                        bails: p.bails.map(b => b._id === bail_id ? { ...b, _id: new_bail_id, enseigne: enseigne_id, enseigne_name } : b),
                    })))
                    .catch(M.Alerts.updateError);
            }).catch(M.Alerts.updateError);
            // Update an existing bail
            else S.updateBailEnseigne({ renter: bail.renter, new_enseigne: enseigne_id, old_enseigne: bail.enseigne })
                .then(() => set_data(p => ({ ...p, bails: p.bails.map(b => b._id === bail_id ? { ...b, enseigne: enseigne_id, enseigne_name } : b) })))
                .catch(M.Alerts.updateError);
        },
        create_renter: (name: string) => {
            S.createSubmission({ path: FP.CLIENT_FORM, submission: { name } }).then(client => {
                set_options(p => ({ ...p, enterprise: p.enterprise.concat({ value: client.data.submissions?.[0]?._id, label: name, franchised: false }) }))
            }).catch(M.Alerts.updateError);

        },
        emp_rentable: (value: boolean) => {
            /*  // Cell is no longer rentable, must delete the bail (if there is one)
             if (!value && data.bails.length > 0) M.askConfirm({ text: TC.BAIL_MANAGER_NO_LONGER_RENTABLE }).then(() => {
                 S.removeBail({ bail: data.bail._id, renter: data.bail.renter, enseigne: data.bail.enseigne }).then(() => {
                     set_data(p => ({ ...p, bail: undefined, emplacement: { ...p.emplacement, isRentable: false } }));
                     was_edited.setTrue();
                 }).catch(M.Alerts.deleteError);
             });
             else */
            S.update_emplacement_field({ field: "isRentable", new_value: value, _id: props.origin, force_update: true, old_value: data.emplacement.isRentable })
                .then(() => set_data(p => ({ ...p, emplacement: { ...p.emplacement, isRentable: value } })))
                .catch(M.Alerts.updateError);
        },
        create_enseigne: (name: string, bail_id: string) => {
            let bail = data.bails.find(b => b._id === bail_id);
            let enseigne: T.EnseigneData = { name, enterprises: [bail.renter].filter(TB.mongoIdValidator), color: "#f5454a" };

            S.createSubmission({ path: FP.ENSEIGNE_FORM, submission: enseigne }).then(({ data }) => {
                set_options(p => ({
                    ...p,
                    enseigne: p.enseigne.concat({ value: data.submissions?.[0]?._id, label: enseigne.name, enterprises: enseigne.enterprises })
                }));
            }).catch(M.Alerts.updateError);
        },
        franchised_renter: (renter_id: string, value: boolean) => {
            S.updateRenterFranchised({ renter: renter_id, franchised: value })
                .then(() => set_options(p => ({ ...p, enterprise: p.enterprise.map(e => e.value === renter_id ? { ...e, franchised: !!value } : e) })))
                .catch(M.Alerts.updateError);
        },
        delete_bail: (bail_id: string) => {
            let bail = data.bails.find(b => b._id === bail_id);
            M.askConfirm().then(confirmed => {
                if (confirmed) S.removeBail({ bail: bail._id, renter: bail.renter, enseigne: bail.enseigne })
                    .then(() => set_data(p => ({ ...p, bails: p.bails.filter(b => b._id !== bail_id) })))
                    .catch(M.Alerts.deleteError);
            });
        },
        bail_field: (bail_id: string, field: keyof T.BailData, value: any) => {
            let bail = data.bails.find(b => b._id === bail_id);
            S.update_bail_field({ field, new_value: value, _id: bail_id, force_update: true, old_value: bail[field] })
                .then(() => set_data(p => ({ ...p, bails: p.bails.map(b => b._id === bail_id ? { ...b, [field]: value } : b) })))
                .catch(M.Alerts.updateError);
        },
    }), [data.bails, data.emplacement?.isRentable, props.origin, options, set_data, set_options]);

    return React.createElement(
        props.popup ? M.BlankModal : React.Fragment,
        props.popup ? {
            ...props.modal,
            onQuit: props.onQuit,
            size: props.modal?.size || "lg",
            title: props.modal?.title || TC.BAIL_MANAGER,
        } : null,
        <C.Spinner status={status}>

            <C.Title level={3} children={TC.GLOBAL_LABEL_CELLS} />
            <BS.Row>
                <BS.Col md={4}>
                    <C.Form.TextField
                        disabled
                        label={TC.GLOBAL_EMP_NAME}
                        value={data?.emplacement?.name}
                    />
                </BS.Col>
                <BS.Col md={4}>
                    <C.Form.RadioBool
                        name="emp_rent"
                        labelPosition="top"
                        value={data?.emplacement?.isRentable}
                        onChange={v => updates.emp_rentable(v)}
                        tooltip={TC.BAIL_MANAGER_MUST_BE_RENTABLE}
                        label={{ prop: "isRentable", _id: forms.emp }}
                    />
                </BS.Col>
            </BS.Row>

            <C.Title level={3} children={TC.BAILS_TITLE} />
            {bails_list.map(bail => <div key={bail._id || "new"} className="border p-2 mb-2">

                {bail._id
                    ? <C.Flex alignItems="center" justifyContent="between">
                        <C.Title level={4} text={lg.getStaticText(TC.RENT_FORM_BAIL_FOR, bail.renter_name)} />
                        <C.Button
                            size="sm"
                            icon="times"
                            variant="danger"
                            disabled={!bail._id}
                            text={TC.BAIL_MANAGER_REMOVE_BAIL}
                            onClick={() => updates.delete_bail(bail._id)}
                        />
                    </C.Flex>
                    : <C.Title icon="plus" level={4} text={TC.RENT_FORM_NEW_BAIL} />}

                <BS.Row>
                    <BS.Col md={5}>
                        <C.Form.Select
                            no_clear_btn
                            value={bail.renter}
                            options={get.renters(bail._id)}
                            loading={options_status === "load"}
                            label={TC.BAIL_MANAGER_RENTER_LABEL}
                            onChange={r => updates.set_renter(r, bail._id)}
                            description={bail._id ? "" : TC.BAIL_FORM_CREATION_CONDITIONS}
                            typeahead={{ allowNewOption: true, onAddOption: updates.create_renter }}
                        />
                    </BS.Col>
                    <BS.Col>
                        <C.Form.RadioBool
                            labelPosition="top"
                            name={bail._id || "_new"}
                            value={get.franchised(bail.renter)}
                            label={TC.BAIL_MANAGER_IS_FRANCHISED}
                            disabled={!bail._id || !get.show_franchised(bail.renter)}
                            onChange={v => updates.franchised_renter(bail.renter, v)}
                        />
                    </BS.Col>
                    <BS.Col md={5}>
                        <C.Form.Select
                            no_clear_btn
                            value={bail.enseigne}
                            disabled={!bail.renter}
                            options={get.enseignes(bail._id)}
                            loading={options_status === "load"}
                            label={TC.BAIL_MANAGER_ENSEIGNE_LABEL}
                            onChange={e => updates.set_enseigne(e, bail._id)}
                            typeahead={{ allowNewOption: true, onAddOption: text => updates.create_enseigne(text, bail._id) }}
                        />
                    </BS.Col>
                </BS.Row>
                <BS.Row>
                    <BS.Col>
                        <C.Form.DateTime
                            value={bail.start}
                            disabled={!bail._id}
                            label={{ prop: "start", _id: forms.bail }}
                            onChange={v => updates.bail_field(bail._id, "start", v)}
                        />
                    </BS.Col>
                    <BS.Col>
                        <C.Form.DateTime
                            value={bail.end}
                            disabled={!bail._id}
                            label={{ prop: "end", _id: forms.bail }}
                            onChange={v => updates.bail_field(bail._id, "end", v)}
                        />
                    </BS.Col>
                    <BS.Col>
                        <C.Form.NumField
                            value={bail.break}
                            disabled={!bail._id}
                            label={{ prop: "break", _id: forms.bail }}
                            onChange={v => updates.bail_field(bail._id, "break", v)}
                        />
                    </BS.Col>
                    <BS.Col>
                        <C.Form.TextField
                            disabled={!bail._id}
                            value={bail.loadDistribution}
                            label={{ prop: "loadDistribution", _id: forms.bail }}
                            onChange={v => updates.bail_field(bail._id, "loadDistribution", v)}
                        />
                    </BS.Col>
                </BS.Row>
            </div>)}
        </C.Spinner>
    );
}

export default Rent;