import { AxiosPromise } from "../mongo";

export * as Reg from "./Reg";
export * as EDL from "./EDL";
export * as Form from "./Form";
export * as Utils from "./Utils";
export * as Graph from "./Graphs";
export * as Access from "./Access";
export * as Report from "./Report";
export * as Actions from "./Action";
export * as Geo from "./Geographic";

export type Token = {
    exp?: number;
    iat?: number;
    isAdmin?: boolean;
    user?: { _id: string };
    form?: { _id: string };
}

export type ToPromise<A extends (...args: any) => any> = Parameters<A>[0] extends undefined
    ? Parameters<A>[1] extends undefined
        ? () => Promise<ReturnType<A>>
        : Parameters<A>[2] extends undefined
            ? (param: undefined, paramB: Parameters<A>[1]) => Promise<ReturnType<A>>
            : (param:undefined, paramB: Parameters<A>[1], paramC: Parameters<A>[2], paramD?: Parameters<A>[3]) => Promise<ReturnType<A>>
    : (param: Parameters<A>[0], paramB?: Parameters<A>[1], paramC?: Parameters<A>[2], paramD?: Parameters<A>[3]) => Promise<ReturnType<A>>;

export type ToAxios<A extends (...args: any) => any> = Parameters<A>[0] extends undefined
    ? Parameters<A>[1] extends undefined
        ? () => AxiosPromise<ReturnType<A>>
        : Parameters<A>[2] extends undefined
            ? (param: undefined, paramB: Parameters<A>[1]) => AxiosPromise<ReturnType<A>>
            : (param:undefined, paramB: Parameters<A>[1], paramC: Parameters<A>[2], paramD?: Parameters<A>[3]) => AxiosPromise<ReturnType<A>>
    : (param: Parameters<A>[0], paramB?: Parameters<A>[1], paramC?: Parameters<A>[2], paramD?: Parameters<A>[3]) => AxiosPromise<ReturnType<A>>;