import React from "react";
import * as ID from "uuid";
import * as M from "../../Modal";
import * as H from "../../../hooks";
import * as C from "../../../Common";
import * as BS from "react-bootstrap";
import * as S from "../../../services";
import { T, TB, TC } from "../../../Constants";

//#region Types
export type CategoryEditProps = {
    /** The pre-existing categories */
    categories?: T.Mission["epra"]["datasource"];
    /** Callback to handle the changes in the categories */
    onChange: (categories: T.Mission["epra"]["datasource"]) => void;
};

export type PeriodEditProps = {
    /** The pre-existing periods */
    periods?: T.Mission["epra"]["periods"];
    /** Callback to handle the changes in the periods */
    onChange: (periods: T.Mission["epra"]["periods"]) => void;
}

export type SavedViewsManagerProps = {
    /** Callback to handle the changes in the periods */
    onChange: (view?: T.EpraView) => void;
    /** The current EPRA configuration */
    epra?: T.Mission["epra"];
}

type EpraSettings = ReturnType<T.API.Utils.Missions.GetEpraViewsSettings>;
//#endregion

/** 
 * Shortcut to render the edit popups
 */
export const render_edit = <P extends Record<"onChange", (...args: any) => any>>(params: { type: "asset" | "period" | "views" } & Omit<P, "onChange">) => new Promise<Parameters<P["onChange"]>[0]>(resolve => {
    const [render, dismount] = M.renderInContainer();
    if (render && dismount) {
        let content = null, title = "", size = "md";
        const onQuit = () => dismount(() => resolve(null));
        const onChange: P["onChange"] = data => dismount(() => resolve(data));

        if (params.type === "asset") {
            title = TC.EPRA_MISSION_TAB_BUTTON_ASSET_CAT;
            content = <CategoryEdit {...params} onChange={onChange} />;
        }
        else if (params.type === "period") {
            title = TC.EPRA_MISSION_TAB_BUTTON_PERIOD;
            content = <PeriodEdit {...params} onChange={onChange} />;
        }
        else if (params.type === "views") {
            title = TC.EPRA_MISSION_TAB_BUTTON_FAVORITE;
            content = <SavedViewsManager {...params} onChange={onChange} />;
        }

        if (content === null) resolve(null);
        else render(<M.BlankModal title={title} onQuit={onQuit} size={size as any} children={content} />);
    }
    else resolve(null);
});

/**
 * A component to edit the assets categories
 */
const CategoryEdit: React.FC<CategoryEditProps> = props => {
    const lg = H.useLanguage();
    const [cat, set_cat] = React.useState(props.categories || []);
    const [errors, set_errors] = React.useState<T.Errors<Record<number, string>>>({});

    const current_cat = React.useMemo<typeof cat>(() => {
        if (cat.length === 0) return [{ id: "empty", assets: [], name: "" }];
        else return cat;
    }, [cat]);

    const events = React.useMemo(() => ({
        remove: (id: string) => set_cat(p => p.filter(c => c.id !== id)),
        new: (name?: string) => set_cat(p => p.concat({ name: name || "", id: ID.v4(), assets: [] })),
        key_down: (event: Parameters<React.KeyboardEventHandler<HTMLInputElement>>[0], id: string) => {
            if (event.key === "Enter") {
                if (id === "empty") events.edit((event.target as any)?.value || "", id, true);
                else events.new();
            }
        },
        edit: (name: string, id: string, add_new_row = false) => {
            if (id === "empty") events.new(name);
            else set_cat(p => p.map(c => c.id === id ? { ...c, name } : c));
            if (add_new_row) events.new();
        },
    }), []);

    const validate = React.useCallback(() => {
        let new_errors: typeof errors = {};

        for (let i = 0; i < cat.length; i++) {
            if (!TB.validString(cat[i].name)) new_errors[i] = TC.GLOBAL_REQUIRED_FIELD;
        }

        if (Object.keys(new_errors).length > 0) set_errors(new_errors);
        else props.onChange(cat);
    }, [cat, props]);

    return <div>
        <C.Flex alignItems="center" justifyContent="between">
            <h4>{lg.getStaticText(TC.CDM_MISSION_CATEGORY_LABEL)}</h4>
            <C.Button size="sm" icon="save" text={TC.GLOBAL_SAVE} onClick={validate} />
        </C.Flex>

        <div className="mt-3">
            {current_cat.map((c, i) => <C.Flex key={c.id} className="mb-2" alignItems="center" justifyContent="between">
                <C.Form.TextField
                    value={c.name}
                    noBottomMargin
                    error={errors[i]}
                    customClass="flex-grow-1 me-2"
                    autoFocus={i === current_cat.length - 1}
                    onKeyDown={e => events.key_down(e, c.id)}
                    onChange={name => events.edit(name, c.id)}
                />
                <div>
                    <C.Button size="sm" icon="times" variant="danger" onClick={() => events.remove(c.id)} />
                    {i === current_cat.length - 1 && <C.Button className="ms-2" size="sm" icon="plus" onClick={() => events.new()} />}
                </div>
            </C.Flex>)}
        </div>
    </div >;
}

/**
 * A component to edit the periods
 */
const PeriodEdit: React.FC<PeriodEditProps> = props => {
    const lg = H.useLanguage();
    const [errors, set_errors] = React.useState<Partial<Record<"p1_name" | "p1_start" | "p1_end" | "p2_name" | "p2_start" | "p2_end", string>>>({});
    const [periods, set_periods] = React.useState(props.periods || { period_1: { name: "", start_date: "", end_date: "" }, period_2: { name: "", start_date: "", end_date: "" } });

    const edit = React.useMemo(() => ({
        edit: (value: string, prop: keyof typeof props.periods.period_1, period: keyof typeof props.periods) => {
            set_periods(p => ({ ...p, [period]: { ...p[period], [prop]: value } }));
        },
    }), [props]);

    const validate = React.useCallback(() => {
        let new_errors: typeof errors = {};

        let name_p1 = periods.period_1?.name,
            name_p2 = periods.period_2?.name,
            end_date_p1 = TB.getDate(periods.period_1?.end_date),
            end_date_p2 = TB.getDate(periods.period_2?.end_date),
            start_date_p1 = TB.getDate(periods.period_1?.start_date),
            start_date_p2 = TB.getDate(periods.period_2?.start_date);

        if (!end_date_p1) new_errors.p1_end = TC.GLOBAL_REQUIRED_FIELD;
        if (!end_date_p2) new_errors.p2_end = TC.GLOBAL_REQUIRED_FIELD;
        if (!start_date_p1) new_errors.p1_start = TC.GLOBAL_REQUIRED_FIELD;
        if (!start_date_p2) new_errors.p2_start = TC.GLOBAL_REQUIRED_FIELD;
        if (!TB.validString(name_p1)) new_errors.p1_name = TC.GLOBAL_REQUIRED_FIELD;
        if (!TB.validString(name_p2)) new_errors.p2_name = TC.GLOBAL_REQUIRED_FIELD;

        if (start_date_p1 && end_date_p1 && start_date_p1 >= end_date_p1) {
            new_errors.p1_end = TC.ERR_DATE_TO_LOWER_DATE_FROM;
            new_errors.p1_start = TC.ERR_DATE_FROM_HIGHER_DATE_TO;
        }
        if (start_date_p2 && end_date_p2 && start_date_p2 >= end_date_p2) {
            new_errors.p2_end = TC.ERR_DATE_TO_LOWER_DATE_FROM;
            new_errors.p2_start = TC.ERR_DATE_FROM_HIGHER_DATE_TO;
        }
        if (!new_errors.p1_end && !new_errors.p2_start && end_date_p1 && start_date_p2 && end_date_p1 >= start_date_p2) {
            new_errors.p1_end = TC.EPRA_MISSION_PERIOD_1_INVALID;
            new_errors.p2_start = TC.EPRA_MISSION_PERIOD_2_INVALID;
        }

        if (Object.keys(new_errors).length > 0) set_errors(new_errors);
        else props.onChange(periods);
    }, [periods, props]);

    return <div>
        <C.Flex alignItems="center" justifyContent="between">
            <h4>{lg.getStaticText(TC.CDM_MISSION_CATEGORY_LABEL)}</h4>
            <C.Button size="sm" icon="save" text={TC.GLOBAL_SAVE} onClick={validate} />
        </C.Flex>

        <div className="mt-3">
            <BS.Row className="g-1 mb-2 fw-bold">
                <BS.Col md={4} children={lg.getStaticText(TC.GLOBAL_NAME)} />
                <BS.Col md={4} children={lg.getStaticText(TC.EPRA_PERIOD_START)} />
                <BS.Col md={4} children={lg.getStaticText(TC.EPRA_PERIOD_END)} />
            </BS.Row>

            <BS.Row className="g-1 mb-2">
                <BS.Col md={4}>
                    <C.Form.TextField
                        hideLabel
                        noBottomMargin
                        error={errors.p1_name}
                        value={periods.period_1?.name}
                        onChange={value => edit.edit(value, "name", "period_1")}
                    />
                </BS.Col>
                <BS.Col md={4}>
                    <C.Form.DateTime
                        hideLabel
                        noBottomMargin
                        error={errors.p1_start}
                        value={periods.period_1?.start_date}
                        onChange={value => edit.edit(value, "start_date", "period_1")}
                    />
                </BS.Col>
                <BS.Col md={4}>
                    <C.Form.DateTime
                        hideLabel
                        noBottomMargin
                        error={errors.p1_end}
                        value={periods.period_1?.end_date}
                        onChange={value => edit.edit(value, "end_date", "period_1")}
                    />
                </BS.Col>
            </BS.Row>

            <BS.Row className="g-1 mb-2">
                <BS.Col md={4}>
                    <C.Form.TextField
                        hideLabel
                        noBottomMargin
                        error={errors.p2_name}
                        value={periods.period_2?.name}
                        onChange={value => edit.edit(value, "name", "period_2")}
                    />
                </BS.Col>
                <BS.Col md={4}>
                    <C.Form.DateTime
                        hideLabel
                        noBottomMargin
                        error={errors.p2_start}
                        value={periods.period_2?.start_date}
                        onChange={value => edit.edit(value, "start_date", "period_2")}
                    />
                </BS.Col>
                <BS.Col md={4}>
                    <C.Form.DateTime
                        hideLabel
                        noBottomMargin
                        error={errors.p2_end}
                        value={periods.period_2?.end_date}
                        onChange={value => edit.edit(value, "end_date", "period_2")}
                    />
                </BS.Col>
            </BS.Row>
        </div>
    </div >;
};

/**
 * A component to manage the saved views 
 */
const SavedViewsManager: React.FC<SavedViewsManagerProps> = ({ onChange, ...props }) => {
    const lg = H.useLanguage();
    const is_creating = H.useBoolean(false);
    const [{ userId, isAdmin }] = H.useAuth();
    const [errors, set_errors] = React.useState<T.Errors<T.EpraView>>({});
    const [settings, set_settings, status] = H.useAsyncState<EpraSettings>({ views: [], users: [] });
    const [new_view, set_view] = React.useState<Omit<T.EpraView, "_id">>({ name: "", users: [], epra: null, public: false, owner: userId });

    React.useEffect(() => {
        let isSubscribed = true;
        S.getEpraViewsSettings()
            .then(({ data }) => isSubscribed && set_settings(data, "done"))
            .catch(() => isSubscribed && set_settings({ views: [], users: [] }, "error"));
        return () => {
            isSubscribed = false;
            set_settings({ views: [], users: [] }, "load");
        }
    }, [set_settings]);

    const change = React.useMemo(() => ({
        cancel: () => {
            // Return to the view list
            is_creating.setFalse();
            // Reset the errors
            set_errors({});
            // Reset the view
            set_view({ name: "", users: [], epra: null, public: false, owner: userId });
        },
        save: () => {
            let new_errors: typeof errors = {};
            // No Name given to the view
            if (!TB.validString(new_view.name)) new_errors.name = TC.GLOBAL_REQUIRED_FIELD;
            // No users given to the view, and view is not public
            if (new_view.users.length === 0 && !new_view.public) new_errors.users = TC.GLOBAL_REQUIRED_FIELD;

            if (Object.keys(new_errors).length > 0) set_errors(new_errors);
            else {
                // Clean the provided epra state of all datasets ids
                let clean_epra_state: T.EpraView["epra"] = {
                    ...props.epra,
                    datasource: props.epra.datasource.map(ds => ({
                        ...ds,
                        assets: ds.assets.map(a => ({
                            ...a,
                            datasets: { period_1: { emplacements: [], datasets: [] }, period_2: { emplacements: [], datasets: [] } }
                        })),
                    })),
                };
                let to_save_view: typeof new_view = { ...new_view, epra: clean_epra_state };

                S.saveEpraView(to_save_view)
                    .then(({ data }) => {
                        set_settings(p => ({ ...p, views: p.views.concat(data) }));
                        change.cancel();
                    })
                    .catch(M.Alerts.updateError);
            }
        },
        remove: (view_id: string) => M.askConfirm().then(confirmed => {
            if (confirmed) S.deleteEpraView(view_id)
                .then(() => set_settings(p => ({ ...p, views: p.views.filter(v => v._id !== view_id) })))
                .catch(M.Alerts.deleteError);
        }),
        public: (is_public: boolean) => set_view(p => ({ ...p, public: is_public, users: is_public ? [] : p.users })),
    }), [is_creating, userId, new_view, props.epra, set_settings, set_view]);

    return <C.Spinner status={status} min_load_size="500px">
        {is_creating.value
            ? <>
                <C.Form.TextField
                    required
                    error={errors.name}
                    labelPosition="left"
                    value={new_view.name}
                    label={TC.EPRA_FAV_NAME}
                    onChange={value => set_view(p => ({ ...p, name: value }))}
                />
                <C.Form.Select
                    multiple
                    error={errors.users}
                    labelPosition="left"
                    value={new_view.users}
                    options={settings.users}
                    label={TC.EPRA_FAV_USERS}
                    disabled={new_view.public}
                    typeahead={{ extra_search_props: "mail" }}
                    onChange={value => set_view(p => ({ ...p, users: value }))}
                />
                {isAdmin && <C.Form.RadioBool
                    always_selected
                    labelPosition="left"
                    value={new_view.public}
                    name="epra_view_public"
                    onChange={change.public}
                    label={TC.EPRA_FAV_PUBLIC}
                />}

                <C.Flex justifyContent="end" alignItems="center">
                    <C.Button size="sm" text={TC.EPRA_FAV_CANCEL} icon="times" onClick={change.cancel} variant="danger" />
                    <C.Button size="sm" text={TC.EPRA_FAV_SAVE} icon="save" onClick={change.save} className="ms-2" />
                </C.Flex>
            </>
            : <>
                {props.epra && <C.Button
                    size="sm"
                    icon="plus"
                    className="w-100 mb-3"
                    text={TC.EPRA_FAV_NEW_VIEW}
                    onClick={is_creating.setTrue}
                />}

                {settings.views.length === 0
                    ? <C.CenterMessage title={TC.EPRA_FAV_NO_VIEWS} />
                    : <BS.ListGroup>
                        {settings.views.map(view => <BS.ListGroup.Item key={view._id}>
                            <C.Flex justifyContent="between">
                                <div className="fs-110">
                                    <C.IconTip
                                        className="me-2"
                                        icon={view.public ? "globe-europe" : "lock"}
                                        tipContent={view.public ? TC.EPRA_FAV_PUBLIC : TC.EPRA_FAV_PRIVATE}
                                    />
                                    {view.name}
                                </div>
                                <BS.ButtonGroup>
                                    <C.Button size="sm" text={TC.EPRA_FAV_SELECT} onClick={() => onChange?.(view)} />
                                    <C.Button size="sm" text={TC.EPRA_FAV_DELETE} onClick={() => change.remove(view._id)} variant="danger" />
                                </BS.ButtonGroup>
                            </C.Flex>
                            <div className="text-muted">
                                <div>
                                    {view.epra.periods.period_1.name} : {TB.formatDate(view.epra.periods.period_1.start_date)} - {TB.formatDate(view.epra.periods.period_1.end_date)}
                                </div>
                                <div>
                                    {view.epra.periods.period_2.name} : {TB.formatDate(view.epra.periods.period_2.start_date)} - {TB.formatDate(view.epra.periods.period_2.end_date)}
                                </div>
                                <div>
                                    {lg.getStaticText(TC.EPRA_MISSION_TAB_BUTTON_ASSET_CAT)} : {view.epra.datasource.map(ds => ds.name).join(", ")}
                                </div>
                            </div>
                        </BS.ListGroup.Item>)}
                    </BS.ListGroup>
                }
            </>
        }
    </C.Spinner>;
};