export const NO_VALUE = 'none';
export const RANGE_ALL_BOTH = 6;
export const OTHER_PROP = 'other';
export const RANGE_DIRECT_BOTH = 3;
export const RANGE_ALL_PARENTS = 5;
export const RANGE_ALL_CHILDREN = 4;
export const RANGE_DIRECT_PARENTS = 2;
export const RANGE_DIRECT_CHILDREN = 1;
export const NO_VALUE_TEXT = 'No Values';
export const MAIN_DIV_ID = "linkCreatorMainDivId";
export const BUTTON_ADD_DIV_ID = "buttonAddDivId";
export const MULTI_SELECT_DIV_ID = "multiSelectDivId";
export const MULTI_SELECT_BIG_CLASS = "multiSelectBig";
export const ERROR_MESSAGE_CLASS = "errorMessageClass";