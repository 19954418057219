import * as T from "../Types";

/** Fields from equipment form thats does not appear in the equipstore form */
export const EQUIP_STORE_IGNORE: T.EquipStoreIgnoredKeys[] = [
    "actions", "estimated_end_of_life", "criticity", "dateFabrication", "dateInstallation",
    "failureCriticity", "fiche", "inGoodCondition", "name", "pictures", "qrCode", "quantity",
    "reglementations", "state", "technicians", "vetusty", "code", "idCode", "longName", "serialNumber",
    "underContract", "equipment", "tags",
];

/** Fields that appear in the equipment form that are not dependent on it's category */
export const EQUIP_NO_GAMME_FIELD: (keyof T.EquipmentBasic | "tags")[] = [
    "actions", "category", "criticity", "failureCriticity", "fiche", "name", "color", "estimated_end_of_life", "tags",
    "pictures", "qrCode", "quantity", "reglementations", "state", "technicians", "vetusty", "equipment", "replacement_cost",
];

/** Equipment fields that are only dependant on the 'communication' property, and not from any restriction from a gamme */
export const EQUIP_COMMUNICATION_FIELD: (keyof T.EquipmentData)[] = ["power_unit", "value_20mA", "value_4mA", "impulsion_weight", 'address'];

/** Fields that appear in the equipment form, but that are not subject to restriction from the gamme */
export const EQUIP_NO_RESTRICTION_FIELD: (keyof T.EquipmentData)[] = EQUIP_COMMUNICATION_FIELD;