export const DEPOT = "depot";
export const SPORT = "sport";
export const JAIL = "prison";
export const HOTEL = "hotels";
export const RETAIL = "retail";
export const HEALTH = "health";
export const CINEMA = "cinema";
export const CHURCH = "church";
export const OFFICE = "offices";
export const LAB_BIO = "labBio";
export const POOL = "sportPool";
export const THEATER = "theatre";
export const AIRPORT = "airport";
export const LIBRARY = "library";
export const BINGO = "bingoClub";
export const BUTCHER = "butcher";
export const ICE_RING = "iceRing";
export const LAB_PHYS = "labPhys";
export const LAB_CHEM = "labChem";
export const R_D_BIO = "rdLabBio";
export const INDUSTRY = "industry";
export const LAB_OTHER = "labOther";
export const FAST_FOOD = "fastFood";
export const TOWN_HALL = "townHall";
export const R_D_CHEM = "rdLabChem";
export const DIY_STORE = "diyStore";
export const R_D_PHYS = "rdLabPhys";
export const EDUCATION = "teaching";
export const SHOE_STORE = "shoeShop";
export const BOOK_STORE = "bookShop";
export const COMMUNITY = "community";
export const R_D_OTHER = "rdLabOther";
export const SCHOOL_LAB = "schoolLab";
export const DAY_CENTER = "dayCenter";
export const FITNESS = "fitnessCenter";
export const PUBLIC_SERVICE = "public";
export const OFF_LICENSE = "offLicense";
export const SMALL_HOTEL = "smallHotel";
export const LUX_HOTEL = "luxuousHotel";
export const POST_OFFICE = "postOffice";
export const REST_BAR = "barRestaurant";
export const OFFICE_OPEN = "officeOpen";
export const CROWN_COURT = "crownCourt";
export const LAB_BIOCHEM = "labBioChem";
export const OFFICE_CELL = "officeCell";
export const SOCIAL_CLUB = "socialClub";
export const SUB_AIRPORT = "subAirport";
export const DIST_STOCK = "distAndStock";
export const HOSP_TEACH = "hospTeaching";
export const SUPERMARKET = "supermarket";
export const NURSING_HOME = "nursingHome";
export const HOSP_COTTAGE = "hospCottage";
export const FIRE_STATION = "fireStation";
export const COUNTY_COURT = "countyCourt";
export const CLOTHE_STORE = "clothesShop";
export const R_D_BIOCHEM = "rdLabBioChem";
export const TEACH_BIO = "teachingLabChem";
export const TEACH_CHEM = "teachingLabBio";
export const BIG_STORE = "departmentStore";
export const GEN_MANU = "genManufacturing";
export const FACT_OFFICE = "factoryOffice";
export const FROZEN_CENTER = "frozenCenter";
export const HOLIDAY_HOTEL = "holidayHotel";
export const AMBULANCE = "ambulanceStation";
export const MAG_COURT = "magistratesCourt";
export const HOMELESS_UNIT = "homelessUnit";
export const TEACH_PHYS = "teachingLabOther";
export const SCHOOL_BUILD = "schoolBuilding";
export const SPORT_CENTER = "mixSportCenter";
export const LOCKER_ROOM = "sportLockerRoom";
export const SHELTER_HOME = "shelteredHouse";
export const ENTERTAINMENT = "entertainment";
export const ELEC_FURN_RENTAL = "elecRental";
export const HOSP_LONG_STAY = "hospLongStay";
export const TEACH_OTHER = "teachingLabPhys";
export const SCHOOL_LIBRARY = "schoolLibrary";
export const HOSP_MATERNITY = "hospMaternity";
export const PRIMARY_SCHOOL = "primarySchool";
export const RETAIL_SERVICE = "detailService";
export const POLICE_STATION = "policeStation";
export const COMMU_CENTER = "communityCenter";
export const LIGHT_MANU = "lightManufacturing";
export const HOUSE_CARE = "residentialCareHome";
export const POOL_LEISURE = "poolCenterLeisure";
export const SMALL_FOOD_STORE = "smallFoodShop";
export const SPORT_CENTER_DRY = "drySportCenter";
export const SCHOOL_FAST_FOOD = "schoolFastFood";
export const SCHOOL_RES_HALL = "residentialHall";
export const MANU_LAB_BIO = "manufactureLabChem";
export const WAREHOUSE = "distributionWarehouse";
export const MANU_LAB_CHEM = "manufactureLabBio";
export const REST_HOUSE = "publicHouseRestaurant";
export const JAIL_SUPERMAX = "highSecurityPrison";
export const SECONDARY_SCHOOL = "secondarySchool";
export const TEACH_BIOCHEM = "teachingLabBioChem";
export const MANU_LAB_PHYS = "manufactureLabOther";
export const MANU_LAB_OTHER = "manufactureLabPhys";
export const COMBINE_LAB_BIO = "otherCombineLabBio";
export const COMBINE_LAB_PHYS = "otherCombineLabPhys";
export const COMBINE_LAB_CHEM = "otherCombineLabChem";
export const HOSP_PRIMARY_HEALTH = "hospPrimaryHealth";
export const SCHOOL_SCIENCE_ROOM = "schoolScienceRoom";
export const MANU_LAB_BIOCHEM = "manufactureLabBioChem";
export const SCHOOL_SELF_CATER = "residentialSelfCater";
export const COMBINE_LAB_OTHER = "otherCombineLabOther";
export const SCHOOL_RESTAURANT_BAR = "schoolRestaurantBar";
export const COMBINE_LAB_BIOCHEM = "otherCombineLabBioChem";
export const SCHOOL_CONFERENCE_ROOM = "schoolConferenceRoom";