import thunk from "redux-thunk";
import * as S from "../services";
import { FP, T } from "../Constants";
import rootReducer from "../reducers";
import asyncDispatch from "./asyncDispatch";
import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";

const create_redux_store = async () => {
    // First load the data then create the store
    const default_data_context = new Promise<T.DataContextRedux>(resolve => {
        try {
            // Check if there is a context stored in localStorage
            let stored = localStorage.getItem("redux_context");
            if (typeof stored === "string" && stored.length > 0) {
                let store = JSON.parse(stored);
                if (!window.location.pathname.includes("/mission/")) {
                    delete store.active_mission;
                    localStorage.removeItem("mission_id");
                }
                else if (store.active_mission) localStorage.setItem("mission_id", store.active_mission);
                resolve(store);
            }
            // If user is logged, try to load a default context
            else {
                // Load the user from the storage
                let user = localStorage.getItem("formioUser");
                // Contact the database
                if (typeof user === "string" && user.length > 0) S.defaultContext().then(response => {
                    // A default context was found
                    if (response.data) resolve({
                        active_mission: null,
                        selectedItems: response.data.roots || [],
                        selectedPortfolio: response.data.portfolio || null,
                        selectedSites: response.data.path === FP.SITE_FORM ? response.data.roots : [],
                        selectedClients: response.data.path === FP.CLIENT_FORM ? response.data.roots : [],
                        selectedBuildings: response.data.path === FP.BUILDING_FORM ? response.data.roots : [],
                        selectedEmplacements: response.data.path === FP.EMPLACEMENT_FORM ? response.data.roots : [],
                    });
                    else resolve(undefined);
                }).catch(() => resolve(undefined));
                else resolve(undefined);
            }
        }
        catch (error) { resolve(undefined) }
    });
    // Second load the rights for the context
    const default_right_context = (context: T.DataContextRedux) => new Promise<T.RightsContextRedux>(resolve => {
        if (!context) resolve(undefined);
        else {
            let search_context = {} as T.ContextParams;
            if (context.selectedPortfolio) search_context.portfolio = context.selectedPortfolio;
            else search_context.roots = context.selectedItems;

            if (window.location.pathname === "/login") resolve({ access: [], admin: false, context_ids: [], first: false, search_context, loading: false });
            else S.getUserAccess(search_context)
                .then(({ data }) => resolve({ ...data, search_context, loading: false }))
                .catch(() => resolve({ access: [], admin: false, context_ids: [], first: false, search_context, loading: false }));
        }
    });

    let dataContext = await default_data_context;
    let rightsContext = await default_right_context(dataContext);
    const store = createStore(rootReducer, { dataContext, rights: rightsContext }, composeWithDevTools(applyMiddleware(...middleware)));
    return store;
}

const middleware = [thunk, asyncDispatch];
const store = create_redux_store();
export default store;