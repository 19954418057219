import _ from "lodash";
import React from "react";
import * as M from "../../Modal";
import * as G from "../../Gestion";
import * as H from "../../../hooks";
import * as C from "../../../Common";
import * as S from "../../../services";
import { DataSets, FormPanels } from "../../Form";
import { FP, LT, T, TC, TB, RESOURCE } from "../../../Constants";
import { Col, Row, Accordion, ButtonGroup } from "react-bootstrap";

//#region Types
export type ControlListProps = {
    /** The id of the asset */
    asset: string;
    /** Callback to notify that datasets were created */
    new_datasets: () => void;
    /** Do not allow any edits */
    read_only: boolean;
};

type UpdateFn = (element: ControlData) => ControlData;
type Indicators = ReturnType<T.API.EDL.EQUIP_DATA.GetIndicators>;
type ControlData = ReturnType<T.API.Utils.Missions.ControlListMissionCDM>[number];
//#endregion

const ControlList: React.FC<ControlListProps> = ({ new_datasets, ...props }) => {
    const lg = H.useLanguage();
    const [formIds] = H.useFormIds();
    const [active, set_active] = React.useState(null);
    const [menu_item, set_menu_item] = React.useState(null);
    const [indicators, set_indicators] = H.useAsyncState<Indicators>([]);
    const data_tables_ref = React.useRef<Record<string, DataSets.DataPanelRef>>({});
    const [control_list, set_control_list, status] = H.useAsyncState<ControlData[]>([]);
    const rems_panels_refs = React.useRef<Record<string, FormPanels.RemarquesPanelRef>>({});

    //#region Load data
    const equip_id = React.useMemo(() => formIds[FP.EQUIPEMENT_FORM], [formIds]);

    React.useEffect(() => {
        let isSubscribed = true;
        S.controlListMissionCDM(props.asset)
            .then(({ data }) => isSubscribed && set_control_list(data, "done"))
            .catch(() => isSubscribed && set_control_list([], "error"));
        return () => {
            isSubscribed = false;
            set_control_list([], "load");
        };
    }, [set_control_list, props.asset]);

    React.useEffect(() => {
        let isSubscribed = true;
        S.getEquipIndicators()
            .then(({ data }) => isSubscribed && set_indicators(data, "done"))
            .catch(() => isSubscribed && set_indicators([], "error"));
        return () => { isSubscribed = false };
    }, [set_indicators]);

    const options = React.useMemo(() => ({
        criticity: indicators
            .filter(i => i.data.type === "criticityEquipment")
            .map(i => ({ label: i.data.level, prop: "level", value: i._id })) as T.Option[],
        vetusty: indicators
            .filter(i => i.data.type === "vetusty")
            .map(i => ({ label: i.data.level, prop: "level", value: i._id })) as T.Option[],
    }), [indicators]);
    //#endregion

    //#region Active Item & Searched
    const find_item = React.useCallback((item_id: string) => {
        let current_item = null as typeof control_list[number];
        const recursive = (item: typeof control_list[number]) => {
            if (item.item === item_id) current_item = item;
            else item.children.forEach(recursive);
        };
        control_list.forEach(recursive);
        return current_item;
    }, [control_list]);

    const active_item = React.useMemo(() => find_item(active), [find_item, active]);

    const active_item_equip_nb = React.useMemo(() => {
        let nb_equip = 0, controlled_equip = 0;
        if (!active_item) return { nb_equip, controlled_equip };

        nb_equip = active_item.children.filter(c => c.type === "equip").length;
        return { nb_equip, controlled_equip };
    }, [active_item]);

    const active_tree_trail = React.useMemo(() => {
        const recursive = (item: ControlData) => {
            if (item.item === active) return item.item;
            else {
                // Search for the active item in the children
                let find = _.flatten(item.children.map(recursive)).filter(TB.mongoIdValidator);
                // Active item is not in the descendance
                if (find.length === 0) return null;
                // Item is in the descendance
                else return [item.item].concat(find);
            }
        }
        return _.flatten(control_list.map(recursive)).filter(TB.mongoIdValidator);
    }, [control_list, active]);
    //#endregion

    //#region Edits
    const set_list = React.useMemo(() => ({
        generic: (update_fn: UpdateFn) => set_control_list(p => {
            const recursive = (list: typeof p) => list.map(i => {
                let item = update_fn(i);
                return { ...item, children: recursive(item.children) };
            });
            return recursive(p);
        }),
        remove: (_id: string) => set_control_list(p => {
            const recursive = (list: typeof p): typeof p => list.map(i => ({ ...i, children: recursive(i.children).filter(i => i.item !== _id) }));
            return recursive(p).filter(i => i.item !== _id);
        }),
        add: (parent: string, item: ControlData) => set_control_list(p => {
            const recursive = (list: typeof p): typeof p => list.map(i => {
                let new_item = { ...i, children: recursive(i.children) };
                if (new_item.item === parent) new_item.children = new_item.children.concat(item);
                return new_item;
            });
            return recursive(p);
        }),
        control_set: (ids: string[]) => set_list.generic(i => ids.includes(i.item) ? { ...i, controlled: true } : i),
        toggle_control: (_id: string) => set_list.generic(i => i.item === _id ? { ...i, controlled: !i.controlled } : i),
        remarque: (_id: string, remarques: ControlData["remarques"]) => set_list.generic(i => i.item === _id ? { ...i, remarques } : i),
        replace: (item: ControlData) => {
            // Update the criticity cause it may have changed
            if (item.type !== "building") rems_panels_refs.current[item.item]?.set_criticity?.(item.equipment?.criticity);
            set_list.generic(i => i.item === item.item ? item : i)
        },
        field: (_id: string, prop: keyof T.EquipmentData, value: any) => {
            // Update the criticity in the rems panel too
            if (prop === "criticity") rems_panels_refs.current[_id]?.set_criticity?.(value);
            set_list.generic(i => i.item === _id ? { ...i, equipment: { ...i.equipment, [prop]: value } } : i)
        },
    }), [set_control_list]);

    const check_equipment = React.useCallback((item: ControlData) => {
        let missing_properties = [] as (keyof typeof item["equipment"])[];
        if (item.type !== "equip") return missing_properties;
        if (!TB.mongoIdValidator(item.equipment?.vetusty)) missing_properties.push("vetusty");
        if (!TB.mongoIdValidator(item.equipment?.criticity)) missing_properties.push("criticity");
        if (TB.getDate(item.equipment?.dateInstallation) === null) missing_properties.push("dateInstallation");
        if (isNaN(TB.getNumber(item.equipment?.expectedLifespan))) missing_properties.push("expectedLifespan");
        if (isNaN(TB.getNumber(item.equipment?.estimated_end_of_life))) missing_properties.push("estimated_end_of_life");
        return missing_properties;
    }, []);

    const events = React.useMemo(() => ({
        toggle_control_element: (item: ControlData) => {
            // Promise to see if we're going through with the control toggle
            const confirm_promised = new Promise<boolean>(resolve => {
                // No need to ask for confirmation if toggle is off
                if (item.controlled) resolve(true);
                // No check needed for emplacement
                else if (item.type === "emp") resolve(true);
                else {
                    let missing_prop = check_equipment(item);
                    // No missing properties
                    if (missing_prop.length === 0) resolve(true);
                    else {
                        let text = <div>
                            {lg.getStaticText(TC.MISSION_CDM_MISSING_PROPS)}
                            <ul>{missing_prop.map(p => <li key={p}>{lg.getTextObj(equip_id, p, p)}</li>)}</ul>
                            {lg.getStaticText(TC.MISSION_CDM_MISSING_PROP_CONFIRM)}
                        </div>;
                        M.askConfirm({ title: TC.MISSION_CDM_MISSING_PROP_TITLE, text }).then(confirmed => resolve(!!confirmed));
                    }
                }
            });

            confirm_promised.then(confirmed => {
                if (confirmed) S.missionToggleControl(item.item)
                    .then(() => set_list.toggle_control(item.item))
                    .catch(M.Alerts.updateError);
            });
        },
        control_sub_equip: (item: ControlData, event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            // Promise to see if we're going through with the control toggle
            const confirm_promised = new Promise<boolean>(resolve => {
                let incomplete_items = [] as string[];

                for (let child of item.children) {
                    if (child.type === "equip") {
                        let missing_prop = check_equipment(child);
                        if (missing_prop.length > 0) incomplete_items.push(child.name);
                    }
                }

                if (incomplete_items.length === 0) resolve(true);
                else {
                    let text = <div>
                        {lg.getStaticText(TC.MISSION_CDM_INCOMPLETE_ELEM)}
                        <ul>{incomplete_items.map((p, i) => <li key={i}>{p}</li>)}</ul>
                        {lg.getStaticText(TC.MISSION_CDM_INCOMPLETE_ELEM_CONFIRM)}
                    </div>;

                    M.askConfirm({ title: TC.MISSION_CDM_MISSING_PROP_TITLE, text }).then(confirmed => resolve(!!confirmed));
                }
            });

            event?.stopPropagation?.();
            confirm_promised.then(confirmed => {
                if (confirmed) {
                    let equip_kids = item.children.filter(i => i.type === "equip").map(i => i.item);
                    S.missionSetControlled(equip_kids)
                        .then(() => set_list.control_set(equip_kids))
                        .catch(M.Alerts.updateError);
                }
            });
        },
        on_change_rem: (item_id: string) => {
            S.controlListRemarquesData(item_id)
                .then(({ data }) => set_list.remarque(item_id, data))
                .catch(M.Alerts.updateError);
        },
        remove_element: (item: ControlData) => {
            M.askConfirm().then(confirmed => {
                const remove_api = item.type === "emp" ? S.removeEmplacements : S.removeEquipments;
                if (confirmed) remove_api(item.item).then(({ data }) => {
                    if (data === "has_children") M.renderAlert({ type: "warning", message: TC.ERROR_DELETE_DESCENDANT_FIRST })
                    else set_list.remove(item.item);
                }).catch(M.Alerts.deleteError);
            })
        },
        add_child_emp: (item: ControlData) => {
            // Create an emplacement
            M.renderFormModal<T.EmplacementData>({ path: FP.EMPLACEMENT_FORM, modalProps: { size: "lg" } }).then(new_emp => {
                if (new_emp) {
                    let unmount = M.renderLoader();
                    // Attach the new emplacement
                    S.attachNode({ parent: item.item, children: new_emp._id, type: LT.LINK_TYPE_OWN }).then(() => {
                        set_list.add(item.item, {
                            type: "emp",
                            presets: [],
                            nb_notes: 0,
                            children: [],
                            item: new_emp._id,
                            controlled: false,
                            datasets_amount: 0,
                            name: new_emp.data.name,
                            emp_type: new_emp.data.type,
                            remarques: { amount: 0, color: "grey" },
                        });
                    })
                        .catch(M.Alerts.updateError)
                        .finally(unmount);
                }
            });
        },
        edit_element: (item: ControlData) => {
            let path = FP.EMPLACEMENT_FORM;
            if (item.type === "equip") path = FP.EQUIPEMENT_FORM;
            else if (item.type === "building") path = FP.BUILDING_FORM;
            let params: Parameters<typeof M.renderPopUpFormModal>[0] = { title: item.name, submissionId: item.item, path };

            M.renderPopUpFormModal(params).then(edited => {
                if (edited) {
                    let unmount = M.renderLoader();
                    S.controlListMissionCDM(item.item).then(({ data }) => {
                        let new_item = data.filter(d => d.item === item.item)[0];
                        if (!new_item) M.Alerts.loadError();
                        else set_list.replace(new_item);
                    })
                        .catch(M.Alerts.loadError)
                        .finally(unmount);
                }
            });
        },
        move_element: (item: ControlData) => {
            M.renderLightTree({
                root: props.asset,
                selection: item.item,
                restrictOwnLinks: true,
                style: { size: "lg", title: TC.GLOBAL_PICK_PARENT },
                linkRestriction: {
                    isInput: true,
                    no_children: true,
                    excludeIds: item.item,
                    linkType: LT.LINK_TYPE_OWN,
                    objForm: formIds[
                        item.type === "building"
                            ? FP.BUILDING_FORM
                            : (item.type === "emp" ? FP.EMPLACEMENT_FORM : FP.EQUIPEMENT_FORM)
                    ],
                }
            })
                .then(parent_id => {
                    if (parent_id && parent_id !== item.item) {
                        let unmount = M.renderLoader();
                        S.moveNode({ removeOld: "sameType", children: item.item, type: LT.LINK_TYPE_OWN, parent: parent_id })
                            .then(() => S.controlListMissionCDM(null)
                                .then(({ data }) => set_control_list(data, "done"))
                                .catch(() => set_control_list([], "error"))
                                .finally(unmount))
                            .catch(error => {
                                unmount();
                                M.Alerts.updateError(error);
                            })
                    }
                });
        },
        magic_data: (item: ControlData, event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            event?.stopPropagation?.();

            let confirm_params = {
                title: TC.CDM_MAGIC_CREATE_DATASETS,
                text: <ul>{item.presets.map(p => <li>{p}</li>)}</ul>,
            } as M.AskConfirmModalProps;

            M.askConfirm(confirm_params).then(confirmed => {
                if (confirmed) S.cmdMagicDatasets(item.item)
                    .then(({ data }) => {
                        // Avert the user the datasets were created
                        M.Alerts.success_alert();
                        // Reload the dataset table
                        data_tables_ref.current[item.item]?.reload?.();
                        // Notify the parent that new datasets are created
                        new_datasets();
                        // Remove the dataset from the list of available presets
                        set_list.replace({ ...item, datasets_amount: item.datasets_amount + data.length, presets: [] });
                    })
                    .catch(M.Alerts.updateError);
            });
        },
        set_equip_field: (item: ControlData, prop: keyof T.EquipmentData, value: any) => {
            S.update_equipment_field({
                field: prop,
                _id: item.item,
                new_value: value,
                force_update: true,
                old_value: item.equipment?.[prop],
            }).then(({ data }) => {
                if (data === "changed") M.Alerts.updateError();
                else set_list.field(item.item, prop, value);
            }).catch(M.Alerts.updateError);
        },
        open_notes: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, item: ControlData) => {
            event.stopPropagation();
            M.renderNoteModal({ origin: item.item, size: "lg" }).then(note_difference => {
                if (typeof note_difference === "number" && note_difference !== 0)
                    set_list.replace({ ...item, nb_notes: item.nb_notes + note_difference });
            });
        },
        bulk_insert_equipments: (equipments => {
            // Update the "input" property to be the active element
            equipments = equipments.map(e => ({ ...e, input: active }));
            let unmount = M.renderLoader();
            S.bulk_equipments(equipments)
                .then(() => S.controlListMissionCDM(props.asset)
                    .then(({ data }) => set_control_list(data, "done"))
                    .catch(() => set_control_list([], "error"))
                    .finally(unmount))
                .catch(error => {
                    unmount();
                    M.Alerts.updateError(error);
                });
        }) as G.EquipQuickInputProps["onSubmit"],
        created_datasets: (item: ControlData, amount: number) => set_list.replace({ ...item, datasets_amount: item.datasets_amount + amount }),
    }), [props.asset, formIds, set_list, lg, equip_id, active, set_control_list, check_equipment, new_datasets]);
    //#endregion

    //#region Renders
    const render = React.useMemo(() => ({
        badge: (item: ControlData) => {
            let checked = 0, total = 0;

            const recursive = (elem: typeof item) => {
                if (elem.type !== "building") {
                    total++;
                    if (elem.controlled) checked++;
                }
                elem.children.forEach(recursive);
            };

            recursive(item);

            let color = "secondary";
            if (checked === total) color = "success";
            else if (checked > 0) color = "outline-success";

            return <C.SoftBadge pill bg={color as any}>
                <i className="me-2 fa fa-check"></i>
                {checked} / {total}
            </C.SoftBadge>
        },
        rems: (item: ControlData, btm_margin = true, no_recursive = false) => {
            let nb_rems = 0, color = "grey" as typeof item.remarques.color;
            let className = btm_margin ? "mb-2" : "";
            if (item.nb_notes > 0) className += " ms-2";

            const recursive = (elem: typeof item) => {
                nb_rems += elem.remarques.amount;
                if (elem.remarques.color === "red") color = "red";
                else if (elem.remarques.color === "orange" && color !== "red") color = "orange";
                if (!no_recursive) elem.children.forEach(recursive);
            };
            recursive(item);

            if (nb_rems === 0) return null;
            return <C.SoftBadge className={className} pill style={{ color: "white", backgroundColor: color }}>
                <i className="me-2 fa fa-exclamation-triangle"></i>
                {nb_rems}
            </C.SoftBadge>;
        },
        icon: (item: ControlData) => {
            let fafa_icon = "question", url_icon = "";
            if (item.type === "equip") fafa_icon = "gear";
            else if (item.type === "building") fafa_icon = "building";
            else if (item.type === "emp" && item.emp_type === "parking") fafa_icon = "parking";
            else if (item.type === "emp") {
                if (item.emp_type === "zone") url_icon = RESOURCE.RESOURCE_URL(RESOURCE.ICON_SUB.DEFAULT_ZONE);
                else if (item.emp_type === "floor") url_icon = RESOURCE.RESOURCE_URL(RESOURCE.ICON_SUB.DEFAULT_FLOOR);
                else if (item.emp_type === "local") url_icon = RESOURCE.RESOURCE_URL(RESOURCE.ICON_SUB.DEFAULT_LOCAL);
                else if (item.emp_type === "parking") fafa_icon = "parking";
            }
            if (url_icon) return <img className="me-2" src={url_icon} alt="" style={{ width: "1rem", height: "1rem" }} />;
            else return <i className={`fa fa-${fafa_icon} me-2`}></i>;
        },
        notes: (item: ControlData) => {
            if (item.nb_notes === 0) return null;
            return <button className="btn-none" onClick={e => events.open_notes(e, item)}>
                <C.SoftBadge className="bg-primary" pill style={{ color: "white" }}>
                    <i className="me-2 fa fa-sticky-note"></i>
                    {item.nb_notes}
                </C.SoftBadge>
            </button>;
        }
    }), [events]);

    //#region Search
    const search_options = React.useMemo(() => {
        let options = [] as T.Option<
            Record<"level", number>
            & Record<"icon", React.ReactElement>
            & Record<"children_names", string[]>
            & Record<"last_non_equip_parent" | "qrCode", string>
        >[];

        const recursive = (item: ControlData, level = 0, set_children_names?: (name: string) => void, last_non_equip_parent?: string) => {
            let option = {
                level,
                label: item.name,
                value: item.item,
                children_names: [],
                last_non_equip_parent,
                icon: render.icon(item),
                qrCode: item.equipment?.qrCode || "",
            } as typeof options[number];

            options.push(option);

            let children_names_setter: typeof set_children_names = name => {
                option.children_names.push(name);
                if (set_children_names) set_children_names(name);
            }

            // options.push({ label: item.name, value: item.item, level, icon: render.icon(item) });
            item.children.forEach(child => {
                let last_parent = ""
                if (child.type === "equip") {
                    if (item.type === "equip") last_parent = last_non_equip_parent;
                    else last_parent = item.item;
                }

                children_names_setter(child.name);
                recursive(child, level + 1, children_names_setter, last_parent);
            });
        };

        control_list.forEach(item => recursive(item, 0));
        return options;
    }, [control_list, render]);

    const activate_through_search = React.useCallback((item?: string) => {
        if (!item) set_active(undefined);
        else {
            let option = search_options.find(o => o.value === item);
            if (!option) set_active(undefined);
            else set_active(option.last_non_equip_parent || option.value);
        }
    }, [search_options]);

    const open_path = React.useMemo(() => {
        let last_level_found: number = null;
        let elements = [] as typeof search_options;
        let index_active_elem = search_options.findIndex(o => o.value === active);

        for (let i = index_active_elem; i >= 0; i--) {
            let elem = search_options[i];
            // Loop back to create the path to the active element
            if (last_level_found === null || elem.level < last_level_found) {
                last_level_found = elem.level;
                elements.push(elem);
            }
            if (last_level_found === 0) break;
        }
        // Reverse the array to have the path from the root to the active element
        elements.reverse();
        return elements.map(e => e.value);
    }, [search_options, active]);

    const nav_menu_select = React.useCallback((key?: string) => {
        if (key) {
            if (key !== active) set_active(p => key || p);
            else {
                let last = open_path[open_path.length - 2];
                set_active(last || null);
            }
            set_menu_item(null);
        }
    }, [active, open_path]);

    const render_search_option = React.useCallback<(opt: typeof search_options[number]) => React.ReactElement>(option => {
        return <div style={{ marginLeft: option.level + "rem" }}>
            {option.icon}
            <span className="ms-2">{option.label}</span>
        </div>
    }, []);

    const activate_through_scan = React.useCallback(() => {
        M.askScan({ size: "sm", title: TC.MISSION_CDM_SCAN_QR }).then(qrcode => {
            if (qrcode) {
                let found_id = search_options.find(o => o.qrCode === qrcode)?.value;
                if (found_id) activate_through_search(found_id);
                else M.renderAlert({ type: "warning", message: TC.MISSION_CDM_SCAN_QR_NOT_FOUND });
            }
        });
    }, [search_options, activate_through_search]);
    //#endregion

    const render_elem_list = React.useCallback((list: ControlData[]) => {
        // Remove the equipments from the list
        let equip_free_list = list.filter(cl => cl.type !== "equip");
        // No Emplacements / Buildings
        if (equip_free_list.length === 0) return <C.CenterMessage className="my-3" italics children={TC.NO_ITEM} />;
        else return <Accordion activeKey={open_path} onSelect={nav_menu_select}>
            {equip_free_list.map(cl => <Accordion.Item key={cl.item} eventKey={cl.item}>
                <Accordion.Header>
                    <C.Flex className="w-100 fs-85" justifyContent="between" alignItems="center" >
                        <span>
                            {render.icon(cl)}
                            {cl.name}
                        </span>
                        <C.Flex direction="column" className="me-1" justifyContent="center">
                            {render.rems(cl)}
                            {render.badge(cl)}
                        </C.Flex>
                    </C.Flex>
                </Accordion.Header>
                <Accordion.Body>
                    <ButtonGroup className="w-100 mb-2" size="sm">
                        <C.Button
                            icon="plus"
                            onClick={() => events.add_child_emp(cl)}
                            disabled={props.read_only || cl.type === "equip"}
                        />
                        <C.Button
                            icon="times"
                            variant="danger"
                            onClick={() => events.remove_element(cl)}
                            disabled={props.read_only || cl.children.length > 0}
                        />
                    </ButtonGroup>

                    {active_tree_trail.includes(cl.item) && render_elem_list(cl.children)}
                </Accordion.Body>
            </Accordion.Item>)}
        </Accordion>
    }, [nav_menu_select, render, events, active_tree_trail, open_path, props.read_only]);

    const render_item_content = React.useCallback((item: ControlData) => <Accordion key={"content_" + item.item}>
        {item.type === "equip" && <Accordion.Item eventKey={"prop_" + item.item}>
            <Accordion.Header>
                {lg.getStaticText(TC.MISSION_CDM_PROPERTIES)}
            </Accordion.Header>
            <Accordion.Body>
                <Row>
                    <Col>
                        <C.Form.Select
                            disabled={props.read_only}
                            options={options.criticity}
                            value={item.equipment?.criticity}
                            label={{ _id: equip_id, prop: "criticity" }}
                            onChange={value => events.set_equip_field(item, "criticity", value)}
                        />
                    </Col>
                    <Col>
                        <C.Form.DateTime
                            disabled={props.read_only}
                            value={item.equipment?.dateInstallation}
                            label={{ _id: equip_id, prop: "dateInstallation" }}
                            onChange={value => events.set_equip_field(item, "dateInstallation", value)}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <C.Form.Select
                            options={options.vetusty}
                            disabled={props.read_only}
                            value={item.equipment?.vetusty}
                            label={{ _id: equip_id, prop: "vetusty" }}
                            onChange={value => events.set_equip_field(item, "vetusty", value)}
                        />
                    </Col>
                    <Col>
                        <C.Form.NumField
                            suffix={TC.FORM_SUFFIX_YEARS}
                            value={item.equipment?.expectedLifespan}
                            label={{ _id: equip_id, prop: "expectedLifespan" }}
                            onChange={value => events.set_equip_field(item, "expectedLifespan", value)}
                            disabled={props.read_only || (typeof item.lifespan === "number" && item.equipment?.expectedLifespan === item.lifespan)}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col></Col>
                    <Col>
                        <C.Form.NumField
                            disabled={props.read_only}
                            value={item.equipment?.estimated_end_of_life}
                            label={{ _id: equip_id, prop: "estimated_end_of_life" }}
                            onChange={value => events.set_equip_field(item, "estimated_end_of_life", value)}
                        />
                    </Col>
                </Row>
            </Accordion.Body>
        </Accordion.Item>}

        {item.type !== "building" && <Accordion.Item eventKey={"rem_" + item.item}>
            <Accordion.Header>
                {lg.getStaticText(TC.MISSION_CDM_REMARQUES)} ({item.remarques.amount})
            </Accordion.Header>
            <Accordion.Body>
                <FormPanels.RemarquesPanel
                    _id={item.item}
                    read_only={props.read_only}
                    onChange={() => events.on_change_rem(item.item)}
                    ref={ref => rems_panels_refs.current[item.item] = ref}
                />
            </Accordion.Body>
        </Accordion.Item>}

        <Accordion.Item eventKey={"data_" + item.item}>
            <Accordion.Header>
                <C.Flex className="w-100" alignItems="center" justifyContent="between">
                    <span>
                        {lg.getStaticText(TC.MISSION_CDM_DATA)} ({item.datasets_amount})
                    </span>
                    {item.type === "equip" && item.presets.length > 0 && <C.Button
                        size="sm"
                        variant="sector"
                        className="me-2"
                        icon="hat-wizard"
                        text={TC.MISSION_CDM_MAGIC_CREATION}
                        onClick={e => events.magic_data(item, e)}
                    />}
                </C.Flex>
            </Accordion.Header>
            <Accordion.Body>
                <C.Flex direction="column" className="w-100" style={{ height: "60vh" }}>
                    <DataSets.DataPanel
                        no_link_panel
                        origin={item.item}
                        add_data_now_default
                        readOnly={props.read_only}
                        ref={ref => data_tables_ref.current[item.item] = ref}
                        has_new_datasets={ids => events.created_datasets(item, ids.length)}
                        has_removed_datasets={ids => events.created_datasets(item, -1 * ids.length)}
                    />
                </C.Flex>
            </Accordion.Body>
        </Accordion.Item>
    </Accordion>, [events, equip_id, options, lg, props.read_only]);

    const render_elem_header = React.useCallback((item: ControlData) => {
        let button = item.controlled
            ? { variant: "success", text: TC.MISSION_CDM_CONTROLLED, icon: "check-circle" }
            : { variant: "secondary", text: TC.MISSION_CDM_NOT_CONTROLLED, icon: "circle" };

        const button_click: C.ButtonProps["onClick"] = event => {
            event.stopPropagation();
            events.toggle_control_element(item);
        };

        return <Accordion.Header>
            <C.Flex alignItems="center" className="w-100" justifyContent="between">
                <div>
                    {!props.read_only && <>
                        <C.IconButton
                            no_propagation
                            className="me-2"
                            icon="pencil-alt"
                            onClick={() => events.edit_element(item)}
                        />

                        <C.IconButton
                            no_propagation
                            className="me-2"
                            icon="arrows-alt"
                            onClick={() => events.move_element(item)}
                        />

                        {item.type === "equip" && <C.IconButton
                            no_propagation
                            className="me-2"
                            icon="trash-alt"
                            onClick={() => events.remove_element(item)}
                        />}
                    </>}
                    {item.name}
                </div>
                {item.type !== "building" && <C.Flex className="me-2" alignItems="center" justifyContent="center">
                    <div className="me-2">
                        {render.notes(item)}
                        {render.rems(item, false, true)}
                    </div>
                    <C.Button
                        size="sm"
                        icon={button.icon}
                        text={button.text}
                        onClick={button_click}
                        variant={button.variant}
                        disabled={props.read_only}
                    />
                </C.Flex>}
            </C.Flex>
        </Accordion.Header>;
    }, [events, render, props.read_only]);
    //#endregion

    return <C.Spinner status={status}>
        <Row className="mb-1 me-2">
            <Col md={3}>
                <C.Form.Select
                    no_clear_btn
                    options={search_options}
                    onChange={activate_through_search}
                    label={TC.GLOBAL_SEARCH_PLACEHOLDER}
                    suffix_button={{ icon: "qrcode", tip: TC.MISSION_CDM_SCAN_QR, onClick: activate_through_scan }}
                    typeahead={{
                        renderItem: render_search_option,
                        filterBy: (option, text) => {
                            let is_similar = TB.areStringSimilar(text, option.label, { inclusion: "reference" });
                            return is_similar || (option as typeof search_options[number]).children_names.some(name => TB.areStringSimilar(text, name, { inclusion: "reference" }));
                        },
                    }}
                />
                <div className="pe-0" style={{ height: "77.5vh", overflowY: "auto" }}>
                    {render_elem_list(control_list)}
                </div>
            </Col>

            <Col>
                {active_item === null
                    ? <C.CenterMessage children={TC.MISSION_CDM_NO_ACTIVE} />
                    : <Accordion key={"acc_" + active} onSelect={set_menu_item}>
                        <Accordion.Item eventKey={active}>
                            {render_elem_header(active_item)}
                            <Accordion.Body>
                                {menu_item === active_item.item && render_item_content(active_item)}
                            </Accordion.Body>
                        </Accordion.Item>

                        {!props.read_only && <Accordion.Item key={active} eventKey="quick_input">
                            <Accordion.Header>
                                <C.Flex className="w-100" alignItems="center" justifyContent="between">
                                    <span>
                                        {lg.getStaticText(FP.EQUIPEMENT_FORM)} ({active_item_equip_nb.nb_equip})
                                    </span>
                                    <C.Button
                                        size="sm"
                                        className="me-2"
                                        icon="check-circle"
                                        variant="outline-success"
                                        text={TC.MISSION_CDM_CONTROL_ALL}
                                        hidden={active_item_equip_nb.nb_equip === 0}
                                        onClick={e => events.control_sub_equip(active_item, e)}
                                        disabled={active_item_equip_nb.controlled_equip === active_item_equip_nb.nb_equip}
                                    />
                                </C.Flex>
                            </Accordion.Header>
                            <Accordion.Body>
                                {menu_item === "quick_input" && <G.EquipmentQuickInput
                                    hide_loc_col
                                    context={{ roots: active_item.item }}
                                    onSubmit={events.bulk_insert_equipments}
                                />}
                            </Accordion.Body>
                        </Accordion.Item>}

                        {active_item.children
                            .filter(c => c.type === "equip")
                            .map(c => <Accordion.Item key={c.item} eventKey={c.item}>
                                {render_elem_header(c)}
                                <Accordion.Body>
                                    {menu_item === c.item && render_item_content(c)}
                                </Accordion.Body>
                            </Accordion.Item>)}
                    </Accordion>}
            </Col>
        </Row>
    </C.Spinner>;
}

export default ControlList;