import React from "react";
import * as H from "../../hooks";
import * as C from "../../Common";
import * as S from "../../services";
import * as BS from "react-bootstrap";
import { T, TABS, TC, URL } from "../../Constants";
import { embedDashboard } from '@superset-ui/embedded-sdk';

type Panels = "elec" | "co2" | "water" | "waste";
type Config = ReturnType<T.API.Access.Auth.GetSupersetToken>;
type PanelsOptions = T.Option<Record<"container", React.RefObject<HTMLDivElement>>, Panels>;

type ConfiguratorProps = {
    /** For which panel is it active ? */
    panel: keyof Config["session"]["dashboard_params"];
    /** The configuration object */
    setting?: T.Superset.Config[keyof T.Superset.Config];
    /** The label of the configuration panel */
    title: string;
    /** Callback to update the configuration */
    update_config: (value: T.Superset.Config[keyof T.Superset.Config]) => void;
}

const Dashboard_Ids: Record<Panels, string> = {
    co2: "8ccffa7e-b119-4a4f-87fa-7dd0c1770e10",
    elec: "d5a69b9f-d187-4e6c-8e45-dc5ec72a3724",
    waste: "8333b4fc-728a-408a-ad83-4bddb0353e9f",
    water: "bc832402-3ae4-4f77-a62d-31beed9aa62b",
}

const options_filters = [
    { value: "tags", label: TC.DASH_ENV_FILTER_TAGS, only_energy: false, ask_segment_select: true },
    { value: "energy", label: TC.DASH_ENV_FILTER_ENERGY, only_energy: true, ask_segment_select: false },
    { value: "country", label: TC.DASH_ENV_FILTER_COUNTRY, only_energy: false, ask_segment_select: false },
    { value: "actif_type", label: TC.DASH_ENV_FILTER_AFFECT, only_energy: false, ask_segment_select: false },
] as T.Option<Record<"only_energy" | "ask_segment_select", boolean>, keyof T.Superset.Config[keyof T.Superset.Config]["filters"]>[];

const Environment: React.FC = () => {
    const lg = H.useLanguage();
    const [roots] = H.useRoots();
    H.useCrumbs(TC.TAB_ENV_DASHBOARD);
    const show_config = H.useBoolean(false);
    H.useAuth({ tabName: TABS.ENV_DASHBOARD });
    const co2_container = React.useRef<HTMLDivElement>(null);
    const elec_container = React.useRef<HTMLDivElement>(null);
    const water_container = React.useRef<HTMLDivElement>(null);
    const waste_container = React.useRef<HTMLDivElement>(null);
    const [settings, set_settings] = React.useState<T.Superset.Config>(null);
    const [config, set_config, status] = H.useAsyncState<Config>({ token: "", session: null });

    const panels = React.useMemo<PanelsOptions[]>(() => [
        { container: elec_container, value: "elec", label: TC.SUPERSET_DASH_ELEC_TITLE },
        { container: co2_container, value: "co2", label: TC.SUPERSET_DASH_CO2_TITLE },
        { container: water_container, value: "water", label: TC.SUPERSET_DASH_WATER_TITLE },
        { container: waste_container, value: "waste", label: TC.SUPERSET_DASH_WASTE_TITLE },
    ], []);

    const configurator = React.useMemo<Pick<ConfiguratorProps, "panel" | "title">[]>(() => [
        { panel: "energy", title: TC.DASH_ENV_CONFIG_PANEL_ENERGY },
        { panel: "water", title: TC.DASH_ENV_CONFIG_PANEL_WATER },
        { panel: "wastes", title: TC.DASH_ENV_CONFIG_PANEL_WASTE },
    ], []);

    const update_config = React.useMemo(() => ({
        local: (prop: ConfiguratorProps["panel"], settings: ConfiguratorProps["setting"]) => set_settings(prev => ({ ...prev, [prop]: settings })),
        confirm: () => {
            /* TODO Update session */
        }
    }), []);

    //#region Data Load & Setup
    React.useEffect(() => {
        let isSubscribed = true;
        let dashboard_ids = Object.values(Dashboard_Ids);

        S.getSupersetToken({ context: roots, dashboard_ids })
            .then(({ data }) => isSubscribed && set_config(data, "done"))
            .catch(() => isSubscribed && set_config({ token: "", session: null }, "error"));
        return () => {
            isSubscribed = false;
            set_config({ token: "", session: null }, "error");
        }
    }, [set_config, roots]);

    // React.useEffect(() => {
    //     if (config.token) panels.forEach(panel => {
    //         embedDashboard({
    //             id: Dashboard_Ids[panel.value],
    //             mountPoint: panel.container.current,
    //             supersetDomain: URL.SUPERSET_RE_ROUTED,
    //             fetchGuestToken: () => new Promise(r => r(config.token)),
    //             dashboardUiConfig: {
    //                 hideTitle: true,
    //                 hideChartControls: false,
    //                 filters: { visible: false, expanded: false },
    //                 urlParams: { standalone: 3, show_filters: 0 },
    //             },
    //         })

    //         let iframe = panel.container.current.querySelector('iframe');
    //         if (iframe) {
    //             iframe.style.width = '100%';
    //             iframe.style.height = "955px";
    //         }
    //     });
    // }, [config.token, panels]);
    //#endregion

    return <C.Spinner height="flex-grow-1" spin_text={TC.SUPERSET_LOAD_CONNECTING} status={status}>
        <C.Flex className="flex-grow-1" direction="column">
            {/* A button to open the configuration panel */}
            <C.Flex justifyContent="between" className="mb-2">
                <div>
                    {lg.getStaticText(TC.SUPERSET_DASH_JUMP_TO)} :
                    {panels.map(panel => <C.Button
                        size="sm"
                        variant="link"
                        className="mx-1"
                        key={panel.value}
                        text={panel.label}
                        onClick={() => panel.container.current?.scrollIntoView?.({ behavior: "smooth" })}
                    />)}
                </div>

                <C.Button disabled={!config.token} icon="cog" size="sm" text={TC.SUPERSET_DASH_CONFIG_BUTTON} onClick={show_config.setTrue} />
            </C.Flex>

            {panels.map(panel => <div key={panel.value}>
                <C.Title text={panel.label} level={4} />
                <div ref={panel.container} />
            </div>)}
        </C.Flex>

        <BS.Offcanvas enforceFocus={false} className="w-50" show={show_config.value} onHide={show_config.setFalse}>
            <BS.Offcanvas.Header closeButton>
                <BS.Offcanvas.Title>{lg.getStaticText(TC.SUPERSET_DASH_CONFIG_TITLE)}</BS.Offcanvas.Title>
            </BS.Offcanvas.Header>
            <BS.Offcanvas.Body>

                <C.Flex className="h-100" direction="column" justifyContent="between">


                    <BS.Accordion className="flex-grow-1">
                        {configurator.map(c => <BS.Accordion.Item key={c.panel} eventKey={c.panel}>
                            <BS.Accordion.Header children={lg.getStaticText(c.title)} />
                            <BS.Accordion.Body>
                                <Configurator
                                    panel={c.panel}
                                    title={c.title}
                                    setting={settings?.[c.panel]}
                                    update_config={settings => update_config.local(c.panel, settings)}
                                />
                            </BS.Accordion.Body>
                        </BS.Accordion.Item>)}
                    </BS.Accordion>


                    <C.Flex className="my-3" justifyContent="end">
                        <C.Button
                            icon="check"
                            onClick={update_config.confirm}
                            text={TC.SUPERSET_DASH_CONFIG_CONFIRM}
                        />
                    </C.Flex>

                </C.Flex>

            </BS.Offcanvas.Body>
        </BS.Offcanvas>

    </C.Spinner>;
};

export default Environment;

const Configurator: React.FC<ConfiguratorProps> = ({ update_config, ...props }) => {
    const [fav_data, { setFilters }] = H.useFavorite({ applyFav: update_config, initialState: props.setting, origin: props.panel + "_configurator", variant: "sector", size: "sm" });

    const update = React.useCallback<typeof update_config>(config => {
        setFilters(config);
        update_config(config);
    }, [update_config, setFilters]);

    const type_filters = React.useMemo(() => {
        if (props.panel === "energy") return options_filters;
        else return options_filters.filter(f => !f.only_energy);
    }, [props.panel]);

    //#region Filters
    const filters = React.useMemo(() => {
        let active_filters = Object.entries(props.setting?.filters || {});
        // Not all type of filters already have a value set, so we need to add an empty one
        if (active_filters.length < type_filters.length) active_filters.push(["", []]);
        return active_filters;
    }, [props.setting, type_filters]);

    const get_filter_options = React.useCallback((current: string) => {
        // The list of filters already set
        let set_filters = Object.keys(props.setting?.filters || {});
        // The list of filters that can be set
        let available_filters = type_filters.filter(f => !set_filters.includes(f.value) || f.value === current);
        return available_filters;
    }, [props.setting, type_filters]);

    const change_filter = React.useMemo(() => ({
        remove: (key: keyof T.Superset.Config[keyof T.Superset.Config]["filters"]) => {
            let new_settings: ConfiguratorProps["setting"] = {
                filters: props.setting?.filters || {},
                segmentation: props.setting?.segmentation || [],
            };
            delete new_settings.filters[key];
            update(new_settings);
        },
        update_value: (key: keyof T.Superset.Config[keyof T.Superset.Config]["filters"], value: string[]) => {
            let new_settings: ConfiguratorProps["setting"] = {
                filters: props.setting?.filters || {},
                segmentation: props.setting?.segmentation || [],
            };
            new_settings.filters[key] = value;
            update(new_settings);
        },
        update_key: (old_key?: keyof T.Superset.Config[keyof T.Superset.Config]["filters"], new_key?: keyof T.Superset.Config[keyof T.Superset.Config]["filters"]) => {
            let new_settings: ConfiguratorProps["setting"] = {
                filters: props.setting?.filters || {},
                segmentation: props.setting?.segmentation || [],
            };
            // There was a key, so we need to remove it
            if (old_key) delete new_settings.filters[old_key];
            // There is a new key, so we need to add it
            if (new_key) new_settings.filters[new_key] = [];
            // Update the settings
            update(new_settings);
        }
    }), [props.setting, update]);
    //#endregion

    //#region Segmentations
    const segmentations = React.useMemo(() => {
        let active = [...(props.setting?.segmentation || [])];
        // Not all type of segmentations already have a value set, so we need to add an empty one
        if (active.length < type_filters.length) active.push(null);
        // Reformat the active segmentations
        let active_objects = active.map(s => {
            if (!s) return { value: [], key: "", show_select: false };
            let show_select = type_filters.find(f => typeof s === "string" ? f.value === s : f.value === "tags")?.ask_segment_select;
            return typeof s === "string" ? { value: [], key: s, show_select } : { value: s.tags, key: "tags", show_select }
        });
        return active_objects;
    }, [props.setting, type_filters]);

    const get_segmentation_options = React.useCallback((current: string) => {
        // The list of segmentations that can be set
        let available_segmentations = type_filters.filter(f => {
            // Check if there is already a value for this filter
            let segmentation_value = segmentations.some(s => s.key === f.value);
            // Show the segmentation if not already used, or is used but is also the current one
            return !segmentation_value || f.value === current;
        });
        return available_segmentations;
    }, [segmentations, type_filters]);

    const change_segmentation = React.useMemo(() => ({
        remove: (key: keyof T.Superset.Config[keyof T.Superset.Config]["filters"]) => {
            let new_settings: ConfiguratorProps["setting"] = {
                filters: props.setting?.filters || {},
                segmentation: props.setting?.segmentation || [],
            };
            // Remove the segmentation
            if (key === "tags") new_settings.segmentation = new_settings.segmentation.filter(s => typeof s === "string");
            else new_settings.segmentation = new_settings.segmentation.filter(s => s !== key);
            update(new_settings);
        },
        update_value: (key: keyof T.Superset.Config[keyof T.Superset.Config]["filters"], value: string[]) => {
            let new_settings: ConfiguratorProps["setting"] = {
                filters: props.setting?.filters || {},
                segmentation: props.setting?.segmentation || [],
            };
            // Only update the tags
            if (key === "tags") {
                new_settings.segmentation = new_settings.segmentation.map(s => typeof s !== "string" ? { tags: value } : s);
                update(new_settings);
            }
        },
        update_key: (old_key?: keyof T.Superset.Config[keyof T.Superset.Config]["filters"], new_key?: keyof T.Superset.Config[keyof T.Superset.Config]["filters"]) => {
            let new_settings: ConfiguratorProps["setting"] = {
                filters: props.setting?.filters || {},
                segmentation: props.setting?.segmentation || [],
            };

            // There was a key, so we need to remove it
            if (old_key) {
                if (old_key === "tags") new_settings.segmentation = new_settings.segmentation.filter(s => typeof s !== "string");
                else new_settings.segmentation = new_settings.segmentation.filter(s => s !== old_key);
            }
            // There is a new key, so we need to add it
            if (new_key) {
                if (new_key === "tags") new_settings.segmentation.push({ tags: [] });
                else new_settings.segmentation.push(new_key);
            }
            // Update the settings
            update(new_settings);
        }
    }), [props.setting, update]);
    //#endregion

    return <div>
        <div className="mb-2">
            <C.Flex justifyContent="between" alignItems="center">
                <C.Title text={TC.DASH_ENV_CONFIG_FILTERS} level={4} />
                {fav_data.dropdown}
            </C.Flex>

            {filters.map(([key, value], i) => <C.Flex key={key || i}>
                <C.Flex className="flex-grow-1" alignItems="start" justifyContent="between">
                    <C.Form.Select
                        value={key}
                        no_clear_btn
                        noBottomMargin
                        customClass="w-100 mb-1"
                        options={get_filter_options(key)}
                        label={i === 0 ? TC.DASH_ENV_PROPERTY_LABEL : undefined}
                        onChange={value => change_filter.update_key(key as any, value)}
                    />
                    <C.Form.Select
                        multiple
                        value={value}
                        noBottomMargin
                        disabled={!key}
                        options={[/* TODO */]}
                        customClass="w-100 mb-1 mx-2"
                        typeahead={{ select_all_on_enter: true }}
                        label={i === 0 ? TC.DASH_ENV_OPTION_LABEL : undefined}
                        onChange={value => change_filter.update_value(key as any, value)}
                    />
                </C.Flex>
                <C.Flex alignItems="center" justifyContent="center">
                    <C.Button
                        icon="times"
                        disabled={!key}
                        variant="danger"
                        className={i === 0 ? "mt-3" : undefined}
                        onClick={() => change_filter.remove(key as any)}
                    />
                </C.Flex>
            </C.Flex>)}
        </div>

        <div>
            <C.Title text={TC.DASH_ENV_CONFIG_GROUPING} level={4} />

            {segmentations.map((seg, i) => <C.Flex key={seg.key || i}>
                <C.Flex className="flex-grow-1" alignItems="start" justifyContent="between">
                    <C.Form.Select
                        no_clear_btn
                        noBottomMargin
                        value={seg.key}
                        customClass="w-100 mb-1"
                        options={get_segmentation_options(seg.key)}
                        label={i === 0 ? TC.DASH_ENV_PROPERTY_LABEL : undefined}
                        onChange={value => change_segmentation.update_key(seg.key as any, value)}
                    />
                    <C.Form.Select
                        multiple
                        noBottomMargin
                        value={seg.value}
                        options={[/* TODO */]}
                        customClass="w-100 mb-1 mx-2"
                        disabled={!seg.key || !seg.show_select}
                        typeahead={{ select_all_on_enter: true }}
                        label={i === 0 ? TC.DASH_ENV_OPTION_LABEL : undefined}
                        onChange={value => change_segmentation.update_value(seg.key as any, value)}
                    />
                </C.Flex>
                <C.Flex alignItems="center" justifyContent="center">
                    <C.Button
                        icon="times"
                        variant="danger"
                        disabled={!seg.key}
                        className={i === 0 ? "mt-3" : undefined}
                        onClick={() => change_segmentation.remove(seg.key as any)}
                    />
                </C.Flex>
            </C.Flex>)}
        </div>
    </div >;
}