import React, { useMemo } from "react";
/** Needs to update index in react-bootstrap files */
import { Carousel, CarouselRef } from "react-bootstrap";

export type Ref = CarouselRef;

export type ImgCarouselProps = {
    /** Show the Carousel previous and next arrows for changing the current slide */
    showControls?: boolean;
    /** Do the images slide automatically ? */
    autoplay?: boolean;
    /** The time in milliseconds to show each images */
    interval?: number;
    /** The images to display */
    images: {
        /** The name of the image */
        name: string;
        /** The source of the image, url or base 64 */
        source: string;
    }[];
}

const ImgCarousel = React.forwardRef<CarouselRef, ImgCarouselProps>((props, ref) => {

    const interval = useMemo(() => {
        if (!props.autoplay) return null;
        let interval = props.interval;
        if (typeof interval !== "number" || interval < 0) interval = 5000;
        return interval;
    }, [props.autoplay, props.interval]);


    return <Carousel variant="dark" ref={ref} controls={props.showControls} interval={interval}>
        {props.images.map((img, index) => <Carousel.Item key={index}>
            <img
                alt=""
                src={img.source}
                className="d-block w-100"
            />
            <div className="text-center fs-85">
                <h5>{img.name}</h5>
            </div>
        </Carousel.Item>)}
    </Carousel>;
});

export default ImgCarousel;