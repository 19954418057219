import React from "react";
import * as M from "../../Modal";
import * as H from "../../../hooks";
import * as C from "../../../Common";
import * as BS from "react-bootstrap";
import * as S from "../../../services";
import * as FTB from "../../../Constants/FileToolBox";
import { MISSION, T, TB, TC, URL } from "../../../Constants";

//#region Intervenants
export type IntervenantsProps = {
    /** Is the table disabled */
    disabled?: boolean;
    /** Callback for changes */
    onChange: (array: T.Mission["intervenants"]) => void;
    /** Current value */
    value: T.Mission["intervenants"];
    /** The current list of errors */
    errors: T.Errors<T.Mission["intervenants"][number]>[];
}

export const Intervenants: React.FC<IntervenantsProps> = props => {
    const lg = H.useLanguage();

    const event = React.useMemo(() => ({
        remove: (index: number) => props.onChange?.(props.value.filter((v, i) => i !== index)),
        add: () => props.onChange?.(props.value.concat({
            tel: "",
            name: "",
            mail: "",
            company: "",
            present: false,
            role: "advisor",
        })),
        set: (value: any, index: number, key: keyof T.Mission_Intervenant) => {
            props.onChange?.(props.value.map((v, i) => {
                if (i !== index) return v;
                else if (key === "role" && value !== "other") return { ...v, [key]: value, other: undefined };
                else return { ...v, [key]: value };
            }));
        },
    }), [props]);

    return <>
        <BS.Row>
            <BS.Col>
                <C.Flex>
                    <h4>{lg.getStaticElem(TC.MISSION_INTERV_TITLE)}</h4>
                    {!props.disabled && <C.Button onClick={event.add} className="ms-2" icon="plus" size="sm" />}
                </C.Flex>
            </BS.Col>
        </BS.Row>
        <BS.Row>
            <BS.Row className="text-center fs-85 align-items-center fw-bold mb-1">
                <BS.Col md={3}>{lg.getStaticElem(TC.MISSION_INTERV_ROLE)}</BS.Col>
                <BS.Col>{lg.getStaticElem(TC.MISSION_INTERV_COMPANY)}</BS.Col>
                <BS.Col>{lg.getStaticElem(TC.MISSION_INTERV_NAME)}</BS.Col>
                <BS.Col>{lg.getStaticElem(TC.MISSION_INTERV_MAIL)}</BS.Col>
                <BS.Col>{lg.getStaticElem(TC.MISSION_INTERV_TEL)}</BS.Col>
                <BS.Col md={1}>{lg.getStaticElem(TC.MISSION_INTERV_PRESENT)}</BS.Col>
                <BS.Col md={1}></BS.Col>
            </BS.Row>
            {props.value.length === 0
                ? <C.CenterMessage italics children={TC.GLOBAL_NONE} />
                : props.value.map((i, index) => <BS.Row key={index}>
                    <BS.Col md={3}>
                        <C.Flex>
                            <C.Form.Select
                                hideLabel
                                value={i.role}
                                disabled={props.disabled}
                                customClass="flex-grow-1"
                                typeahead={{ hideClearButton: true }}
                                options={MISSION.RoleIntervenantsOptions}
                                onChange={v => event.set(v, index, "role")}
                                error={{ code: props.errors?.[index]?.role }}
                            />
                            {i.role === "other" && <C.Form.TextField
                                hideLabel
                                value={i.other}
                                disabled={props.disabled}
                                onChange={v => event.set(v, index, "other")}
                                error={{ code: props.errors?.[index]?.other }}
                            />}
                        </C.Flex>
                    </BS.Col>
                    <BS.Col>
                        <C.Form.TextField
                            hideLabel
                            value={i.company}
                            disabled={props.disabled}
                            onChange={v => event.set(v, index, "company")}
                            error={{ code: props.errors?.[index]?.company }}
                        />
                    </BS.Col>
                    <BS.Col>
                        <C.Form.TextField
                            hideLabel
                            value={i.name}
                            disabled={props.disabled}
                            onChange={v => event.set(v, index, "name")}
                            error={{ code: props.errors?.[index]?.name }}
                        />
                    </BS.Col>
                    <BS.Col>
                        <C.Form.TextField
                            isEmail
                            hideLabel
                            value={i.mail}
                            disabled={props.disabled}
                            onChange={v => event.set(v, index, "mail")}
                            error={{ code: props.errors?.[index]?.mail }}
                        />
                    </BS.Col>
                    <BS.Col>
                        <C.Form.TextField
                            hideLabel
                            value={i.tel}
                            disabled={props.disabled}
                            onChange={v => event.set(v, index, "tel")}
                            error={{ code: props.errors?.[index]?.tel }}
                        />
                    </BS.Col>
                    <BS.Col md={1}>
                        <C.Flex className="w-100 h-100" justifyContent="center" alignItems="center">
                            <C.Form.CheckBox
                                hideLabel
                                value={i.present}
                                check_type="switch"
                                disabled={props.disabled}
                                onChange={v => event.set(v, index, "present")}
                            />
                        </C.Flex>
                    </BS.Col>
                    <BS.Col md={1}>
                        <C.Button disabled={props.disabled} onClick={() => event.remove(index)} variant="danger" icon="trash" size="sm" />
                    </BS.Col>
                </BS.Row>)}
        </BS.Row>
    </>;
}
//#endregion

//#region Visits
type VisitsProps = {
    /** A list of intervenants */
    intervenants: T.Mission["intervenants"];
    /** The visits */
    visit: T.Mission_Task_Visit;
    /** Callback for changes */
    onChange: (visit: T.Mission_Task_Visit) => void;
    /** A list of current errors */
    errors: T.Errors<T.Mission_Task_Visit>;
    /** Is the visit disabled */
    disabled?: boolean;
}

type InviteStatus = T.Mission_Task_Visit["invitations"][number]["status"];

export const InviteStatusOptions = [
    { label: TC.MISSION_INVITE_ACCEPTED, variant: "success", value: "accepted" },
    { label: TC.MISSION_INVITE_DENIED, variant: "danger", value: "denied" },
    { label: TC.MISSION_INVITE_SENT, variant: "info", value: "sent" },
    { label: TC.MISSION_INVITE_TO_SEND, variant: "primary", value: "to_send" },
] as T.Option<{ variant: T.ColorTypes }, InviteStatus>[];

const Visits: React.FC<VisitsProps> = ({ onChange, ...props }) => {
    const lg = H.useLanguage();

    const get_status = React.useCallback((mail: string) => {
        return props.visit?.invitations?.filter?.(i => i.mail === mail)?.[0]?.status || "to_send";
    }, [props.visit?.invitations]);

    const change_status = React.useCallback((mail: string, status: InviteStatus) => {
        let new_invitations = props.visit?.invitations || [];

        // Update an existing mail
        if (new_invitations.some(i => i.mail === mail)) new_invitations = new_invitations
            .map(i => i.mail === mail ? { mail, status } : i);
        // Add a mail
        else new_invitations = new_invitations.concat({ mail, status });

        onChange?.({ ...props.visit, invitations: new_invitations });
    }, [onChange, props.visit]);

    return <BS.Row>
        <BS.Col>
            <BS.Row>
                <BS.Col>
                    <C.Form.DateTime
                        enableTime
                        disabled={props.disabled}
                        label={TC.MISSION_VISIT_FROM}
                        value={props.visit?.start_date}
                        error={{ code: props.errors?.start_date }}
                        onChange={start_date => onChange?.({ ...props.visit, start_date })}
                    />
                </BS.Col>
            </BS.Row>
            <BS.Row>
                <BS.Col>
                    <C.Form.DateTime
                        enableTime
                        disabled={props.disabled}
                        label={TC.MISSION_VISIT_TO}
                        value={props.visit?.end_date}
                        error={{ code: props.errors?.end_date }}
                        onChange={end_date => onChange?.({ ...props.visit, end_date })}
                    />
                </BS.Col>
            </BS.Row>
        </BS.Col>
        <BS.Col>
            <BS.Row className="fw-bold">
                <BS.Col>
                    {lg.getStaticElem(TC.MISSION_INTERV_TITLE)}
                </BS.Col>
                <BS.Col>
                    {lg.getStaticElem(TC.MISSION_INVITATION)}
                </BS.Col>
            </BS.Row>
            {props.intervenants.length === 0
                ? <C.CenterMessage>
                    {TC.GLOBAL_NONE}
                </C.CenterMessage>
                : props.intervenants.map((i, index) => <BS.Row className="my-1 align-items-center" key={i.mail || index}>
                    <BS.Col>
                        {TB.validateMail(i.mail)
                            ? i.name || i.mail
                            : <C.ErrorBanner no_margins type="danger" message={TC.REG_MAIL_INVALID} size="sm" />}
                    </BS.Col>
                    <BS.Col>
                        <C.ButtonSelect
                            size="sm"
                            default_value="to_send"
                            value={get_status(i.mail)}
                            options={InviteStatusOptions}
                            disabled={props.disabled || !TB.validateMail(i.mail)}
                            onChange={status => change_status(i.mail, status as any)}
                        />
                    </BS.Col>
                </BS.Row>)
            }
        </BS.Col>
    </BS.Row>;
}
//#endregion

//#region Report
type ReportProps = {
    /** The id of the mission */
    mission_id: string;
    /** The id of the asset under review */
    asset: string;
    /** The report data */
    report: T.Mission_Task_Report;
    /** The type of mission */
    mission_type: T.Mission["type"];
    /** The language of the mission's report */
    lang: T.Mission["lang"];
    /** A list of intervenants */
    intervenants: T.Mission["intervenants"];
    /** Callback for changes */
    onChange: (report: T.Mission_Task_Report) => void;
    /** Is the report task disabled */
    disabled?: boolean;
    /** Callback to the main mission form for checking the mistakes */
    check_errors: TasksProps["check_errors"];
    /** Callback to update and save the main mission form */
    save: ReportProps["onChange"];
}

const Report: React.FC<ReportProps> = props => {
    const lg = H.useLanguage();
    const [{ userId }] = H.useAuth();

    const set_as_sent = React.useCallback((mail: string) => {
        props.onChange({
            ...props.report,
            versions: props.report.versions.map((v, i) => {
                // Update the last version
                if (i === props.report.versions.length - 1) return {
                    ...v,
                    sent: v.sent.concat({ date: new Date().toISOString(), mail }),
                };
                else return v;
            }),
        });
    }, [props]);

    const render_user_report = React.useCallback((mail: string, version: T.Mission_Task_Report["versions"][number], isLast = false) => {
        if (!TB.validateMail(mail)) return null;
        let status = version.sent.filter(s => s.mail === mail)[0];

        if (status) return <C.Button variant="info" disabled>
            <C.Flex direction="column">
                <span>{lg.getStaticElem(TC.MISSION_REPORT_SENT)}</span>
                <span>{status.date}</span>
            </C.Flex>
        </C.Button>;
        else if (isLast) return <C.Button disabled={props.disabled} text={TC.MISSION_REPORT_SEND} onClick={() => set_as_sent(mail)} />;
        else return null;
    }, [set_as_sent, lg, props.disabled]);

    const report = React.useMemo(() => ({
        new_normal: (isFinal = false) => M.renderMissionReportModal({ mission_id: props.mission_id }).then(note => {
            if (note) {
                let final_promise = new Promise<void>((resolve, reject) => {
                    if (isFinal) {
                        let promise: Promise<any> = null;
                        if (props.mission_type === "cdm" || props.mission_type === "edl_tech") promise = S.finalReportKPIs(props.mission_id);
                        else if (props.mission_type === "edl_reg") promise = S.finalReportRegKPIs(props.mission_id);
                        // Wait for the promise to end
                        if (promise) promise.then(() => resolve()).catch(reject);
                        else resolve();
                    }
                    else resolve();

                    if (!isFinal || (props.mission_type !== "cdm" && props.mission_type !== "edl_tech")) resolve();
                    else S.finalReportKPIs(props.mission_id).then(() => resolve()).catch(reject);
                });
                final_promise.then(() => {
                    let updated_report = {
                        ...props.report,
                        versions: props.report.versions.concat({
                            sent: [],
                            user: userId,
                            final: isFinal,
                            notes: note.notes,
                            report: note.report,
                            date: new Date().toISOString(),
                        })
                    } as T.Mission_Task_Report;

                    if (isFinal) props.save(updated_report);
                    else props.onChange(updated_report);
                }).catch(M.Alerts.updateError);
            }
        }),
        new_final: () => {
            // Check if mission form is OK to save
            let errors = props.check_errors(true);
            // Found errors
            if (Object.entries(errors).length > 0) M.renderAlert({ type: "warning", message: TC.REPORT_ERRORS_STILL });
            // No errors
            else M.askConfirm({ title: TC.REPORT_FINAL_VERSION, text: TC.REPORT_FINAL_REPORT_CONFIRM })
                .then(confirmed => confirmed && report.new_normal(true))
        },
    }), [props, userId]);

    const generate_report = React.useCallback(() => {
        // Check the data of the mission and get the info needed for the generation of the report
        S.missionCheck(props.mission_id).then(({ data }) => {
            // Mission doesn't exists, or wrong type, or inaccessible
            if (data.state === "non-existent") M.Alerts.haveNotRight();
            // There are missing data
            else if (data.state === "incomplete") {
                // Make a list of the errors found
                let text = React.createElement("ul", null, data.errors.map((error, i) => <li key={i}>
                    {lg.getStaticText(error.error)} - {lg.getStaticText(error.label)} {error.extra ? ` (${lg.getStaticText(error.extra)})` : ""}
                </li>));
                // Show the errors to the user
                M.askConfirm({ text, no_buttons: true, title: TC.NRJ_REPORT_FAILED_CHECK });
            }
            // The report is a single file
            else if (data.report.templates.length === 1) {
                let template = data.report.templates[0];
                FTB.downloadJsReportFile(template.code, { extension: template.file_type, name: data.report.name }, { mission: props.mission_id, asset: props.asset, lang: props.lang });
            }
            // The report is a zip file
            else {
                let unmount = M.renderLoader(TC.GLOBAL_GENERATING_FILE);
                let files = [] as Parameters<typeof S.multi_file_report>[0];

                for (let template of data.report.templates) files.push({
                    name: data.report.name,
                    template: template.code,
                    file_type: template.file_type,
                    params: { mission: props.mission_id, asset: props.asset, lang: props.lang },
                });

                S.multi_file_report(files).then(buffer => {
                    TB.asyncArrayBufferToBase64(buffer.data)
                        .then(base64 => TB.downloadFile(base64, data.report.name, "zip"))
                        .catch(M.Alerts.file_generation_fail)
                        .finally(unmount);
                })
                    .catch(e => {
                        unmount();
                        M.Alerts.file_generation_fail(e);
                    });
            }
        }).catch(M.Alerts.loadError);
    }, [lg, props.asset, props.mission_id, props.lang]);

    if (!TB.mongoIdValidator(props.mission_id)) return <C.CenterMessage children={TC.REPORT_UNSAVED_MISSION} />;
    return <>
        <BS.Row className="mb-3">
            <BS.Col>
                <BS.Row className="mb-3">
                    <BS.Col>
                        <h3>{lg.getStaticElem(TC.MISSION_VERSION_TITLE)} ({props.report.versions.length})</h3>
                    </BS.Col>
                    <BS.Col md={6}>
                        <C.Flex className="w-100" justifyContent="end" alignItems="center">
                            <C.Button disabled={props.disabled} className="mx-1" size="sm" variant="outline-primary" icon="download" onClick={generate_report}>
                                {TC.MISSION_REPORT_GENERATE}
                            </C.Button>
                            <C.Button disabled={props.disabled} className="mx-1" size="sm" icon="plus" onClick={() => report.new_normal()}>
                                {TC.MISSION_REPORT_NEW_VERSION}
                            </C.Button>
                            <C.Button disabled={props.disabled} className="mx-1" size="sm" icon="plus" variant="danger" onClick={() => report.new_final()}>
                                {TC.MISSION_REPORT_FINAL_VERSION}
                            </C.Button>
                        </C.Flex>
                    </BS.Col>
                </BS.Row>
                <BS.Row className="text-center">
                    <BS.Col>#</BS.Col>
                    <BS.Col>{lg.getStaticElem(TC.MISSION_REPORT_DATE)}</BS.Col>
                    <BS.Col>{lg.getStaticElem(TC.MISSION_REPORT_NAME)}</BS.Col>
                    <BS.Col md={4}>{lg.getStaticElem(TC.MISSION_REPORT_NOTE)}</BS.Col>
                    <BS.Col>{lg.getStaticElem(TC.MISSION_REPORT_FILE)}</BS.Col>
                </BS.Row>
                {props.report.versions.length === 0
                    ? <BS.Row>
                        <C.CenterMessage italics className="my-2">
                            {TC.GLOBAL_NONE}
                        </C.CenterMessage>
                    </BS.Row>
                    : props.report.versions.map((v, i) => <BS.Row key={i}>
                        <BS.Col>{i + 1}</BS.Col>
                        <BS.Col>{v.date}</BS.Col>
                        <BS.Col>{v.user}</BS.Col>
                        <BS.Col md={4}>{v.notes}</BS.Col>
                        <BS.Col>
                            <C.IconButton
                                icon="file-pdf"
                                onClick={() => TB.downloadFile(v.report, "report", "pdf")}
                            />
                        </BS.Col>
                    </BS.Row>)}
            </BS.Col>
        </BS.Row>
        {props.report.versions.length > 0 && <BS.Row>
            <BS.Col>
                <BS.Row>
                    <BS.Col><h3>{lg.getStaticElem(TC.MISSION_REPORT_SENDING)}</h3></BS.Col>
                </BS.Row>
                <BS.Row>
                    <BS.Col>
                        <h4>{lg.getStaticElem(TC.MISSION_INTERV_TITLE)}</h4>
                    </BS.Col>
                    {props.report.versions.map((v, i) => v.final ? null : <BS.Col>{lg.getStaticElem(TC.MISSION_VERSION_TITLE)} {i + 1}</BS.Col>)}
                </BS.Row>
                {props.intervenants.length === 0
                    ? <BS.Row>
                        <C.CenterMessage className="my-2" italics>
                            {TC.GLOBAL_NONE}
                        </C.CenterMessage>
                    </BS.Row>
                    : props.intervenants.map((inter, i) => <BS.Row key={inter.mail}>
                        <BS.Col>{inter.name || inter.mail || "N/A"}</BS.Col>
                        {props.report.versions.map((v, i) => v.final ? null : <BS.Col key={i}>
                            {render_user_report(inter.mail, v, i === props.report.versions.length - 1)}
                        </BS.Col>)}
                    </BS.Row>)}
            </BS.Col>
        </BS.Row>}
    </>;
}
//#endregion

//#region Report Form
export type ReportFormProps = {
    /** Extra Modal styles */
    modal?: M.StyleModalProps;
    /** Render in a popup ? */
    popup?: boolean;
    /** The _id of the mission */
    mission_id: string;
    /** Callback for confirmation */
    onConfirm?: (version?: Pick<T.Mission_Task_Report["versions"][number], "notes" | "report">) => void;
}

export const ReportForm: React.FC<ReportFormProps> = props => {
    const lg = H.useLanguage();
    const process_image = H.useBoolean(false);
    const [errors, set_errors] = React.useState<T.Errors<typeof note>>({});
    const [note, set_note] = React.useState<Parameters<ReportFormProps["onConfirm"]>[0]>({ notes: "", report: "" });

    const save_note = React.useCallback(() => {
        if (!TB.validString(note.report)) set_errors({ report: TC.GLOBAL_REQUIRED_FIELD });
        else {
            process_image.setTrue();
            S.tempFileExists(note.report).then(({ data }) => {
                if (data) S.moveTempFile({ name: note.report, id: props.mission_id })
                    .then(() => props.onConfirm({
                        notes: note.notes,
                        report: URL.CRAFT_FILE_URL(props.mission_id, note.report),
                    }))
                    .catch(() => {
                        process_image.setFalse();
                        set_errors({ report: TC.FILE_FAILED_UPLOAD });
                    })
                else {
                    process_image.setFalse();
                    set_errors({ report: TC.FILE_FAILED_UPLOAD });
                }
            }).catch(() => {
                process_image.setFalse();
                set_errors({ report: TC.FILE_FAILED_UPLOAD })
            });
        }
    }, [note, process_image, props]);

    React.useEffect(() => {
        set_errors(p => ({ ...p, report: undefined }));
    }, [note.report]);

    const content = React.useMemo(() => <div>
        <C.Form.TextField
            textArea
            rows={4}
            autoExpand
            value={note.notes}
            error={{ code: errors.notes }}
            label={TC.MISSION_REPORT_NOTE}
            onChange={notes => set_note(p => ({ ...p, notes }))}
        />
        <C.Form.FileUploader
            filePattern="application/pdf"
            label={TC.MISSION_REPORT_FILE}
            error={{ code: errors.report }}
            onChange={f => set_note(p => ({ ...p, report: f?.[0]?.name }))}
        />
    </div>, [note, errors]);

    const footer = React.useMemo(() => <C.Flex>
        <C.Button
            onClick={save_note}
            text={TC.GLOBAL_SAVE}
            icon={{
                icon: "save",
                spinIcon: "spinner",
                spin: process_image.value,
            }}
        />
    </C.Flex>, [process_image.value, save_note]);

    if (!TB.mongoIdValidator(props.mission_id)) return <C.ErrorBanner
        type="danger"
        textCode="invalid mission id"
    />;
    else if (props.popup) return <M.BlankModal
        {...props.modal}
        footer={footer}
        size={props.modal?.size || "sm"}
        onQuit={() => props.onConfirm?.()}
        title={props.modal?.title || TC.MISSION_REPORT_NEW_VERSION}
    >
        {content}
    </M.BlankModal>
    return <div>
        <h5>{lg.getStaticElem(TC.MISSION_REPORT_NEW_VERSION)}</h5>
        {content}
        {footer}
    </div>;
}
//#endregion

//#region Tasks
export type TasksProps = {
    /** The id of the asset under review */
    asset: string;
    /** A list of intervenants */
    intervenants: T.Mission["intervenants"];
    /** The current tasks states */
    tasks: T.Mission["tasks"];
    /** The language of the mission's report */
    lang: T.Mission["lang"];
    /** The type of Mission */
    mission_type: T.Mission["type"];
    /** A callback to update the tasks */
    onChange: (tasks: T.Mission["tasks"]) => void;
    /** A list of current errors */
    errors: T.Errors<any>[];
    /** The id of the mission */
    mission_id?: string;
    /** Are the tasks disabled */
    disabled?: boolean;
    /** Callback after a change, also save the data */
    onChange_save: TasksProps["onChange"];
    /** Check the current form for errors */
    check_errors: (set?: boolean) => Partial<Record<keyof T.Mission, any>>;
}

export const TasksOptions = [
    { label: TC.MISSION_TASK_REG, value: "reg", unique: true, mission_types: ["cdm", "edl_tech"] },
    // { label: TC.MISSION_TASK_CRVA, value: "crva", unique: true, mission_types: ["eu_taxonomy"] },
    { label: TC.MISSION_TASK_VISIT, value: "visit", unique: true, mission_types: ["cdm", "edl_tech", "edl_reg", "eu_taxonomy"] },
    { label: TC.MISSION_TASK_REPORT, value: "report", unique: true, mission_types: ["cdm", "nrj", "edl_tech", "red_flag", "edl_reg", "eu_taxonomy", "rsc01", "epra"] },
] as T.Option<{ unique: boolean, mission_types: T.Mission["type"][] }, T.Mission["tasks"][number]["type"]>[];

export const Tasks: React.FC<TasksProps> = props => {
    const lg = H.useLanguage();

    const options = React.useMemo(() => {
        let already_selected = props.tasks.map(t => t.type);
        let available = TasksOptions.filter(o => o.mission_types.includes(props.mission_type) && (!o.unique || !already_selected.includes(o.value)));
        return available;
    }, [props.tasks, props.mission_type]);

    const event = React.useMemo(() => ({
        remove_task: (index: number) => props.onChange?.(props.tasks.filter((t, i) => i !== index)),
        add: () => {
            let type = options[0]?.value;
            if (type) props.onChange?.(props.tasks.concat({ type: type as any, versions: type === "report" ? [] : undefined }));
        },
        set_type: (type: T.Mission["tasks"][number]["type"], index: number) => {
            props.onChange?.(props.tasks.map((t, i) => {
                if (i !== index) return t;
                else if (type === "reg") return { type: "reg" };
                else if (type === "crva") return { type: "crva" };
                else if (type === "report") return { type: "report", versions: [] };
                else return { type: "visit", end_date: "", start_date: "", invitations: [] };
            }));
        },
        set_task: (task: T.Mission["tasks"][number], index: number) => {
            props.onChange?.(props.tasks.map((t, i) => {
                if (i !== index) return t;
                return task;
            }));
        },
        save_task: (task: T.Mission["tasks"][number], index: number) => {
            props.onChange_save?.(props.tasks.map((t, i) => {
                if (i !== index) return t;
                return task;
            }));
        },
    }), [options, props]);

    const getOptions = React.useCallback((value?: T.Mission["tasks"][number]["type"]) => {
        let currentOption = TasksOptions.filter(o => o.value === value)[0];
        if (currentOption && !options.some(o => o.value === value)) return options.concat(currentOption);
        else return options;
    }, [options]);

    return <>
        <BS.Row className="mb-3">
            <BS.Col>
                <C.Flex>
                    <h4>{lg.getStaticElem(TC.MISSION_TASKS)}</h4>
                    <C.Button disabled={props.disabled || options.length === 0} onClick={event.add} className="ms-2" icon="plus" size="sm" />
                </C.Flex>
            </BS.Col>
        </BS.Row>
        {props.tasks.length === 0
            ? <BS.Row>
                <BS.Col>
                    <C.CenterMessage italics>
                        {TC.GLOBAL_NONE}
                    </C.CenterMessage>
                </BS.Col>
            </BS.Row>
            : props.tasks.map((t, i) => <BS.Row key={i} className="m-2 p-3 border">
                <BS.Col md={4}>
                    <C.Form.Select
                        hideLabel
                        value={t.type}
                        disabled={props.disabled}
                        options={getOptions(t.type)}
                        typeahead={{ hideClearButton: true }}
                        onChange={type => event.set_type(type, i)}
                    />
                </BS.Col>
                <BS.Col md={7}></BS.Col>
                <BS.Col className="text-end" md={1}>
                    <C.Button
                        size="sm"
                        icon="times"
                        variant="danger"
                        disabled={props.disabled}
                        onClick={() => event.remove_task(i)}
                    />
                </BS.Col>
                <BS.Col md={12}>
                    {t.type === "reg" && <></>}
                    {t.type === "crva" && <></>}

                    {t.type === "visit" && <Visits
                        visit={t}
                        disabled={props.disabled}
                        errors={props.errors?.[i]}
                        intervenants={props.intervenants}
                        onChange={value => event.set_task(value, i)}
                    />}

                    {t.type === "report" && <Report
                        report={t}
                        lang={props.lang}
                        asset={props.asset}
                        disabled={props.disabled}
                        mission_id={props.mission_id}
                        mission_type={props.mission_type}
                        check_errors={props.check_errors}
                        intervenants={props.intervenants}
                        save={value => event.save_task(value, i)}
                        onChange={value => event.set_task(value, i)}
                    />}
                </BS.Col>
            </BS.Row>)}
    </>
}
//#endregion