import React from "react";
import * as F from "../../Form";
import * as M from "../../Modal";
import * as H from "../../../hooks";
import * as C from "../../../Common";
import * as BS from "react-bootstrap";
import * as S from "../../../services";
import { T, TC } from "../../../Constants";

type Tabs = {
    /** The label displayed in the tab and in the crumb */
    label: string;
    /** The key to know which tab is active */
    key: "mission" | "edl_red_flag";
}

type Resume = ReturnType<T.API.Utils.Missions.MissionResumeRSC01>;

const TABS = [
    { label: TC.CERT_HISTORY_MISSION, key: "mission" },
    { label: TC.RSC01_TAB_MISSION_CHOICE, key: "edl_red_flag" },
] as Tabs[];

const RSC01: React.FC = () => {
    const [, mission_id] = H.useRoots();
    const updated_mission = H.useBoolean(false);
    const mission_ref = React.useRef<F.Missions.MissionFormRef>(null);
    const { updateCrumbs, resetCrumbs } = H.useCrumbs(TC.MISSION_TYPE_RSC01);
    const [current_tab, set_current_tab] = React.useState<Tabs["key"]>("mission");
    const [loaded_tabs, set_loaded_tabs] = React.useState<Tabs["key"][]>(["mission"]);
    const [mission_state, set_mission_state, status] = H.useAsyncState<Resume>({ mission_id: "", asset_id: "", read_only: true, missions: { edl: [], red_flag: [] }, data: { edl: "", red_flag: "" } });

    //#region Tabs & Crumbs
    React.useEffect(() => {
        let tab = TABS.filter(t => t.key === current_tab)[0];
        if (tab) updateCrumbs({ label: tab.label }, 1);
        return () => { resetCrumbs() };
    }, [updateCrumbs, resetCrumbs, current_tab]);

    const change_tab = React.useCallback((tab: typeof current_tab) => {
        const tab_setter = (keep_mission_loaded = true) => {
            // Update the current tab
            set_current_tab(tab);
            // Update the list of loaded tab
            set_loaded_tabs(p => {
                let new_loaded = p.includes(tab) ? p : p.concat(tab);
                if (!keep_mission_loaded) return new_loaded.filter(t => t !== "mission");
                else return new_loaded;
            });
        }

        // Ask to confirm the changes made in the mission
        if (current_tab === "mission" && updated_mission.value) M.askConfirm({ title: TC.MISSION_WIZARD_AUTO_SAVE_MISSION, text: TC.MISSION_WIZARD_AUTO_SAVE_MISSION_TEXT }).then(confirmed => {
            if (confirmed) {
                let result = mission_ref.current?.save?.();
                if (result && result !== "errors") result.then(success => {
                    if (success) {
                        tab_setter();
                        updated_mission.setFalse();
                    }
                });
            }
            else tab_setter(false);
        });
        else tab_setter();
    }, [current_tab, updated_mission]);

    // eslint-disable-next-line react-hooks/exhaustive-deps -- Dependency is not necessary, and I don't want the warning to show up
    React.useEffect(() => updated_mission.setFalse(), [mission_id]);
    //#endregion

    React.useEffect(() => {
        let isSubscribed = true;
        S.missionResumeRSC01()
            .then(({ data }) => isSubscribed && set_mission_state(data, "done"))
            .catch(() => isSubscribed && set_mission_state({ mission_id: "", asset_id: "", read_only: true, missions: { edl: [], red_flag: [] }, data: { edl: "", red_flag: "" } }, "error"));
        return () => {
            isSubscribed = false;
            set_current_tab("mission");
            set_loaded_tabs(["mission"]);
            set_mission_state({ mission_id: "", asset_id: "", read_only: true, missions: { edl: [], red_flag: [] }, data: { edl: "", red_flag: "" } }, "load");
        }
    }, [set_mission_state, mission_id]);

    const events = React.useMemo(() => ({
        set_mission: (mission: string, prop: keyof Resume["data"]) => {
            let new_data = { ...mission_state.data, [prop]: mission };
            S.saveMissionRSC01(new_data)
                .then(() => set_mission_state(p => ({ ...p, data: new_data })))
                .catch(M.Alerts.updateError);
        }
    }), [mission_state.data, set_mission_state]);

    return <C.Flex direction="column" className="flex-grow-1">
        <C.Spinner status={status}>

            <BS.Row className="g-1">
                {TABS.map(tab => <BS.Col key={tab.key}>
                    <C.Button
                        size="sm"
                        text={tab.label}
                        className="w-100"
                        onClick={() => change_tab(tab.key)}
                        variant={tab.key === current_tab ? "primary" : "link"}
                    />
                </BS.Col>)}
            </BS.Row>

            {loaded_tabs.includes("mission") && <div className="flex-grow-1 my-3" hidden={current_tab !== "mission"}>
                <F.Missions.MissionForm
                    no_delete
                    ref={mission_ref}
                    asset={mission_state.asset_id}
                    onSave={updated_mission.setFalse}
                    onChange={updated_mission.setTrue}
                    mission_id={mission_state.mission_id}
                />
            </div>}

            {/* Different handling than other tabs, because table still appears if flex is hidden */}
            {current_tab === "edl_red_flag" && <div className="mt-3">
                <C.Form.Select
                    required
                    no_clear_btn
                    labelPosition="left"
                    label={TC.MISSION_TYPES_EDL}
                    value={mission_state.data.edl}
                    disabled={mission_state.read_only}
                    options={mission_state.missions.edl}
                    onChange={mission => events.set_mission(mission, "edl")}
                />

                <C.Form.Select
                    required
                    no_clear_btn
                    labelPosition="left"
                    label={TC.MISSION_TYPES_RED_FLAG}
                    disabled={mission_state.read_only}
                    value={mission_state.data.red_flag}
                    options={mission_state.missions.red_flag}
                    onChange={mission => events.set_mission(mission, "red_flag")}
                />
            </div>}

        </C.Spinner>
    </C.Flex>
};

export default RSC01;