import _ from "lodash";
import React from "react";
import * as G from "../../Gestion";
import * as H from "../../../hooks";
import * as C from "../../../Common";
import * as BS from "react-bootstrap";
import { T, TB, TC } from "../../../Constants";

export type CommentsProps = {
    /** The list of categories */
    state: ReturnType<T.API.Utils.Missions.MissionResumeRedFlag>;
    /** Callback to edit a comment */
    set_comment: (prop: keyof T.Mission["extra"]["red_flag"], value: string) => void;
};

type Budget = {
    /** The name of the category */
    category: keyof T.Mission["extra"]["red_flag"] | "total";
    /** The code for the category's label */
    label: string;
    /** The sum of the budget for actions under 2 years */
    sum_2: number;
    /** The sum of the budget for actions under 5 years */
    sum_5: number;
    /** The sum of the budget for actions under 10 years */
    sum_10: number;
    /** The total sum of the budget */
    total: number;
}

const Comments: React.FC<CommentsProps> = ({ set_comment, ...props }) => {
    const lg = H.useLanguage();
    const capex_ready = H.useBoolean(false);
    const capex_ref = React.useRef<G.CapexRef>(null);
    const reg_ref = React.useRef<G.RegTableRef>(null);
    const rem_ref = React.useRef<G.RemarquesTableRef>(null);
    const [capex_rows, set_capex_rows] = React.useState<Budget[]>([]);
    const [category, set_category] = React.useState<keyof T.Mission["red_flag"]["capex"] | "capex">("capex");

    const current = React.useMemo(() => {
        if (category === "capex") return TC.TAB_CAPEX_PLAN;
        else if (category === "reg") return TC.RED_FLAG_CAT_REG;
        else return props.state.categories.find(c => c.value === category)?.label || category;
    }, [category, props.state.categories]);

    const category_state = React.useMemo<Record<typeof category, string>>(() => {
        let base = {
            reg: "",
            air: "",
            hot: "",
            cold: "",
            elec: "",
            fire: "",
            mood: "",
            capex: "",
            hoists: "",
            auto_reg: "",
            sanitary: "",
            architecture: "",
        } as Record<typeof category, string>;

        let has_reg = props.state.data.reg.length > 0,
            has_reg_comments = TB.validString(props.state.extra?.reg),
            has_capex_comments = TB.validString(props.state.extra?.capex),
            has_capex = _.flatten(Object.values(props.state.data.capex)).length > 0;
        base.reg = has_reg && has_reg_comments ? "success" : "warning";
        base.capex = has_capex && has_capex_comments ? "success" : "warning";

        for (let key of Object.keys(base)) {
            let code = key as keyof typeof base;
            if (code !== "reg" && code !== "capex") {
                let has_comments = TB.validString(props.state.extra?.[code]),
                    has_actions = props.state.data.capex?.[code]?.length > 0,
                    has_remarques = props.state.data.remarques?.[code]?.length > 0;
                base[code] = has_actions && has_remarques && has_comments ? "success" : "warning";
            }
        }
        return base;
    }, [props.state.data, props.state.extra]);

    const context = React.useMemo<T.ContextParams>(() => ({ roots: props.state.asset_id }), [props.state.asset_id]);

    const styles = React.useMemo<Record<"reg" | "capex" | "budget" | "rem", React.CSSProperties>>(() => {
        let reg = { height: "0px", overflow: "hidden" } as React.CSSProperties,
            rem = { height: "0px", overflow: "hidden" } as React.CSSProperties,
            capex = { height: "0px", overflow: "hidden" } as React.CSSProperties,
            budget = { height: "0px", overflow: "hidden" } as React.CSSProperties;

        if (category === "reg") {
            reg = { flexGrow: 1 };
            capex = { flexGrow: 1 };
        }
        else if (category === "capex") budget = { flexGrow: 1 };
        else {
            rem = { flexGrow: 1 };
            capex = { flexGrow: 1 };
        }
        return { capex, rem, budget, reg };
    }, [category]);

    const set_cat = React.useCallback((cat: typeof category) => {
        // Filter the reg table
        if (cat === "reg") {
            // Filter the rem table to keep only the selected rows
            let reg_to_show_rows_ids = props.state.data?.reg || [];
            let reg_to_show_rows = (reg_ref.current?.rows || []).filter(r => reg_to_show_rows_ids.includes(r._id));
            reg_ref.current?.table?.current?.grid?.api?.setRowData?.(reg_to_show_rows);
        }
        // Filter the capex table
        if (cat !== "capex") {
            // Filter the capex table to keep only selected items for the category
            let capex_to_show_rows_ids = props.state.data?.capex?.[cat] || [];
            let capex_to_show_rows = (capex_ref.current?.rows || []).filter(row => {
                // Is an existing ticket
                if (TB.mongoIdValidator(row._id)) return capex_to_show_rows_ids.some(r => r.type === "action" && r.id === row._id);
                // Is a replacement action
                else return capex_to_show_rows_ids.some(r => r.type === "equipment" && r.id === row.element_id);
            });
            capex_ref.current?.table?.current?.grid?.api?.setRowData?.(capex_to_show_rows);
            // Redraw rows to keep the group row values updated
            capex_ref.current?.table?.current?.grid?.api?.redrawRows?.();
        }
        // Filter the remarques table
        if (cat !== "capex" && cat !== "reg") {
            // Filter the rem table to keep only selected items for the category
            let rem_to_show_rows_ids = props.state.data?.remarques?.[cat] || [];
            let rem_to_show_rows = (rem_ref.current?.rows || []).filter(r => rem_to_show_rows_ids.includes(r._id));
            rem_ref.current?.table?.current?.grid?.api?.setRowData?.(rem_to_show_rows);
        }
        set_category(cat);
    }, [props.state.data]);

    React.useEffect(() => {
        if (capex_ready.value) {
            let budget = [] as Budget[];

            for (let [key, values] of Object.entries(props.state.data?.capex || {})) {
                let code = key as keyof T.Mission["red_flag"]["capex"];

                let action_rows = (capex_ref.current?.rows || []).filter(row => {
                    // Is an existing ticket
                    if (TB.mongoIdValidator(row._id)) return values.some(r => r.type === "action" && r.id === row._id);
                    // Is a replacement action
                    else return values.some(r => r.type === "equipment" && r.id === row.element_id);
                });

                let sum_2 = action_rows
                    .filter(r => r.time_range === "under_2")
                    .reduce((acc, row) => acc + (row.cost || 0), 0);
                let sum_5 = action_rows
                    .filter(r => r.time_range === "under_5")
                    .reduce((acc, row) => acc + (row.cost || 0), 0);
                let sum_10 = action_rows
                    .filter(r => r.time_range === "under_10")
                    .reduce((acc, row) => acc + (row.cost || 0), 0);
                let total = sum_2 + sum_5 + sum_10;
                let label = "";
                if (code === "reg") label = TC.RED_FLAG_CAT_REG;
                else label = props.state.categories.find(c => c.value === code)?.label || code;
                budget.push({ category: code, label, sum_2, sum_5, sum_10, total });
            }

            // Add the total row
            budget.push({
                category: "total",
                label: TC.GLOBAL_TOTAL,
                sum_2: _.sumBy(budget, "sum_2"),
                sum_5: _.sumBy(budget, "sum_5"),
                total: _.sumBy(budget, "total"),
                sum_10: _.sumBy(budget, "sum_10"),
            });
            set_capex_rows(budget);
        }
    }, [props.state.categories, props.state.data?.capex, capex_ready.value]);

    const table_props = React.useMemo(() => ({
        rem: { ref: rem_ref, context, origin: "rf_rem", read_only: true, no_group: true } as G.RemarquesTableProps,
        reg: { context, ref: reg_ref, read_only: true, origin: "rf_reg", hide_buttons: "toggle" } as G.RegTableProps,
        capex: { ref: capex_ref, context, origin: "rf_capex", hide_buttons: "toggle", onReadyGrid: capex_ready.setTrue } as G.CapexProps,
    }), [context, capex_ready]);

    const capex_budget = React.useMemo<G.TableProps<Budget>>(() => ({
        loading: !capex_ready.value,
        adaptableId: "red_flag_capex",
        rows: capex_rows.map(r => ({ ...r, tr_cat: lg.getStaticText(r.label) })),
        columns: [
            { field: "tr_cat", headerName: " " },
            { field: "sum_2", headerName: TC.KPI_API_END_LIFE_NOW, type: G.CellsTypes.TYPE_NUMBER, params: { formatted: "money" } },
            { field: "sum_5", headerName: TC.KPI_API_END_LIFE_UNDER_FIVE, type: G.CellsTypes.TYPE_NUMBER, params: { formatted: "money" } },
            { field: "sum_10", headerName: TC.KPI_API_END_LIFE_FIVE_TEN, type: G.CellsTypes.TYPE_NUMBER, params: { formatted: "money" } },
            { field: "total", headerName: TC.GLOBAL_TOTAL, type: G.CellsTypes.TYPE_NUMBER, params: { formatted: "money" } },
        ],
    }), [capex_rows, lg, capex_ready.value]);

    return <BS.Row className="h-100 p-2 g-2">
        <BS.Col md={3} className="h-100">
            <BS.ListGroup>

                <BS.ListGroup.Item className="pointer" active={category === "capex"} onClick={() => set_cat("capex")}>
                    <C.Flex alignItems="center" justifyContent="between">
                        <span children={lg.getStaticText(TC.TAB_CAPEX_PLAN)} />
                        <i className={`fa fa-circle text-${category_state.capex} ms-2`} />
                    </C.Flex>
                </BS.ListGroup.Item>

                {props.state.categories.map(c => <BS.ListGroup.Item className="pointer" key={c.value} active={category === c.value} onClick={() => set_cat(c.value)}>
                    <C.Flex alignItems="center" justifyContent="between">
                        <span children={lg.getStaticText(c.label)} />
                        <i className={`fa fa-circle text-${category_state[c.value]} ms-2`} />
                    </C.Flex>
                </BS.ListGroup.Item>)}

                <BS.ListGroup.Item className="pointer" active={category === "reg"} onClick={() => set_cat("reg")}>
                    <C.Flex alignItems="center" justifyContent="between">
                        <span children={lg.getStaticText(TC.RED_FLAG_CAT_REG)} />
                        <i className={`fa fa-circle text-${category_state.reg} ms-2`} />
                    </C.Flex>
                </BS.ListGroup.Item>

            </BS.ListGroup>
        </BS.Col>

        <BS.Col md={9} className="d-flex flex-column h-100">

            <div>
                <C.Title level={3} text={current} />

                <C.Form.TextField
                    textArea
                    key={category}
                    max_height="75px"
                    min_height="75px"
                    label={TC.RED_FLAG_GEN_COMMENT}
                    value={props.state.extra?.[category]}
                    onChange={comment => set_comment(category, comment)}
                    placeholder={category !== "capex" && category !== "reg" && TC.RED_FLAG_COMMENT_PLACEHOLDER}
                />
            </div>

            <C.Flex direction="column" className="flex-grow-1">
                <C.Flex style={styles.reg} children={<G.RegDocs {...table_props.reg} />} />

                {category !== "capex" && category !== "reg" && <div children={lg.getStaticText(TC.MISSION_CDM_REMARQUES)} />}
                <C.Flex style={styles.rem} children={<G.Remarques {...table_props.rem} />} />

                {category !== "capex" && <div children={lg.getStaticText(TC.TAB_CAPEX_PLAN)} />}
                <C.Flex style={styles.capex} children={<G.Capex {...table_props.capex} />} />

                <C.Flex style={styles.budget} children={<G.Table<Budget> {...capex_budget} />} />
            </C.Flex>

        </BS.Col>
    </BS.Row>;
}

export default Comments;