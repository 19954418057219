import React from "react";
import { Flex } from "../../Layout";
import * as H from "../../../hooks";
import { T, TB } from "../../../Constants";
import { ComponentWrapper, InputProps } from "../Input";
import { Button, Form, InputGroup, ListGroup } from "react-bootstrap";

//#region Types
export type TextFieldProps<A = (SingleProps | MultiProps)> = InputProps & CommonProps & A;

type SingleTextFieldRef = {
    /** Get the controlled value of the input, if input is single */
    get_controlled_single?: () => string;
    /** Ref to the input */
    input: React.MutableRefObject<HTMLInputElement>;
    /** Ref to the textarea */
    textarea: React.MutableRefObject<HTMLTextAreaElement>;
}

export type TextFieldRef = {
    /** The ref to the single input */
    single?: React.MutableRefObject<SingleTextFieldRef>;
}

type CommonProps = {
    rows?: number;
    suffix?: string;
    prefix?: string;
    isEmail?: boolean;
    multiple?: boolean;
    textArea?: boolean;
    autoFocus?: boolean;
    autoExpand?: boolean;
    spellcheck?: boolean;
    placeholder?: string;
    case?: T.FormTextCase;
    autocomplete?: string;
    uncontrolled?: boolean;
    /** The id to give to the input */
    id?: string;
    /** Should the content be hidden */
    password?: boolean;
    /** Maximum height the textarea can reach */
    max_height?: string;
    /** Minimum height the textarea can reach */
    min_height?: string;
    // inputRef?: React.MutableRefObject<HTMLInputElement>;
    textareaRef?: React.MutableRefObject<HTMLTextAreaElement>;
}

type SingleProps = {
    value?: string;
    useCalculatedError?: boolean;
    onChange?: (text: string) => void;
    onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>
}

type MultiProps = {
    value?: string[];
    onChange?: (texts: string[]) => void;
}
//#endregion

//#region Single
const TextFieldSingle = React.forwardRef<SingleTextFieldRef, TextFieldProps<SingleProps>>(({ onChange, onKeyDown, disabled, noOverride, ...props }, ref) => {
    const lg = H.useLanguage();
    const [controlled, setControlled] = React.useState("");
    const textInputRef = React.useRef<HTMLInputElement>(null);
    const textAreaRef = React.useRef<HTMLTextAreaElement>(null);
    const { error } = H.useFormConsumer(props.prop, props.contextKey);
    const isDisabled = React.useMemo(() => disabled || noOverride, [disabled, noOverride]);
    H.useAutoSizeTextArea(textAreaRef.current, props.autoExpand ? controlled : "", props.rows);

    React.useImperativeHandle(ref, () => ({
        input: textInputRef,
        textarea: textAreaRef,
        get_controlled_single: () => controlled,
    }), [controlled]);

    React.useEffect(() => {
        if (props.textareaRef) props.textareaRef.current = textAreaRef.current;
    });

    //#region Language
    React.useEffect(() => {
        let codes = [props.suffix, props.prefix, props.placeholder].filter(TB.isTextCode);
        if (codes.length > 0) lg.fetchStaticTranslations(codes);
    }, [lg, props.suffix, props.prefix, props.placeholder]);
    //#endregion

    //#region Updates
    React.useEffect(() => setControlled(TB.getString(props.value)), [props.value]);
    const onLostFocus = React.useCallback(() => !isDisabled && !props.uncontrolled && onChange?.(controlled), [onChange, controlled, isDisabled, props.uncontrolled]);

    const onChangeInput = React.useCallback((text: string) => {
        if (props.case === "lowercase" || props.isEmail) text = text.toLowerCase();
        else if (props.case === "uppercase") text = text.toUpperCase();
        if (props.uncontrolled) onChange?.(text);
        else setControlled(text);
    }, [props.case, props.isEmail, props.uncontrolled, onChange]);
    //#endregion

    //#region Input Style & Props
    const vPrefix = React.useMemo(() => props.prefix && lg.getStaticText(props.prefix), [props.prefix, lg]);
    const vSuffix = React.useMemo(() => props.suffix && lg.getStaticText(props.suffix), [props.suffix, lg]);
    const vPlaceHolder = React.useMemo(() => props.placeholder && lg.getStaticText(props.placeholder), [props.placeholder, lg]);

    const inputProps = React.useMemo(() => ({
        id: props.id,
        placeholder: vPlaceHolder,
        spellCheck: props.spellcheck,
        autoComplete: props.autocomplete,
        type: props.isEmail ? "email" : (props.password ? "password" : undefined),
    }), [vPlaceHolder, props.isEmail, props.autocomplete, props.spellcheck, props.password, props.id]);

    const inputComponent = React.useMemo(() => <InputGroup>
        {vPrefix && <InputGroup.Text>{vPrefix}</InputGroup.Text>}
        <Form.Control
            {...inputProps}
            value={controlled}
            ref={textInputRef}
            onBlur={onLostFocus}
            disabled={isDisabled}
            onKeyDown={onKeyDown}
            readOnly={props.readonly}
            className={"input_control"}
            autoFocus={props.autoFocus}
            onChange={e => onChangeInput(e.target.value)}
        />
        {vSuffix && <InputGroup.Text>{vSuffix}</InputGroup.Text>}
    </InputGroup>, [inputProps, vPrefix, vSuffix, controlled, isDisabled, onChangeInput, onLostFocus, onKeyDown, props.readonly, props.autoFocus]);

    const textAreaComponent = React.useMemo(() => <InputGroup>
        <Form.Control
            {...inputProps}
            as="textarea"
            ref={textAreaRef}
            rows={props.rows}
            value={controlled}
            onBlur={onLostFocus}
            disabled={isDisabled}
            readOnly={props.readonly}
            style={{ maxHeight: props.max_height, minHeight: props.min_height }}
            onChange={e => onChangeInput(e.target.value)}
        />
    </InputGroup>, [inputProps, controlled, isDisabled, onChangeInput, onLostFocus, props.rows, props.readonly, props.max_height, props.min_height]);

    const input = React.useMemo(() => props.textArea ? textAreaComponent : inputComponent, [textAreaComponent, inputComponent, props.textArea]);
    //#endregion

    return <ComponentWrapper {...props} error={props.useCalculatedError ? error : props.error} disabled={isDisabled} children={input} />
});
//#endregion

const TextField = React.forwardRef<TextFieldRef, TextFieldProps>(({ multiple, disabled, noOverride, ...props }, ref) => {
    const singleRef = React.useRef<SingleTextFieldRef>(null);
    const onChange = React.useMemo(() => props.onChange, [props.onChange]);
    const isDisabled = React.useMemo(() => disabled || noOverride, [disabled, noOverride]);
    const values = React.useMemo(() => TB.arrayWrapper(props.value).filter(x => typeof x === "string"), [props.value]);

    React.useImperativeHandle(ref, () => ({ single: singleRef }), []);

    const editEntry = React.useCallback((str: string, index: number) => {
        if (values.length === 0 && index === 0) onChange?.([str]);
        else onChange?.(values.map((v, i) => i === index ? str : v));
    }, [onChange, values]);

    const addEntry = React.useCallback(() => onChange?.(values.concat("")), [onChange, values]);
    const removeEntry = React.useCallback((index: number) => onChange?.(values.filter((v, i) => i !== index)), [onChange, values]);

    if (!multiple) return <TextFieldSingle {...props} ref={singleRef} disabled={isDisabled} />;
    return <ListGroup className="mb-3">
        {values.map((v, i) => <ListGroup.Item key={i}>
            <Flex alignItems="center">
                <TextFieldSingle
                    {...props}
                    value={v}
                    useCalculatedError
                    disabled={isDisabled}
                    prop={props.prop + "." + i}
                    onChange={(s: string) => editEntry(s, i)}
                    customClass={TB.getString(props.customClass) + " flex-grow-1"}
                />
                <div className="ms-3">
                    <Button variant="danger" disabled={isDisabled} size="sm" onClick={() => removeEntry(i)}>
                        <i className="fa fa-times"></i>
                    </Button>
                </div>
            </Flex>
        </ListGroup.Item>)}

        {values.length === 0 && <ListGroup.Item>
            <TextFieldSingle {...props} value={""} disabled={isDisabled} onChange={(s: string) => editEntry(s, 0)} error={null} />
        </ListGroup.Item>}

        <ListGroup.Item className="text-end">
            <Button size="sm" onClick={addEntry} disabled={isDisabled}>
                <i className="fa fa-plus"></i>
            </Button>
        </ListGroup.Item>
    </ListGroup>;
});

export default TextField;